<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import Utils from "@/assets/javascript/utils";

function doParseSheetRecord(sheetModel) {
  let pages = new Array();
  for(let pageModel of sheetModel.pages) {
    pages.push(doParseSheetPageRecord(pageModel));
  }
  return {
    sheetId: sheetModel.id,
    id: sheetModel.sheetRecordId,
    modifiedCode: sheetModel.modifiedCode,
    pages,
    linkResults: sheetModel.linkResults,
  }
}

function doParseSheetPageRecord(pageModel) {
  let items = new Array();

  for(let subPage of pageModel.pages) {
    for(let itemModel of subPage.items) {
      if(itemModel.type === SheetUtils.ILLUSTRATION) continue;
      if(itemModel.type === SheetUtils.GROUP) {
        items.push(doParseSheetItemGroup(itemModel));
      } else {
        items.push(doParseSheetItem(itemModel));
      }
    }
  }
  return {
    number: pageModel.number,
    items,
    additionalSheetItems: [],
  }
}

function doParseSheetItem(itemModel) {
  let record = Utils.deepCopy(itemModel.record);
  record.number = itemModel.number;
  return record;
}

function doParseSheetItemGroup(groupModel) {
  let items = new Array();
  for(let itemModel of groupModel.items) {
    if(itemModel.type === SheetUtils.ILLUSTRATION) continue;
    items.push(doParseSheetItem(itemModel));
  }
  return {
    number: groupModel.number,
    type: groupModel.type,
    items,
    additionalSheetItems: [],
  }
}

export default {
  name: "SheetRecordViewModelParser",
  methods: {
    doParseSheetRecord(sheetModel) {
      return doParseSheetRecord(sheetModel);
    }
  }
}
</script>

<style scoped>

</style>