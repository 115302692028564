<template>
  <div class="sheet-item sheet-item__number" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} 0.0000001kg</div>
    <div class="title layout-horizontal layout-middle padding-horizontal-large">
      <span v-if="!sheetItem.optional" class="font-color-danger layout-inflexible">*</span>
      <div :class="mode === ViewMode.MODE_EDIT ? 'layout-flexible' : 'layout-inflexible'">
        <ys-click-to-edit
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%;"
            v-if="mode === ViewMode.MODE_EDIT"
            @blur="handleTitleBlur"
        ></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <template v-if="!isBigGap">
        <div class="layout-flexible layout-horizontal layout-right layout-middle font-align-right">
          <ys-click-to-edit
              type="textarea"
              v-model="placeholder"
              :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
              style="width: 100%"
              v-if="mode === ViewMode.MODE_EDIT"
              @blur="handlePlaceholderBlur"
              placeholder="双击此处添加占位文字"
              class="font-color-placeholder"
          ></ys-click-to-edit>
          <ys-number
              :digits="sheetItem.digits"
              :max="sheetItem.maxValue"
              :min="sheetItem.minValue"
              :placeholder="placeholder ? placeholder : '请输入'"
              :show-background="false"
              style="width: 100%; text-align: right;"
              v-model="sheetItemRecord.value"
              @input="handleValueInput"
              v-else-if="!readOnly"
          ></ys-number>
          <template v-else>
            <span v-if="sheetItemRecord.value">{{ ExactNumber.stringify(sheetItemRecord.value, true) }}</span>
            <span class="font-color-placeholder" v-else>未填写</span>
          </template>
        </div>
        <div class="layout-inflexible" v-if="sheetItem.units.length > 0 && !readOnly">
          <span v-if="sheetItem.units.length === 1">{{ unit }}</span>
          <el-dropdown v-else>
            <span class="dropdown-units">{{ unit }} <i
                class="fa fa-angle-down font-color-placeholder"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="unit-item font-size-large" v-for="unit in sheetItem.units" :key="unit"
                                @click.native="handleUnitPicked(unit)">
                {{ unit }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </div>
    <div v-if="isBigGap"
         class="padding-bottom-large padding-horizontal-large layout-horizontal layout-middle layout-right">
      <div class="layout-flexible layout-horizontal layout-right layout-middle font-align-right">
        <ys-click-to-edit
            type="textarea"
            v-model="placeholder"
            :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
            style="width: 100%"
            v-if="mode === ViewMode.MODE_EDIT"
            class="font-color-placeholder"
            placeholder="双击此处添加占位文字"
            @blur="handlePlaceholderBlur"
        ></ys-click-to-edit>
        <ys-number
            :digits="sheetItem.digits"
            :max="sheetItem.maxValue"
            :min="sheetItem.minValue"
            :placeholder="placeholder ? placeholder : '请输入'"
            :show-background="false"
            style="width: 100%; text-align: right;"
            v-else-if="!readOnly"
        ></ys-number>
        <template v-else>
          <span v-if="sheetItemRecord.value">{{ ExactNumber.stringify(sheetItemRecord.value, true) }}</span>
          <span class="font-color-placeholder" v-else>未填写</span>
        </template>
      </div>
      <div class="layout-inflexible" v-if="sheetItem.units.length > 0 && !readOnly">
        <span v-if="sheetItem.units.length === 1">{{ unit }}</span>
        <el-dropdown v-else>
            <span class="dropdown-units">{{ unit }} <i
                class="fa fa-angle-down font-color-placeholder"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="unit-item font-size-large" v-for="unit in sheetItem.units" :key="unit"
                              @click.native="handleUnitPicked(unit)">
              {{ unit }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsNumber from "@/components/wedigets/YsNumber";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemNumberPropertyPanel from "@/components/sheet/panel/SheetItemNumberPropertyPanel";

export default {
  name: "SheetItemNumber",
  mixins: [SheetItemBasic],
  components: {YsNumber, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      ExactNumber,

      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      placeholder: null,

      unit: null,

      ViewMode,
      SheetUtils,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.placeholder = this.sheetItem.placeholder;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if (this.sheetItemRecord && this.sheetItemRecord.unit) {
          this.unit = this.sheetItemRecord.unit;
        } else if (this.sheetItem.units.length > 0) {
          this.unit = this.sheetItem.units[0];
        } else {
          this.unit = null;
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      console.log('title input.....');
      this.$emit('input', this.item);
    },
    handlePlaceholderBlur() {
      this.sheetItem.placeholder = this.placeholder;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 40;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.placeholder = this.sheetItem.placeholder;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemNumberPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleUnitPicked(unit) {
      this.unit = unit;
      if (this.sheetItemRecord && this.sheetItemRecord.value) {
        this.sheetItemRecord.value.unit = unit;
        this.$emit('input', this.item);
      }
    },
    handleValueInput() {
      this.sheetItemRecord.value.unit = this.unit;
      this.$emit('input', this.item);
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.text-measurer {
  height: 0px;
  overflow: hidden;
  position: absolute;
}

.dropdown-units {
  cursor: pointer;
}

.dropdown-units:hover {
  background-color: #f1f1f1;
}

.unit-item {
  padding: 10px 20px;
}
</style>