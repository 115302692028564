<script>
import SheetItemCoordinateHelper from "@/components/sheet/basic/SheetItemCoordinateHelper";
import SheetUtils from "@/assets/javascript/sheet-utils";
import GuidUtils from "@/assets/javascript/guid-utils";

function doCreateSheetPageAfter(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];

  let sheetPage = SheetUtils.defaultSheetPage();
  sheetPage.globalId = GuidUtils.guid();

  sheet.pages.splice(coordinate.page + 1, 0, sheetPage);

  let number = 1;
  for(let page of sheet.pages) {
    page.number = number ++;
  }
  return sheetPage;
}

function doCreateSheetItemAfter(sheetItemType, sheetList, coordinate) {
  let page = sheetList[coordinate.sheet].pages[coordinate.page];

  let sheetItem = SheetUtils.defaultItem(sheetItemType);
  sheetItem.globalId = GuidUtils.guid();

  page.sheetItems.splice(coordinate.item + 1, 0, sheetItem);

  let number = 1;
  for(let item of page.sheetItems) {
    item.number = number ++;
  }
  return sheetItem;
}

function doCreateSubSheetItemAfter(sheetItemType, sheetList, coordiate) {
  let itemGroup = sheetList[coordiate.sheet].pages[coordiate.page].sheetItems[coordiate.item];
  if(itemGroup.type != SheetUtils.GROUP) return null;

  let sheetItem = SheetUtils.defaultItem(sheetItemType);
  sheetItem.globalId = GuidUtils.guid();

  if(itemGroup.items.length == 0) {
    itemGroup.items.push(sheetItem);
  } else {
    itemGroup.items.splice(coordiate.s_item + 1, 0, sheetItem);
  }

  let number = 1;
  for(let item of itemGroup.items) {
    item.number = number ++;
  }
  return sheetItem;
}

export default {
  name: "SheetItemCreateHelper",
  mixins: [SheetItemCoordinateHelper],
  methods: {
    createPageByAfter(sheetList, keys) {
      let coordinate = null;
      for (let key of keys) {
        let candidate = this.getCoordinateByKey(key, sheetList);
        if (candidate && candidate.type >= this.CoordinateTypes.PAGE) {
          coordinate = candidate;
          break;
        } else {
          if(!coordinate) coordinate = candidate;
        }
      }
      if(!coordinate) coordinate = this.getLastSheetPage(sheetList);
      coordinate = this.doGetInsertCoordinateOrCreateParents(coordinate, this.CoordinateTypes.PAGE, sheetList);
      let sheetPage = doCreateSheetPageAfter(sheetList, coordinate);
      return this.keyOfSheetPage(sheetPage);
    },
    createSheetItemAfter(sheetItemType, sheetList, keys) {
      let coordinate = null;
      for(let key of keys) {
        let candidate = this.getCoordinateByKey(key, sheetList);
        if(candidate && candidate.type >= this.CoordinateTypes.SHEET_ITEM) {
          coordinate = candidate;
          break;
        } else {
          if(!coordinate) coordinate = candidate;
        }
      }
      if(!coordinate) coordinate = this.getLastSheetItem(sheetList);
      coordinate = this.doGetInsertCoordinateOrCreateParents(coordinate, this.CoordinateTypes.SHEET_ITEM, sheetList);
      let sheetItem;
      if(coordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
        sheetItem = doCreateSubSheetItemAfter(sheetItemType, sheetList, coordinate);
      } else {
        sheetItem = doCreateSheetItemAfter(sheetItemType, sheetList, coordinate);
      }
      return this.keyOfSheetItem(sheetItem);
    },
    getSheetItemInsertCoordinate(sheetList, keys) {
      let coordinate = null;
      for(let key of keys) {
        let candidate = this.getCoordinateByKey(key, sheetList);
        if(candidate && candidate.type >= this.CoordinateTypes.SHEET_ITEM) {
          coordinate = candidate;
          break;
        }
      }
      if(!coordinate) coordinate = this.getLastSheetItem(sheetList);
      return coordinate;
    },
    doGetInsertCoordinateOrCreateParents(coordinate, insertType, sheetList) {

      if (insertType === this.CoordinateTypes.SHEET) {
        if (!coordinate) {
          return {
            type: this.CoordinateTypes.SHEET,
            sheet: -1,
          }
        } else if (coordinate.type === insertType) {
          return coordinate;
        } else {
          return {
            type: this.CoordinateTypes.SHEET,
            sheet: coordinate.sheet,
          }
        }
      }

      if (!coordinate) {
        let sheet = SheetUtils.defaultSheet();
        sheet.globalId = GuidUtils.guid();
        sheetList.push(sheet);
        coordinate = {
          type: this.CoordinateTypes.SHEET,
          sheet: -1,
        }
      }

      if (insertType === this.CoordinateTypes.PAGE) {
        if (coordinate.type === this.CoordinateTypes.PAGE) {
          return coordinate;
        } else if (coordinate.type > this.CoordinateTypes.PAGE) {
          return {
            type: this.CoordinateTypes.PAGE,
            sheet: coordinate.sheet,
            page: coordinate.page,
          }
        } else {
          return {
            type: this.CoordinateTypes.PAGE,
            sheet: coordinate.sheet,
            page: -1,
          }
        }
      }

      if (coordinate.type < this.CoordinateTypes.PAGE) {
        let page = SheetUtils.defaultSheetPage();
        page.globalId = GuidUtils.guid();
        page.number = 1;
        let sheet = sheetList[coordinate.sheet];
        sheet.pages.push(page);
        coordinate = {
          type: this.CoordinateTypes.PAGE,
          sheet: coordinate.sheet,
          page: -1,
        }
      }

      if (insertType === this.CoordinateTypes.SHEET_ITEM) {
        if (coordinate.type === this.CoordinateTypes.SHEET_ITEM || coordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
          let sheetItem = sheetList[coordinate.sheet].pages[coordinate.page].sheetItems[coordinate.item];
          if(sheetItem.type === SheetUtils.GROUP && sheetItem.items.length === 0) {
            return {
              type: this.CoordinateTypes.SUB_SHEET_ITEM,
              sheet: coordinate.sheet,
              page: coordinate.page,
              item: coordinate.item,
              s_item: -1,
            }
          } else {
            return coordinate;
          }
        } else if (coordinate.type < this.CoordinateTypes.SHEET_ITEM) {
          return {
            type: this.CoordinateTypes.SHEET_ITEM,
            sheet: coordinate.sheet,
            page: coordinate.page,
            item: -1,
          }
        }
      }

      return coordinate;

    },
  },
}
</script>

<style scoped>

</style>
