<template>
  <div
      class="sheet-item sheet-item__illustration layout-horizontal layout-middle padding-horizontal-large padding-vertical-small"
      v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="dot layout-inflexible"></div>
    <div class="layout-flexible margin-left-small">
      <ys-click-to-edit
          v-model="title"
          placeholder="双击此处添加内容"
          :maxlength="SheetUtils.TITLE_MAX_LENGTH"
          type="textarea"
          style="width: 100%;"
          @blur="handleTitleBlur"
          v-if="mode === ViewMode.MODE_EDIT"
      ></ys-click-to-edit>
      <span v-else>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetItemIllustrationPropertyPanel from "@/components/sheet/panel/SheetItemIllustrationPropertyPanel";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";

export default {
  name: "SheetItemIllustration",
  mixins: [SheetItemBasic],
  components: {YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      title: null,
      isBigGap: false,
      ViewMode,
      SheetUtils,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    },
    sheetItem: {
      handler() {
        let sheetItem = this.sheetItem;
        if (sheetItem) {
          this.title = sheetItem.title;
        }

        let propertyPanel = this.propertyPanel;
        if (propertyPanel) propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleInput() {
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemIllustrationPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.dot {
  background-color: #bec0c0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: #bec0c0;
}

</style>