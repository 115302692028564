<template>
  <div class="sheet-container layout-horizontal">
    <contents ref="contents"
              style="height: calc(100% - 20px);"
              class="margin-top"
              :multi-choice="multiChoice"
              @input="handleContentsInput"
              @move="handleMove"
              :movable="mode === ViewMode.MODE_EDIT"
              v-show="showContents">
      <slot name="contents-item"></slot>
      <div class="padding-bottom">
        <div v-for="(sheet, sheetIndex) in sheetList" :key="sheetIndex">
          <template v-if="sheetList.length > 1">
            <contents-item v-model="sheet.name"
                           class="font-weight-bold" @click="handleClickMenuSheet(sheet)" :tag="keyOfSheet(sheet)"
                           :move-to-children="false" :disable-edit="mode === ViewMode.MODE_VIEW">
              <div slot="children" class="font-weight-normal">
                <contents-item v-for="(page, pageIndex) in sheet.pages" :key="page.globalId"
                               v-model="page.name" :placeholder="`第${pageIndex + 1}页`"
                               @click="handleClickMenuSheetPage(page)" :move-to-children="false"
                               :tag="keyOfSheetPage(page)"
                               :disable-edit="mode === ViewMode.MODE_VIEW">
                  <contents-item slot="children" v-for="(item) in page.sheetItems" :key="item.globalId"
                                 :pick-up-level="999"
                                 v-model="item.title"
                                 @click="handleClickMenuSheetItem(item)"
                                 :move-to-children="item.type !== SheetUtils.GROUP"
                                 :tag="keyOfSheetItem(item)"
                                 :disable-edit="mode === ViewMode.MODE_VIEW">
                    <div slot="children" v-if="item.type === SheetUtils.GROUP">
                      <contents-item v-for="subItem in item.items" :key="subItem.globalId"
                                     v-model="subItem.title"
                                     @click="handleClickMenuSheetItem(subItem)"
                                     :tag="keyOfSheetItem(subItem)"
                                     :disable-edit="mode === ViewMode.MODE_VIEW"></contents-item>
                    </div>
                  </contents-item>
                </contents-item>
              </div>
            </contents-item>
            <contents-item placeholder="#表单结束#" :move-to-children="false" disable tag="end#"
                           disable-touching-bottom
                           v-if="sheetIndex == sheetList.length - 1 && mode === ViewMode.MODE_EDIT"></contents-item>
          </template>
          <template v-else>
            <contents-item v-for="(page, pageIndex) in sheet.pages" :key="page.globalId"
                           v-model="page.name" :placeholder="`第${pageIndex + 1}页`"
                           @click="handleClickMenuSheetPage(page)" :move-to-children="false"
                           :tag="keyOfSheetPage(page)"
                           :disable-edit="mode === ViewMode.MODE_VIEW">
              <contents-item slot="children" v-for="(item) in page.sheetItems" :key="item.globalId"
                             :pick-up-level="999"
                             v-model="item.title"
                             @click="handleClickMenuSheetItem( item)"
                             :move-to-children="item.type !== SheetUtils.GROUP"
                             :tag="keyOfSheetItem(item)"
                             :disable-edit="mode === ViewMode.MODE_VIEW">
                <div slot="children" v-if="item.type === SheetUtils.GROUP">
                  <contents-item v-for="subItem in item.items" :key="subItem.globalId"
                                 v-model="subItem.title" @click="handleClickMenuSheetItem(subItem)"
                                 :tag="keyOfSheetItem(subItem)"
                                 :disable-edit="mode === ViewMode.MODE_VIEW"></contents-item>
                </div>
              </contents-item>
            </contents-item>
            <contents-item placeholder="#表单结束#" :move-to-children="false" disable tag="end#"
                           disable-touching-bottom
                           v-if="sheetIndex == sheetList.length - 1 && mode === ViewMode.MODE_EDIT"></contents-item>
          </template>
        </div>
      </div>
    </contents>
    <div class="layout-flexible">
      <el-scrollbar ref="scrollbar" class="scrollbar" v-resize="handleResize">
        <div class="sheet-list"
             :class="{
            'is-show-abstract': isShowAbstract,
            'is-page-by-page': isPageByPage,
           }"
             :style="`${isShowAbstract ? `height: ${containerHeight - 48}px;` : ''}`"
        >
          <div v-for="(sheet, index) in sheetList"
               :key="index">
            <transition name="el-fade-in">
              <sheet
                  v-show="!pageByPage || sheetIndex === index"
                  :sheet="sheet"
                  :record="sheetRecordList ? sheetRecordList[index] : null"
                  :property-docker="propertyDocker"
                  :read-only="readOnly"
                  :page="pageIndex"
                  :pageByPage="isPageByPage"
                  @input="handleSheetInput($event, index)"
                  @insert="handleSheetItemInsert"
              ></sheet>
            </transition>
          </div>
        </div>
        <div class="footer-container" v-if="isShowAbstract">
          <div class="footer font-align-center" style="margin: 0px 16px;">
            <div class="btn-show-all font-size-medium font-color-secondary padding padding-large" @click="handleShowAllClick"><span>查看全部 <i
                class="fas fa-caret-down"></i> </span></div>
          </div>
        </div>
        <div class="footer-slot-container"
             :class="{
          'margin-top-large': !isShowAbstract
        }">
          <div style="margin-left: 16px; margin-right: 16px; box-shadow: 0 0 16px #bec0c0;"
               :style="`${isShowAbstract ? 'margin-top: -20px; padding-top: 20px; min-height: 48px;' : ''}`">
            <slot name="footer"></slot>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <delete-dialog :visible.sync="deleteDialogVisible" message="您确定要删除吗？"
                   @confirm="handleDeleteConfirm"></delete-dialog>
    <sheet-item-types-dialog :visible.sync="sheetItemTypesDialogVisible" :disable-group-type="disableSheetItemGroup"
                             @pick="handleSheetItemTypePick"></sheet-item-types-dialog>
  </div>
</template>

<script>
import {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";
import ContentsItem from "@/components/contents/ContentsItem";
import Contents from "@/components/contents/Contents";
import Sheet from "@/components/sheet/Sheet";
import SheetUtils from "@/assets/javascript/sheet-utils";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import SheetItemDeleteHelper from "@/components/sheet/basic/SheetItemDeleteHelper";
import SheetItemCopyHelper from "@/components/sheet/basic/SheetItemCopyHelper";
import SheetItemCreateHelper from "@/components/sheet/basic/SheetItemCreateHelper";
import SheetItemTypesDialog from "@/components/sheet/dialog/SheetItemTypesDialog";
import SheetItemMoveHelper from "@/components/sheet/basic/SheetItemMoveHelper";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "SheetContainer",
  components: {YsButton, SheetItemTypesDialog, DeleteDialog, Sheet, Contents, ContentsItem},
  mixins: [SheetItemKeyGenerator, SheetItemDeleteHelper, SheetItemCopyHelper, SheetItemCreateHelper, SheetItemMoveHelper],
  props: {
    mode: {
      type: String,
      default: ViewMode.MODE_EDIT,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
    multiChoice: Boolean,
    propertyDocker: Element,
    sheetList: Array,
    sheetRecordList: Array,
    showContents: {
      type: Boolean,
      default: true,
    },
    showAbstract: Boolean,
    pageByPage: Boolean,
    page: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isShowAbstract: false,
      isPageByPage: false,
      sheetIndex: 0,
      pageIndex: 0,
      pageIndexInAll: 0,
      requireRefresh: false,
      children: [],
      childrenMap: {},
      keyMap: {},
      SheetUtils,
      sheetItemInsertKeys: [],
      deleteDialogVisible: false,
      disableSheetItemGroup: false,
      sheetItemTypesDialogVisible: false,
      ViewMode,

      containerHeight: 0,
    }
  },
  computed: {
    totalPages() {
      let totalPages = 0;
      if (this.sheetList) {
        for (let sheet of this.sheetList) {
          totalPages += sheet.pages.length;
        }
      }
      return totalPages;
    }
  },
  watch: {
    mode: {
      handler() {
        for (let children of this.children) {
          children.mode = this.mode;
        }
        if (this.mode === ViewMode.MODE_VIEW) {
          if (this.propertyDocker) this.propertyDocker.mountPanel();
        }
      },
      immediate: true,
    },
    propertyDocker: {
      handler() {
        for (let children of this.children) {
          children.propertyDocker = this.propertyDocker;
        }
      },
      immediate: true,
    },
    showAbstract: {
      handler() {
        this.isShowAbstract = this.showAbstract;
      },
      immediate: true,
    },
    pageByPage: {
      handler() {
        this.isPageByPage = this.pageByPage;
      },
      immediate: true,
    },
    page: {
      handler() {
        this.pageIndexInAll = this.page;
      },
      immediate: true,
    },
    pageIndexInAll: {
      handler() {
        let pagesBefore = 0;
        let sheetIndex = 0;
        let pageIndex = 0;
        for (let n = 0; n < this.sheetList.length; n++) {
          let sheet = this.sheetList[n];
          if (pagesBefore + sheet.pages.length > this.pageIndexInAll) {
            sheetIndex = n;
            pageIndex = this.pageIndexInAll - pagesBefore;
            break;
          }
          pagesBefore += sheet.pages.length;
        }
        this.sheetIndex = sheetIndex;
        this.pageIndex = pageIndex;
      },
      immediate: true,
    },
    sheetList: {
      handler() {
        this.page = 0;
      },
      immediate: true,
    }
  },
  methods: {
    isSheetContainer() {
      return true;
    },
    refreshChildren() {
      this.requireRefresh = true;
      this.$nextTick(() => {
        if (this.requireRefresh) {
          this.requireRefresh = false;
          this.children = this.doRefreshChildrens(this);
          for (let children of this.children) {
            children.mode = this.mode;
            children.propertyDocker = this.propertyDocker;
          }
        }
      })
    },
    doRefreshChildrens(view) {
      let childrenList = new Array();
      for (let children of view.$children) {
        if (children.isSheet && children.isSheet(this)) {
          childrenList.push(children);
        } else {
          childrenList = childrenList.concat(this.doRefreshChildrens(children));
        }
      }
      return childrenList;
    },
    getChildrenByKey(key) {
      let children = this.childrenMap[key];
      if (children) {
        return children;
      } else {
        for (let candidate of this.children) {
          if (candidate.getChildrenByKey) {
            children = candidate.getChildrenByKey(key);
          }
          if (children) return children;
        }
      }
      return null;
    },
    setSelectionByKey(key) {
      let children = this.getChildrenByKey(key);
      if (children) {
        children.setSelected();
        this.scrollToKey(key);
      } else {
        if (this.propertyDocker) this.propertyDocker.unmountPanel();
      }
    },
    scrollToKey(key) {

      if(this.pageByPage) {
        let pageIndexStart = 0;
        let pageIndexInAll = 0;
        for(let sheet of this.sheetList) {
          let pageIndex = this.getPageIndexOfKey(key, sheet);
          if(pageIndex >= 0) {
            pageIndexInAll = pageIndex + pageIndexStart;
            break;
          } else {
            pageIndexStart += pageIndexStart + sheet.pages.length;
          }
        }

        if(pageIndexInAll != this.pageIndexInAll) {
          this.pageIndexInAll = pageIndexInAll;
          this.$emit('update:page', this.pageIndexInAll);
        }
      }

      setTimeout(()=> {
        let children = this.getChildrenByKey(key);
        if (children) {
          let parentRect = this.$el.getBoundingClientRect();
          let childrenRect = children.$el.getBoundingClientRect();
          if (childrenRect.top < parentRect.top || childrenRect.bottom > parentRect.bottom) {
            this.$refs.scrollbar.wrap.scrollTo({
              top: childrenRect.top + this.$refs.scrollbar.wrap.scrollTop - parentRect.top - 40,
              behavior: 'smooth'
            })
          }
        }
      }, 500);
    },
    scrollTo(top) {
      this.$refs.scrollbar.wrap.scrollTo({
        top,
        behavior: 'smooth',
      })
    },
    scrollToTop() {
      this.$refs.scrollbar.wrap.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    navigateToNextPage() {
      if (this.pageIndexInAll < this.totalPages - 1) {
        this.pageIndexInAll++;
        this.$emit('update:page', this.pageIndexInAll);
        this.$refs.scrollbar.wrap.scrollTo({
          top: 0,
        });
      }
    },
    navigateToPreviousPage() {
      if (this.pageIndexInAll > 0) {
        this.pageIndexInAll--;
        this.$emit('update:page', this.pageIndexInAll);
        this.$refs.scrollbar.wrap.scrollTo({
          top: 0,
        });
      }
    },
    scrollToBottom() {
      let maxScrollTop = this.$refs.scrollbar.wrap.scrollHeight - this.$refs.scrollbar.$el.getBoundingClientRect().height;
      this.$refs.scrollbar.wrap.scrollTo({
        top: maxScrollTop,
        behavior: 'smooth',
      })
    },
    registerChildren(key, children) {
      this.unregisterChildren(children);
      if (key) {
        this.keyMap[children.guid] = key;
        this.childrenMap[key] = children;
      }
    },
    unregisterChildren(children) {
      let key = this.keyMap[children.guid];
      if (key) {
        delete this.keyMap[children.guid];
        delete this.childrenMap[key];
      }
    },
    update() {
      for (let children of this.children) {
        children.update();
      }
    },
    checkBeforeSubmit() {
      let firstRequiredEmptyKey = null;
      for(let children of this.children) {
        let key = children.checkBeforeSubmit();
        console.log('key: ' + key);
        if(key && !firstRequiredEmptyKey) firstRequiredEmptyKey = key;
      }
      if(firstRequiredEmptyKey) {
        this.scrollToKey(firstRequiredEmptyKey);
      }
      return firstRequiredEmptyKey ? false : true;
    },
    onChildrenClicked(children, key) {
      this.$refs.contents.choiceItemByTag(key);
      children.setSelected();
    },
    handleSheetInput(evt, index) {
      if(this.mode === ViewMode.MODE_EDIT) {
        this.sheetList.splice(index, 1, evt.sheet);
        this.update();
        this.$emit('input', this.sheetList);
      } else if(this.mode === ViewMode.MODE_VIEW && !this.readOnly) {
        this.sheetRecordList.splice(index, 1, evt.sheetRecord);
        this.$emit('input', this.sheetRecordList);
      }
    },
    handleSheetItemInsert(evt) {
      this.doShowSheetItemTypes([evt]);
    },
    handleClickMenuSheet(sheet) {
      this.setSelectionByKey(this.keyOfSheet(sheet));
    },
    handleClickMenuSheetPage(sheetPage) {
      this.setSelectionByKey(this.keyOfSheetPage(sheetPage));
    },
    handleClickMenuSheetItem(sheetItem) {
      this.setSelectionByKey(this.keyOfSheetItem(sheetItem));
    },
    handleContentsInput() {
      this.update();
      this.$emit('input', this.sheetList);
    },
    handleResize(evt) {
      this.containerHeight = evt.height;
    },
    copySelected() {
      let selectedTags = this.$refs.contents.getChoiceTags();
      if (selectedTags.length == 0) {
        this.$message.warning('请先选择1个目标');
      } else if (selectedTags.length > 1) {
        this.$message.warning('只能复制单个目标');
        return;
      }
      let copiedKeys = this.copySheetItems(this.sheetList, selectedTags);
      if (copiedKeys.length > 0) {
        this.update();
        setTimeout(() => {
          let contentsItem = this.$refs.contents.getContentsItemByTag(copiedKeys[0])
          if (contentsItem) {
            contentsItem.click();
          }
        }, 1);
      }
      this.$emit('input', this.sheetList);
    },
    deleteSelected() {
      this.deleteDialogVisible = true;
    },
    handleDeleteConfirm() {
      let selectedTags = this.$refs.contents.getChoiceTags();
      this.deleteSheetItems(this.sheetList, selectedTags);
      this.update();
      if (this.propertyDocker) this.propertyDocker.unmountPanel();
      this.$emit('input', this.sheetList);
    },
    addSheetPage() {
      let selectedTags = this.$refs.contents.getChoiceTags();
      let createdKey = this.createPageByAfter(this.sheetList, selectedTags);
      this.update();
      setTimeout(() => {
        let contentsItem = this.$refs.contents.getContentsItemByTag(createdKey);
        if (contentsItem) contentsItem.click();
      }, 1);
      this.$emit('input', this.sheetList);
    },
    addSheetItem() {
      let selectedTags = this.$refs.contents.getChoiceTags();
      this.doShowSheetItemTypes(selectedTags);
      this.$emit('input', this.sheetList);
    },
    doShowSheetItemTypes(sheetItemInsertTags) {
      let coordinate = this.getSheetItemInsertCoordinate(this.sheetList, sheetItemInsertTags);
      let disableSheetItemGroup = false;
      if (coordinate) {
        let sheetItem = this.sheetList[coordinate.sheet].pages[coordinate.page].sheetItems[coordinate.item];
        if (sheetItem && sheetItem.type === SheetUtils.GROUP && sheetItem.items.length === 0) {
          disableSheetItemGroup = true;
        } else if (coordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
          disableSheetItemGroup = true;
        }
      }
      this.sheetItemInsertKeys = sheetItemInsertTags;
      this.disableSheetItemGroup = disableSheetItemGroup;
      this.sheetItemTypesDialogVisible = true;
    },
    handleSheetItemTypePick(type) {
      let selectedTags = this.sheetItemInsertKeys;
      let createdKey = this.createSheetItemAfter(type, this.sheetList, selectedTags);
      this.update();
      setTimeout(() => {
        let contentsItem = this.$refs.contents.getContentsItemByTag(createdKey);
        if (contentsItem) contentsItem.click();
      }, 1);
      this.$emit('input', this.sheetList);
    },
    handleMove(evt) {
      let fromCoordinate = this.getCoordinateByKey(evt.from, this.sheetList);
      if (fromCoordinate) {
        let createdKey = this.moveSheetItem(evt.from, evt.to, evt.direct, this.sheetList);
        this.update();
        if (createdKey) {
          setTimeout(() => {
            let contentsItem = this.$refs.contents.getContentsItemByTag(createdKey);
            if (contentsItem) {
              contentsItem.click();
            }
          }, 1);
        }
      }
      this.$emit('input', this.sheetList);
    },
    handleShowAllClick() {
      this.isShowAbstract = false;
      this.$emit('update:show-abstract', this.isShowAbstract);
    }
  }
}
</script>

<style scoped>

.sheet-container {
  width: inherit;
  height: inherit;
  box-sizing: border-box;
}

.sheet-container, .scrollbar {
  width: inherit;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
}

.sheet-list {
  max-width: 512px;
  min-width: 380px;
  width: inherit;
  padding: 0px 16px;
}

.sheet-list.is-page-by-page {
  padding: 0 !important;
}

.sheet-list.is-show-abstract {
  overflow: hidden;
}

.footer-container {
  height: 0px;
}

.footer-container .footer {
  height: 48px;
  background: linear-gradient(white, white, white, white);
  box-shadow: 0 -32px 32px #f5f5f5;
  position: relative;
  top: -48px;
}

.btn-show-all {
  cursor: pointer;
}

.btn-show-all:hover {
  filter: brightness(90%);
}

.footer-slot-container {
  overflow: hidden;
}


</style>