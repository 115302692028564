<template>
  <div>
    <transition name="el-fade-in">
      <div ref="property"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PropertyDocker",
  data() {
    return {
      propertyPanel: null,
    }
  },
  methods: {
    mountPanel(panel) {
      if (this.propertyPanel) {
        this.unmountPanel();
      }
      let MyPanel = Vue.extend(panel);
      this.propertyPanel = new MyPanel().$mount();
      this.propertyPanel.$on('input', this.handleInput);
      this.propertyPanel.$emit('mounted', this.propertyPanel);
      this.propertyPanel.sheet = {name: 'abc'}
      this.propertyPanel.$forceUpdate();
      this.$emit('mounted', this.propertyPanel);
      this.$refs.property.appendChild(this.propertyPanel.$el);
      return this.propertyPanel;
    },
    unmountPanel() {
      if (this.propertyPanel) {
        this.propertyPanel.$emit('unmounted', this.propertyPanel);
        this.propertyPanel.$destroy();
        this.propertyPanel.$off();
        this.propertyPanel.$el.parentNode.removeChild(this.propertyPanel.$el);
        this.$emit('unmounted', this.propertyPanel);
        this.propertyPanel = null;
      }
    },
    handleInput(evt) {
      this.$emit('input', evt);
    }
  },
  destroyed() {
    if (this.propertyPanel) this.unmountPanel();
  },
  deactivated() {
    if (this.propertyPanel) this.unmountPanel();
  }
}
</script>

<style scoped>

</style>