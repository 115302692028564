<template>
  <div class="sheet-item-property__certificate padding" v-if="sheetItem">
    <div><span class="font-color-primary font-weight-bold"><i class="fa fa-list-ul"></i> 身份证输入</span></div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible">占位文字：</span>
      <ys-textarea v-model="sheetItem.placeholder" placeholder="请输入占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemCertificatePropertyPanel",
  components: {SheetItemUserDataBinder, YsSwitch, YsTextarea},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input');
    }
  }
}
</script>

<style scoped>

</style>