const defaultCertificate = () => {
    return {
        type: CertificateCard.TYPE_ID_CARD,
        number: null,
    }
}

const CertificateCard = {
    TYPE_ID_CARD: 0,

    defaultCertificate: defaultCertificate,
}

export default CertificateCard;