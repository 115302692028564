<template>
  <div class="ys-address-picker">
    <el-popover v-model="visible" v-bind="$attrs">
      <slot slot="reference" name="reference">
        <div class="reference">
          <span v-if="address"></span>
          <span v-else class="font-color-placeholder">请选择地址</span>
        </div>
      </slot>
      <div class="layout-horizontal layout-middle">
        <div class="line line-top">
          <div class="line-1"></div>
          <div class="line-2"></div>
        </div>
        <div class="dot dot-active"></div>
        <div>中国</div>
      </div>
      <div class="layout-horizontal layout-middle">
        <div class="line" :class="{'line-bottom' : !province}">
          <div class="line-1"></div>
          <div class="line-2"></div>
        </div>
        <div class="dot dot-active"></div>
        <div class="address-level font-color-placeholder" @click="handleProvinceLevelClick">
          <span v-if="province" class="font-color-normal">{{ province }}</span>
          <span v-else>请选择省份</span>
        </div>
      </div>
      <transition name="el-fade-in">
      <div class="layout-horizontal layout-middle" v-if="province">
        <div class="line" :class="{'line-bottom' : !city}">
          <div class="line-1"></div>
          <div class="line-2"></div>
        </div>
        <div class="dot dot-active"></div>
        <div class="address-level font-color-placeholder" @click="handleCityLevelClick">
          <span v-if="city" class="font-color-normal">{{ city }}</span>
          <span v-else>请选择城市</span>
        </div>
      </div>
      </transition>
      <transition name="el-fade-in">
        <div class="layout-horizontal layout-middle" v-if="city">
          <div class="line line-bottom">
            <div class="line-1"></div>
            <div class="line-2"></div>
          </div>
          <div class="dot dot-active"></div>
          <div class="address-level font-color-placeholder" @click="handleDistrictLevelClick">
            <span v-if="district" class="font-color-normal">{{ district }}</span>
            <span v-else>请选择区/县</span>
          </div>
        </div>
      </transition>
      <div class="margin-top">
        <div class="font-size-small font-color-secondary margin-bottom-small">常选城市：</div>
        <ys-button type="secondary" size="small" @click="handleShanghaiClick">上海</ys-button>
        <ys-button type="secondary" size="small" class="margin-left-small" @click="handleGuangzhouClick()">广州
        </ys-button>
        <ys-button type="secondary" size="small" class="margin-left-small" @click="handleHangzhouClick()">杭州</ys-button>
      </div>
      <div class="margin-top" v-if="level === LEVEL_PROVINCE">
        <div class="font-size-small font-color-danger">请选择省份：</div>
        <ys-infinite-scroll-view class="margin-top-small choice-list">
          <div
              class="choice-item"
              :class="{'is-active': province === provinceItem.name}"
              v-for="provinceItem of provinceList"
              :key="provinceItem.id"
              @click="handleProvinceClick(provinceItem)"
          >{{ provinceItem.name }}
          </div>
        </ys-infinite-scroll-view>
      </div>
      <div class="margin-top" v-else-if="level === LEVEL_CITY">
        <div class="font-size-small font-color-danger">请选择城市：</div>
        <ys-infinite-scroll-view class="margin-top-small choice-list">
          <div
              class="choice-item"
              :class="{'is-active': city === cityItem.name}"
              v-for="cityItem of cityList"
              :key="cityItem.id"
              @click="handleCityClick(cityItem)"
          >{{ cityItem.name }}
          </div>
        </ys-infinite-scroll-view>
      </div>
      <div class="margin-top" v-else-if="level === LEVEL_DISTRICT">
        <div class="font-size-small font-color-danger">请选择区/县：</div>
        <ys-infinite-scroll-view class="margin-top-small choice-list">
          <div
              class="choice-item"
              :class="{'is-active': district === districtItem.name}"
              v-for="districtItem of districtList"
              :key="districtItem.id"
              @click="handleDistrictClick(districtItem)"
          >{{ districtItem.name }}
          </div>
        </ys-infinite-scroll-view>
      </div>
    </el-popover>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";


function doLoadProvince() {
  this.$reqGet({
    path: '/address/province/list/get',
  }).then(res => {
    this.provinceList = res.data;
    if (this.province) {
      let provinceId = null;
      for (let province of this.provinceList) {
        if (this.province === province.name) {
          provinceId = province.id;
          break;
        }
      }
      if (provinceId != null) {
        this.provinceId = provinceId;
        this.loadCity();
      } else {
        this.province = null;
        this.city = null;
        this.district = null;
        this.level = this.LEVEL_PROVINCE;
      }
    }
  }).catch(() => {
    this.$message.error('加载失败');
  })
}

function doLoadCity(provinceId) {
  this.$reqGet({
    path: '/address/city/list/get',
    data: {
      provinceId,
    }
  }).then(res => {
    if (this.provinceId === provinceId) {
      this.cityList = res.data;
      if (this.cityList.length === 1 && !this.city) {
        this.city = this.cityList[0].name;
        this.level = this.LEVEL_DISTRICT;
      }
      if (this.city) {
        let cityId = null;
        for (let city of this.cityList) {
          if (this.city === city.name) {
            cityId = city.id;
            break;
          }
        }
        if (cityId != null) {
          this.cityId = cityId;
          this.loadDistrict();
        } else {
          this.city = null;
          this.district = null;
          this.level = this.LEVEL_CITY;
        }
      }

    }
  }).catch(() => {
    this.$message.error('加载失败');
  })
}

function doLoadDistrict(cityId) {
  this.$reqGet({
    path: '/address/district/list/get',
    data: {
      cityId,
    }
  }).then(res => {
    if (this.cityId === cityId) {
      this.districtList = res.data;
      if (this.district) {
        let districtId = null;
        for (let district of this.districtList) {
          if (this.district === district.name) {
            districtId = district.id;
            break;
          }
        }
        if (districtId == null) {
          this.district = null;
          this.level = this.LEVEL_DISTRICT;
        }
      }
    }
  }).catch(() => {
    this.$message.error('加载失败');
  })
}

export default {
  name: "YsAddressPicker",
  mixins: [httpapi],
  components: {YsInfiniteScrollView, YsButton},
  props: {
    address: Object,
  },
  data() {
    return {
      visible: false,
      province: null,
      city: null,
      district: null,
      provinceId: null,
      provinceList: [],
      cityId: null,
      cityList: [],
      districtList: [],
      level: 0,
      LEVEL_PROVINCE: 0,
      LEVEL_CITY: 1,
      LEVEL_DISTRICT: 2,
    }
  },
  watch: {
    visible: {
      handler() {
        if (this.visible) {
          this.loadProvince();
        }
      },
      immediate: true,
    },
    address: {
      handler() {
        if (this.address) {
          this.province = this.address.province;
          this.city = this.address.city;
          this.district = this.address.district;
        } else {
          this.province = null;
          this.city = null;
          this.district = null;
        }
        if (!this.province) {
          this.city = null;
          this.district = null;
          this.level = this.LEVEL_PROVINCE;
        } else if (!this.city) {
          this.district = null;
          this.level = this.LEVEL_CITY;
        } else {
          this.level = this.LEVEL_DISTRICT;
        }
        this.loadProvince();
      },
      immediate: true,
    }
  },
  methods: {
    loadProvince() {
      doLoadProvince.bind(this)();
    },
    loadCity() {
      doLoadCity.bind(this)(this.provinceId);
    },
    loadDistrict() {
      doLoadDistrict.bind(this)(this.cityId);
    },
    handleProvinceClick(province) {
      this.province = province.name;
      let loadCity = false;
      if (this.provinceId != province.id || this.cityList.length == 0) {
        loadCity = true;
        this.city = null;
        this.district = null;
        this.cityList = [];
        this.districtList = [];
      }
      this.provinceId = province.id;
      this.level = this.LEVEL_CITY;
      if (loadCity) this.loadCity();
    },
    handleCityClick(city) {
      this.city = city.name;
      let loadDistrict = false;
      if (this.cityId != city.id || this.districtList.length == 0) {
        loadDistrict = true;
        this.district = null;
        this.districtList = [];
      }
      this.cityId = city.id;
      this.level = this.LEVEL_DISTRICT;
      if (loadDistrict) this.loadDistrict();
    },
    handleDistrictClick(district) {
      this.district = district.name;
      this.visible = false;
      this.$emit('pick', {
        province: this.province,
        city: this.city,
        district: this.district,
      })
    },
    handleProvinceLevelClick() {
      this.level = this.LEVEL_PROVINCE;
    },
    handleCityLevelClick() {
      if (this.province) {
        this.level = this.LEVEL_CITY;
      }
    },
    handleDistrictLevelClick() {
      if (this.province && this.city) {
        this.level = this.LEVEL_DISTRICT;
      }
    },
    handleShanghaiClick() {
      this.province = '上海';
      this.city = '上海';
      this.district = null;
      this.level = this.LEVEL_DISTRICT;
      this.loadProvince();
    },
    handleGuangzhouClick() {
      this.province = '广东';
      this.city = '广州';
      this.district = null;
      this.level = this.LEVEL_DISTRICT;
      this.loadProvince();
    },
    handleHangzhouClick() {
      this.province = '浙江';
      this.city = '杭州';
      this.district = null;
      this.level = this.LEVEL_DISTRICT;
      this.loadProvince();
    }
  },
}
</script>

<style scoped>

.reference {
  height: 40px;
  cursor: pointer;
  text-align: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.reference:hover {
  background-color: #f1f1f1;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 50%;
  margin-right: 10px;
}

.dot-active {
  background-color: #ff5500;
}

.line {
  width: 2px;
  height: 40px;
  position: relative;
  left: 6px;
}

.line > div {
  height: 50%;
  width: 2px;
  background-color: #ff5500;
}

.line.line-top > .line-1 {
  background-color: transparent !important;
}

.line.line-bottom > .line-2 {
  background-color: transparent !important;
}

.address-level {
  cursor: pointer;
}

.address-level:hover {
  opacity: 0.7;
}

.choice-item {
  padding: 5px 0px;
  cursor: pointer;
}

.choice-list {
  height: 200px;
}

.choice-item:hover {
  background-color: #f1f1f1;
}

.choice-item.is-active {
  color: #3a8ee6;
  font-weight: bold !important;
}

.font-color-normal {
  color: #686868;
}

</style>