<template>
  <div class="sheet-item-property__string padding" v-if="sheetItem">
    <div><span class="font-color-primary font-weight-bold"><i class="fa fa-list-ul"></i> 文本输入</span></div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible">占位文字：</span>
      <ys-textarea v-model="sheetItem.placeholder" placeholder="请输入占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal layout-middle">
      <span class="layout-inflexible">字数限制：</span>
      <ys-number v-model="maxLength" placeholder="请输入字数限制" :digits="0" :min="ExactNumber.of(1)"
                 :max="ExactNumber.of(1000000)"
                 @input="handleMaxLengthInput"></ys-number>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetUtils from "@/assets/javascript/sheet-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import YsNumber from "@/components/wedigets/YsNumber";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemStringPropertyPanel",
  components: {SheetItemUserDataBinder, YsNumber, YsTextarea, YsSwitch},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
      maxLength: null,
      ExactNumber,
    }
  },
  watch: {
    sheetItem: {
      handler() {
        this.maxLength = this.sheetItem && this.sheetItem.maxLength ? ExactNumber.of(this.sheetItem.maxLength) : ExactNumber.of(100);
      },
      immediate: true,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    },
    handleMaxLengthInput(maxLength) {
      this.sheetItem.maxLength = maxLength ? ExactNumber.toFloat(maxLength) : 20;
      this.handleInput();
    }
  }
}
</script>

<style scoped>

</style>