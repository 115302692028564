<script>
import ImageUtils from "@/assets/javascript/image-utils";
import StringUtils from "@/assets/javascript/StringUtils";
import SheetUtils from "@/assets/javascript/sheet-utils";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";

export default {
  name: "SheetChecker",
  mixins: [SheetItemKeyGenerator],
  methods: {
    checkSheets(sheets, silence) {
      let errorKey = null;
      for (let sheet of sheets) {
        errorKey = this.checkSheet(sheet, silence);
        if (errorKey) return errorKey;
      }
      return null;
    },
    checkSheet(sheet, silence = false) {
      if (StringUtils.isEmpty(sheet.name)) {
        if (!silence) this.$message.error('表单标题不能为空');
        return this.keyOfSheet(sheet);
      }
      if (sheet.pages.length == 0) {
        if (!silence) this.$message.error('至少需要一个表单页');
        return this.keyOfSheetPage(sheet);
      }
      for (let sheetPage of sheet.pages) {
        if (sheetPage.sheetItems.length == 0) {
          if (!silence) this.$message.error(`第${sheetPage.number}页，至少需要一个表单项`)
          return this.keyOfSheetPage(sheetPage);
        }
        for (let sheetItem of sheetPage.sheetItems) {
          if (sheetItem.type != SheetUtils.GROUP) {
            if (StringUtils.isEmpty(sheetItem.title)) {
              if (!silence) this.$message.error(`第${sheetPage.number}页，第${sheetItem.number}题，标题不能为空`);
              return this.keyOfSheetItem(sheetItem);
            }
          }
          if (sheetItem.type == SheetUtils.CHOICE) {
            if (sheetItem.items.length <= 1) {
              if (sheetItem.items.length == 1 && sheetItem.withOthers) {
                //当有其他选项时，选择题可以只有一个选项
              } else {
                if (!silence) this.$message.error(`第${sheetPage.number}页，第${sheetItem.number}题，选择题至少需要两个选项`);
                return this.keyOfSheetItem(sheetItem);
              }
            }
            for (let choiceItem of sheetItem.items) {
              if (StringUtils.isEmpty(choiceItem.value) && !choiceItem.image) {
                if (!silence) this.$message.error(`第${sheetPage.number}页，第${sheetItem.number}题，选项不能为空`);
                return this.keyOfSheetItem(sheetItem);
              }
            }
          }
          if (sheetItem.type == SheetUtils.GROUP) {
            for (let childSheetItem of sheetItem.items) {
              if (childSheetItem.type == SheetUtils.CHOICE) {
                if (childSheetItem.items.length <= 1) {
                  if (!silence) this.$message.error(`第${sheetPage.number}页，第${sheetItem.number}组，第${childSheetItem.number}题，选择题至少需要两个选项`)
                  return this.keyOfSheetItem(childSheetItem);
                }
                for (let choiceItem of childSheetItem.items) {
                  if (StringUtils.isEmpty(choiceItem.value) && !choiceItem.image) {
                    if (!silence) this.$message.error(`第${sheetPage.number}页，第${sheetItem.number}组，第${childSheetItem.number}题，选项不能为空`);
                    return this.keyOfSheetItem(childSheetItem);
                  }
                }
              }
            }
          }
        }
      }
      if (ImageUtils.containsUncommittedLocalImages(this.sheet)) {
        if (!silence) this.$message.warning('等待图片上传');
        return this.keyOfSheet(sheet);
      }
      return null;
    }
  }
}
</script>

<style scoped>

</style>