<script>
import GuidUtils from "@/assets/javascript/guid-utils";

export default {
  methods: {
    keyOfSheet(sheet, number = 0) {
      if (!sheet.globalId) sheet.globalId = GuidUtils.guid();
      return `sheet#${sheet.globalId}#${number}`;
    },
    keyOfSheetPage(page, number = 0) {
      if (!page.globalId) page.globalId = GuidUtils.guid();
      return `page#${page.globalId}#${number}`
    },
    keyOfSubSheetPage(page, number = 0) {
      if (!page.globalId) page.globalId = GuidUtils.guid();
      return `s_page#${page.globalId}#${number}`
    },
    keyOfSheetItem(sheetItem, number = 0) {
      if (!sheetItem.globalId) sheetItem.globalId = GuidUtils.guid();
      return `item#${sheetItem.globalId}#${number}`;
    },
    getPageIndexOfKey(key, sheet) {
      let lastIndexOfSharp = key.lastIndexOf('#');
      key = key.substring(0, lastIndexOfSharp + 1) + '0';
      for (let pageIndex = 0; pageIndex < sheet.pages.length; pageIndex++) {
        let page = sheet.pages[pageIndex];
        if (key === this.keyOfSheetPage(page)) {
          return pageIndex;
        }
        for (let item of page.sheetItems) {
          if (key === this.keyOfSheetItem(item)) {
            return pageIndex;
          }
        }
      }
      return -1;
    }
  }
}
</script>

<style scoped>

</style>