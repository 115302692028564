<script>

import Utils from "@/assets/javascript/utils";
import SheetUtils from "@/assets/javascript/sheet-utils";

function doParseSheet(sheetModel) {
  let pages = new Array();

  let sheet = {
    name: sheetModel.name,
    message: sheetModel.message,
    globalId: sheetModel.globalId,
    links: Utils.deepCopy(sheetModel.links),
    pages,
  };

  for (let pageModel of sheetModel.pages) {
    pages.push(doParseSheetPage(pageModel, sheet));
  }

  return sheet;
}

function doParseSheetPage(sheetPageModel, sheet) {
  let sheetItems = new Array();
  for (let subPageModel of sheetPageModel.pages) {
    for (let sheetItemModel of subPageModel.items) {
      let duplicated = false;
      for (let tmp of sheetItems) {
        if (sheetItemModel.number === tmp.number) {
          duplicated = true;
          break;
        }
      }
      if (!duplicated) sheetItems.push(doParseSheetItem(sheetItemModel, sheet));
    }
  }

  return {
    number: sheetPageModel.number,
    name: sheetPageModel.name,
    message: sheetPageModel.message,
    globalId: sheetPageModel.globalId,
    sheetItems,
  }
}

function doParseSheetItem(sheetItemModel, sheet) {
  if (sheetItemModel.type === SheetUtils.GROUP) {
    return doParseSheetItemGroup(sheetItemModel, sheet);
  } else {
    if (sheetItemModel.boundUserDataList.length > 0) {
      if (!sheet.dataDefinitionMap) sheet.dataDefinitionMap = {};
      sheet.dataDefinitionMap[sheetItemModel.globalId] = Utils.deepCopy(sheetItemModel.boundUserDataList);
    }
    return Utils.deepCopy(sheetItemModel.template);
  }
}

function doParseSheetItemGroup(itemGroupModel, sheet) {
  let items = new Array();
  for (let sheetItemModel of itemGroupModel.items) {
    let duplicated = false;
    for (let tmp of items) {
      if (tmp.number === sheetItemModel.number) {
        duplicated = true;
        break;
      }
    }
    if (!duplicated) items.push(doParseSheetItem(sheetItemModel, sheet));
  }
  let sheetItemGroup = Utils.deepCopy(itemGroupModel.template);
  sheetItemGroup.items = items;
  return sheetItemGroup;
}

export default {
  methods: {
    doParseSheet(sheetModel) {
      return doParseSheet(sheetModel);
    },
    doParseSheetItemGroup(groupModel) {
      return doParseSheetItemGroup(groupModel);
    }
  }
}
</script>

<style scoped>

</style>