<template>
  <div class="sheet-item-property__choice padding" v-if="sheetItem">
    <div class="font-size-small"><span class="font-color-primary font-weight-bold"><span class="fas fa-list-ul"></span> 选择题</span>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入选择题标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal">
      <span>多选：</span>
      <ys-switch v-model="sheetItem.multiple" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal">
      <span>显示其他选项：</span>
      <ys-switch v-model="sheetItem.withOthers" @input="handleInput"></ys-switch>
    </div>
    <div class="divider margin-top"></div>
    <div class="margin-top margin-bottom">选项：</div>
    <ys-movable @move="handleChoiceItemMove">
      <ys-movable-item v-for="(choiceItem, index) in sheetItem.items"
                       :key="choiceItem.guid" style="width: 320px;" :tag="index">
        <div class="choice-item padding-left padding-vertical-small layout-horizontal layout-middle"
             @dblclick="handleChoiceItemDbClick(choiceItem, index)">
          <div class="layout-flexible" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            <span class="font-color-primary margin-right-small"
                  v-if="choiceItem.score">{{ ExactNumber.stringify(choiceItem.score) }}"</span>
            <span v-if="choiceItem.value">{{ choiceItem.value }}</span>
            <span v-else class="font-color-danger">未命名</span>
          </div>
          <i class="far fa-times-circle padding-horizontal icon-delete font-color-danger"
             @click="handleChoiceItemDeleteClick(index)"></i>
        </div>
      </ys-movable-item>
      <ys-movable-item disabled v-if="sheetItem.items.length < 100">
        <div class="choice-item padding-left padding-vertical-small layout-horizontal layout-middle"
             @dblclick="handleChoiceItemAddClick">
          <div class="layout-flexible font-color-primary"
               style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            <ys-click-to-edit v-model="choiceItemInputted" @focus="handleChoiceItemAddFocus" @blur="handleChoiceItemAddBlur">
              <span><i class="fa fa-plus-circle"></i> 双击此处添加选项</span>
            </ys-click-to-edit>
          </div>
        </div>
      </ys-movable-item>
    </ys-movable>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
    <choice-item-edit-dialog :value="choiceItem" :visible.sync="choiceItemDialogVisible"
                             @input="handleChoiceItemInput"></choice-item-edit-dialog>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsMovable from "@/components/wedigets/YsMovable";
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import ChoiceItemEditDialog from "@/components/sheet/dialog/ChoiceItemEditDialog";
import ExactNumber from "@/assets/javascript/exact-number";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemChoicePropertyPanel",
  components: {
    SheetItemUserDataBinder,
    YsClickToEdit, ChoiceItemEditDialog, YsMovableItem, YsMovable, YsSwitch, YsTextarea},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
      choiceItemDialogVisible: false,
      choiceItem: null,
      choiceItemIndex: null,
      choiceItemInputted: null,
      ExactNumber,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    },
    handleChoiceItemInput(choiceItem) {
      let duplicated = false;
      for(let n = 0; n < this.sheetItem.items.length; n++) {
        if(n != this.choiceItemIndex) {
          if(this.sheetItem.items[n].value === choiceItem.value) {
            duplicated = true;
            break;
          }
        }
      }

      if(duplicated) {
        this.$message.warning('选项重复');
        return;
      }
      this.choiceItem = choiceItem;
      this.sheetItem.items.splice(this.choiceItemIndex, 1, choiceItem);

      let number = 1;
      for(let item of this.sheetItem.items) {
        item.number = number ++;
      }

      this.handleInput();
    },
    handleChoiceItemDbClick(choiceItem, index) {
      this.choiceItem = choiceItem;
      this.choiceItemIndex = index;
      this.choiceItemDialogVisible = true;
    },
    handleChoiceItemAddClick() {
      this.choiceItemIndex = this.sheetItem.items.length;
      let lastChoiceItem = null;
      if (this.sheetItem.items.length > 0) {
        lastChoiceItem = this.sheetItem.items[this.sheetItem.items.length - 1];
      }
      this.choiceItem = SheetUtils.defaultChoiceItem(lastChoiceItem ? lastChoiceItem.number + 1 : 1, null, null, null);
      this.choiceItemDialogVisible = true;
    },
    handleChoiceItemMove(evt) {
      let direct = evt.direct;
      let from = evt.from;
      let to = evt.to;
      if (from < to) {
        to--;
      }
      if (direct === 'bottom') {
        to++;
      }
      let choiceItem = this.sheetItem.items[from];
      this.sheetItem.items.splice(from, 1);
      this.sheetItem.items.splice(to, 0, choiceItem);
      this.handleInput();
    },
    handleChoiceItemDeleteClick(index) {
      this.sheetItem.items.splice(index, 1);
      let number = 1;
      for(let item of this.sheetItem.items) {
        item.number = number ++;
      }
      this.handleInput();
    },
    handleChoiceItemAddFocus() {
      this.choiceItemInputted = null;
    },
    handleChoiceItemAddBlur() {
      if(this.choiceItemInputted) {
        let duplicated = false;
        for(let n = 0; n < this.sheetItem.items.length; n++) {
          if(n != this.choiceItemIndex) {
            if(this.sheetItem.items[n].value === this.choiceItemInputted) {
              duplicated = true;
              break;
            }
          }
        }

        if(duplicated) {
          this.$message.warning('选项重复');
          return;
        }

        let score = null;
        if(this.sheetItem.items.length > 0) {
          let lastItem = this.sheetItem.items[this.sheetItem.items.length - 1];
          score = lastItem.score ? ExactNumber.of(lastItem.score.value + 1, lastItem.score.digits, lastItem.score.unit) : null;
        }
        this.sheetItem.items.push({
          value: this.choiceItemInputted,
          score,
        })

        let number = 1;
        for(let item of this.sheetItem.items) {
          item.number = number ++;
        }
        this.handleInput();
      }
    }
  }
}
</script>

<style scoped>

.choice-item {
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 4px;
  cursor: pointer;
  width: 320px;
}

.choice-item:hover {
  background-color: #faf4f5;
}

.icon-delete {
  opacity: 0;
}

.choice-item:hover .icon-delete {
  opacity: 1;
}
</style>