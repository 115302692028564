<template>
  <div class="ys-image-picker" @click="handleImagePickerClick">
    <el-upload
        ref="uploader"
        class="mask upload"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        list-type="picture-card"
        :multiple="false"
        :on-change="imageFilePicked"
        :disabled="disabled"
        accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP">
      <div slot="trigger" class="layout-vertical" ref="trigger">
      </div>
    </el-upload>
    <slot>
      <el-image
          v-if="innerImage"
          class="image"
          :class="{
                'is-upload-failed': isUploadFailed,
              }"
          fit="cover"
          :src="$prepareImageUrl(innerImage)"
      ></el-image>
      <div v-else class="image-upload layout-vertical layout-center layout-middle font-color-placeholder">
        <div class="font-size-extra-large"><i class="fas fa-camera"></i></div>
        <div class="font-size-extra-small">选择图片</div>
      </div>
    </slot>
    <div class="image-mask__container">
      <div class="image-mask font-align-right">
        <div style="height: 0px;" v-if="image">
          <div class="btn-delete" @click.stop="handleDeleteClick">
            <span class="fas fa-minus-circle font-color-danger"></span>
          </div>
        </div>
        <div style="width: 100%; height: 100%;" class="layout-vertical layout-center layout-middle">
          <transition name="el-fade-in">
            <div class="margin-top-large font-align-center" v-if="isUploading">
              <div class="font-size-small font-color-light"
                   style="filter: drop-shadow(0 0 5px #000000);"
              >上传中：{{ progress }}%
              </div>
              <ys-progressbar :progress="progress" class="margin-horizontal-small margin-top-small"></ys-progressbar>
            </div>
          </transition>
          <transition name="el-fade-in">
            <div class="margin-top font-align-center" v-if="isUploadFailed" @click.stop="startUpload">
              <div class="font-size-small font-color-light"
                   style="filter: drop-shadow(0 0 5px #ff5500);">上传失败
              </div>
              <ys-button round size="small" lighting type="secondary" class="margin-top-small">
                <i class="fas fa-redo"></i>
              </ys-button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import ImageUtils from "@/assets/javascript/image-utils";
import YsProgressbar from "@/components/wedigets/YsProgressbar";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "YsImagePicker",
  components: {YsButton, YsProgressbar},
  mixins: [httpapi],
  props: {
    image: Object,
    disabled: Boolean,
    onlyPick: Boolean,
    autoUpload: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      innerImage: null,
      isUploading: false,
      isUploadFailed: false,
      progress: 0,
    }
  },
  watch: {
    image: {
      handler() {
        this.innerImage = this.image;
        if (this.innerImage?.__localImageFile) {
          if(this.autoUpload) {
            this.startUpload();
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    click: function () {
      this.$refs.trigger.click();
    },
    handleImagePickerClick() {
      this.$refs.trigger.click();
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    imageFilePicked(file) {
      let image = ImageUtils.defaultLocalImage(file);
      this.$emit('update:image', image);
      if (this.onlyPick) return;
      this.innerImage = image;
      if(this.autoUpload) {
        this.startUpload();
      }
    },
    startUpload() {
      if (this.isUploading) return;
      let image = this.innerImage;
      if (!image?.__localImageFile) return;
      this.isUploading = true;

      this.response = this.$uploadImage({
        imageFile: image.__localImageFile,
      }).then(res => {
        this.innerImage = res.data;
        this.$emit('update:image', this.innerImage);
        this.$emit('finished');
        this.isUploadFailed = false;
      }).progress(progress => {
        this.progress = progress;
      }).catch((e) => {
        this.isUploadFailed = true;
      }).complete(() => {
        this.isUploading = false;
      });
    }
  }
}
</script>

<style scoped>

.upload {
  width: 0px;
  height: 0px;
}

.upload >>> .el-upload {
  width: 0px;
  height: 0px;
}

.ys-image-picker {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
  background-color: #f1f1f1;
  cursor: pointer;
}

.image {
  width: inherit;
  height: inherit;
  display: block;
}

.image-upload {
  width: inherit;
  height: inherit;
}

.image-mask__container {
  height: 0px;
}

.image-mask {
  width: inherit;
  height: inherit;
  position: relative;
  top: -100%;
}

.ys-image-picker .btn-delete {
  visibility: hidden;
  background-color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
}

.ys-image-picker:hover .btn-delete {
  visibility: visible;
}

</style>