<template>
  <div class="font-align-right layout-vertical layout-right">
    <ys-movable-item @update:moving="updateMovingState" inner-class="layout-right" :tag="tag"
      :disable-moving="disable || titleEdit === 'edit' || disableMoving" :disable-touching="disableMoving"
      :disable-touching-top="disableTouchingTop"
      :disable-touching-bottom="disableTouchingBottom">
      <slot>
        <div ref="content" class="contents-item layout-horizontal layout-middle"
             :class="{
           'contents-item__level1': level === 1,
           'contents-item__level2': level === 2,
           'contents-item__level3': level === 3,
           'contents-item__level4': level >= 4,
           'is-choice': isChoice,
           'is-choice__by-ancestor': multiChoice && isChoiceByAncestor
          }" @click.stop="handleClick">
          <span class="margin-right-small" v-if="multiChoice && enableMultiChoice">
            <i class="fas fa-check-square font-color-danger" style="opacity: 0.5;" v-if="isChoiceByAncestor"></i>
            <i :class="[`${isChoice ? 'fas font-color-danger' : 'far font-color-placeholder'}`, 'fa-check-square']" v-else></i>
          </span>
          <i class="far" :class="showChildren && showChildrenTemp ? 'fa-minus-square' : 'fa-plus-square'"
             v-if="$slots.children" @mousedown.stop="handleMouseDown" @mouseup.stop="handleMouseUp" @click.stop="handleShowChildrenClick"></i>
          <ys-click-to-edit v-model="value" style="text-overflow: ellipsis; margin-left: 4px; width: 100%;" :mode.sync="titleEdit" :disable="disable || disableEdit"
            @blur="handleTitleBlur" :placeholder="placeholder ? placeholder : '未命名'"></ys-click-to-edit>
        </div>
        <el-collapse-transition v-if="!moving" name="normal-expand">
          <div v-show="showChildren && showChildrenTemp" class="layout-vertical layout-right">
            <ys-movable v-if="subMovableRegion">
              <slot name="children"></slot>
            </ys-movable>
            <slot v-else name="children"></slot>
          </div>
        </el-collapse-transition>
      </slot>
    </ys-movable-item>
  </div>
</template>

<script>
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import YsMovable from "@/components/wedigets/YsMovable";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";

export default {
  name: "ContentsItem",
  components: {YsClickToEdit, YsMovable, YsMovableItem},
  inject: {
    provideData: {
      type: Object,
    }
  },
  provide() {
    return {
      provideData: this.provideToChild,
    }
  },
  props: {
    value: String,
    subMovableRegion: Boolean,
    enableMultiChoice: {
      type: Boolean,
      default: true,
    },
    moveToChildren: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    tag: String,
    disableMoving: Boolean,
    disable: Boolean,
    disableTouchingTop: Boolean,
    disableTouchingBottom: Boolean,
    disableEdit: Boolean,
  },
  computed: {
    titleStr() {
      let title = this.title;
      if(title) {
        if(title.length > 18) {
          return title.substring(0, 15) + '...';
        } else {
          return title;
        }
      } else {
        return '...';
      }
    }
  },
  data() {
    return {
      level: 1,
      provideToChild: {
        level: 2,
        multiChoice: false,
      },
      showChildren: true,
      showChildrenTemp: true,
      moving: false,
      mouseDownX: 0,
      mouseDownY: 0,
      children: [],
      requireRefresh: false,
      parent: null,
      fatherItem: null,
      multiChoice: false,
      isChoice: false,
      isChoiceByAncestor: false,
      stackMoving: false,
      titleEdit: 'view',
    }
  },
  watch: {
    'provideData.level': {
      handler() {
        this.level = this.provideData ? this.provideData.level : this.level;
        this.provideToChild.level = this.level + 1;
      },
      immediate: true,
    },
    'provideData.multiChoice': {
      handler() {
        this.multiChoice = this.provideData ? this.provideData.multiChoice : false;
        this.provideToChild.multiChoice = this.multiChoice;
      }
    },
    isChoice: {
      handler() {
        if(!this.isChoice) {
          for(let children of this.children) {
            children.isChoiceByAncestor = false;
          }
        }
      }
    },
    isChoiceByAncestor: {
      handler() {
        if(this.isChoiceByAncestor) this.parent.unchoice(this);
        for(let children of this.children) {
          children.isChoiceByAncestor = this.isChoiceByAncestor;
        }
      },
      immediate: true,
    },
    multiChoice: {
      handler() {
        if(!this.multiChoice) {
          this.isChoiceByAncestor = false;
        } else {
          if(this.isChoice) {
            for(let children of this.children) {
              children.isChoiceByAncestor = true;
            }
          }
        }
      },
      immediate: true,
    }
  },
  mounted() {
    setTimeout(() => this.showChildren = true, 2000);
    let parent = this.$parent;
    while (parent && !parent.isContents) {
      parent = parent.$parent;
    }
    this.parent = parent;
    if (parent) {
      parent.refreshChildren();
    }

    let fatherItem = this.$parent;
    while (fatherItem && !fatherItem.isContentsItem) {
      if(fatherItem.isContents) break;
      fatherItem = fatherItem.$parent;
    }
    this.fatherItem = fatherItem;
    if(fatherItem) {
      fatherItem.refreshChildren();
    }
  },
  destroyed() {
    this.$off();
    if (this.parent) this.parent.refreshChildren();
    if(this.fatherItem) this.fatherItem.refreshChildren();
  },
  methods: {
    isContentsItem(parent) {
      return this.parent === parent;
    },
    click() {
      this.$refs.content.click();
    },
    handleMouseDown(evt) {
      this.mouseDownX = evt.x;
      this.mouseDownY = evt.y;
    },
    handleMouseUp(evt) {
      if (Math.abs(this.mouseDownX - evt.x) < 2 && Math.abs(this.mouseDownY - evt.y) < 2) {
        //this.showChildren = !this.showChildren;
      }
    },
    handleShowChildrenClick() {
      if(this.moving) return;
      this.showChildren = !this.showChildren;
    },
    handleClick(evt) {
      if(this.disable) return;
      if(this.multiChoice && this.isChoiceByAncestor) {
        if(!this.isChoice) return;
      }
      this.isChoice = !this.isChoice;
      let parent = this.parent;
      if(parent) {
        if (this.isChoice) {
          parent.choice(this, this.multiChoice && this.enableMultiChoice);
        } else {
          parent.unchoice(this);
        }
      }
      if(this.multiChoice) {
        for (let children of this.children) {
          children.isChoiceByAncestor = this.isChoice;
        }
      } else {
        for(let children of this.children) {
          children.isChoiceByAncestor = false;
        }
      }
      this.$emit('click', evt);
    },
    handleTitleBlur() {
      this.$emit('input', this.value);
    },
    updateMovingState(isMoving) {
      if (this.moving === isMoving) return;
      this.isChoice = false;
      this.moving = isMoving;
      if(!this.moveToChildren && this.parent) {
        if (this.moving) {
          this.parent.pickUp(this.level);
        } else {
          this.parent.spread(this.level);
        }
      }
    },
    refreshChildren() {
      this.requireRefresh = true;
      this.$nextTick(()=>{
        if(this.requireRefresh) {
          this.requireRefresh = false;
          this.children = this.doRefreshChildrens(this);
          for(let children of this.children) {
            children.isChoiceByAncestor = this.isChoiceByAncestor;
          }
        }
      });
    },
    doRefreshChildrens(view) {
      let childrens = new Array();
      for(let children of view.$children) {
        if(children.isContentsItem) {
          childrens.push(children);
        } else {
          childrens = childrens.concat(this.doRefreshChildrens(children));
        }
      }
      return childrens;
    }
  }
}
</script>

<style scoped>

.contents-item {
  width: 336px;
  text-align: left;
  padding: 4px 8px 4px 4px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  margin-right: 4px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.contents-item:hover {
  background-color: #faf4f5;
}

.contents-item.is-choice {
  color: white !important;
  font-weight: bold;
  background-color: #f091a6;
  box-shadow: 0 0 4px #f091a6;
}

.contents-item.is-choice__by-ancestor {
  background-color: #ffdae2;
}

.contents-item.is-choice:hover {
  color: white !important;
}

.contents-item.contents-item__level1 {
  width: calc(20vw);
  max-width: 312px;
}

.contents-item.contents-item__level2 {
  width: calc(20vw - 8px);
  max-width: 304px;
}

.contents-item.contents-item__level3 {
  width: calc(20vw - 16px);
  max-width: 296px;
}

.contents-item.contents-item__level4 {
  width: calc(20vw - 24px);
  max-width: 288px;
}


</style>