<template>
  <div class="sheet-item sheet-item__phone" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} {{ simulatedInputText }}</div>
    <div class="title layout-horizontal layout-middle padding-left-large">
        <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div :class="mode === ViewMode.MODE_VIEW ? 'layout-inflexible' : 'layout-flexible'">
        <ys-click-to-edit v-model="title" placeholder="双击此处添加标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                          type="textarea" style="width: 100%;" v-if="mode === ViewMode.MODE_EDIT"
                          @blur="handleTitleBlur"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-flexible layout-middle layout-right" v-if="!isBigGap">
        <ys-click-to-edit v-if="mode === ViewMode.MODE_EDIT" type="textarea" v-model="placeholder"
                          placeholder="双击此处添加占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                          class="font-color-placeholder"
                          style="text-align: right !important; width: 100%;"
                          @blur="handlePlaceholderBlur"></ys-click-to-edit>
        <ys-input
            type="phone"
            style="width: 100%; text-align: right;"
            :maxlength="SheetUtils.PHONE_MAX_LENGTH"
            :show-background="false"
            :placeholder="placeholder ? placeholder : '请输入'"
            v-model="phoneInputted"
            @input="handlePhoneInput"
            v-else-if="!readOnly"></ys-input>
        <div v-else-if="phoneStr" class="padding-right-large">{{phoneStr}}</div>
        <div v-else class="font-color-placeholder font-align-right padding-right-large">未填写</div>
      </div>
    </div>
    <div v-if="isBigGap" class="padding-horizontal-large padding-bottom layout-vertical layout-right">
      <ys-click-to-edit v-if="mode === ViewMode.MODE_EDIT" type="textarea" v-model="placeholder"
                        placeholder="双击此处添加占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                        class="font-color-placeholder"
                        style="text-align: right !important; width: 100%"
                        @blur="handlePlaceholderBlur"></ys-click-to-edit>
      <ys-input type="phone"
                style="width: 100%; text-align: right;"
                :maxlength="SheetUtils.PHONE_MAX_LENGTH"
                :show-background="false"
                :placeholder="placeholder ? placeholder : '请输入'"
                v-model="phoneInputted"
                @input="handlePhoneInput"
                v-else-if="!readOnly"></ys-input>
      <div v-else-if="phoneStr" class="padding-right-large">{{phoneStr}}</div>
      <div v-else class="font-color-placeholder padding-right-large">未填写</div>
    </div>
  </div>
</template>

<script>
import {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsInput from "@/components/wedigets/YsInput";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemPhonePropertyPanel from "@/components/sheet/panel/SheetItemPhonePropertyPanel";
import TextUtils from "@/assets/javascript/text-utils";
import PhoneUtils from "@/assets/javascript/phone-utils";

export default {
  name: "SheetItemPhone",
  mixins: [SheetItemBasic],
  components: {YsInput, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  computed: {
    simulatedInputText() {
      let result = '1234567890123';
      let placeholder = this.sheetItem.placeholder ? this.sheetItem.placeholder : '请输入';
      if (placeholder.length > result) result = placeholder;
      return result;
    },
    phoneStr() {
      if(this.sheetItemRecord && this.sheetItemRecord.phone) {
        return this.sheetItemRecord.phone.phone;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      placeholder: null,
      ViewMode,
      SheetUtils,
      isBigGap: false,
      propertyPanel: null,

      phoneInputted: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.placeholder = this.sheetItem.placeholder;
        if(this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        this.phoneInputted = this.sheetItemRecord?.phone?.phone;
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePlaceholderBlur() {
      this.sheetItem.placeholder = this.placeholder;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 80;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.placeholder = this.sheetItem.placeholder;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handlePhoneInput() {
      if(this.sheetItemRecord) {
        if(TextUtils.isBlank(this.phoneInputted)) {
          this.sheetItemRecord.phone = null;
        } else {
          if(!this.sheetItemRecord.phone) {
            this.sheetItemRecord.phone = PhoneUtils.defaultPhone(this.phoneInputted);
          } else {
            this.sheetItemRecord.phone.phone = this.phoneInputted;
          }
        }
      }
    },
    setSelected() {
      if(this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if(propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemPhonePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}


.text-measurer {
  height: 0px;
  overflow: hidden;
  position: absolute;
}
</style>