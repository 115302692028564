<template>
  <div class="sheet-property padding" v-if="sheet">
    <div class="font-size-small title"><span class="font-color-primary font-weight-bold"><i class="fa fa-table"></i> 表单</span></div>
    <div class="layout-horizontal margin-top">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>名称：</span>
      <ys-textarea class="layout-flexible" v-model="sheet.name" placeholder="请输入表单名称" :maxlength="SheetUtils.SHEET_NAME_MAX_LENGTH"
        @input="handleInput"></ys-textarea>
    </div>
    <div class="layout-horizontal margin-top">
      <span class="layout-inflexible">说明：</span>
      <ys-textarea class="layout-flexible" v-model="sheet.message" placeholder="请输入表单说明（选填）" :maxlength="SheetUtils.SHEET_MESSAGE_MAX_LENGTH"
        @input="handleInput"></ys-textarea>
    </div>
  </div>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetUtils from "@/assets/javascript/sheet-utils";

export default {
  name: "SheetPropertyPanel",
  components: {YsTextarea},
  props: {
    title: String,
    sheet: Object,
  },
  data() {
    return {
      SheetUtils,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheet);
    }
  },
  destroyed() {
    console.log('panel destroyed');
  },

}
</script>

<style scoped>

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>