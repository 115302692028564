<template>
  <div class="sheet" v-if="sheetModel">
    <div
        v-for="(page, index) in sheetModel.pages"
        :key="page.key"
    >
      <transition name="el-fade-in">
        <sheet-page ref="sheetPages"
                    v-if="!pageByPage || pageIndex === index"
                    :hide-border="pageByPage"
                    :page="page"
                    :mode="mode"
                    :read-only="readOnly"
                    class="margin-top"
                    @input="handleInput"
                    :property-docker="propertyDocker"
                    @insert="handleInsert"></sheet-page>
      </transition>
    </div>
  </div>
</template>

<script>
import SheetPage from "@/components/sheet/SheetPage";
import {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetViewModelCreator from "@/components/sheet/basic/SheetViewModelCreator";
import SheetViewModelParser from "@/components/sheet/basic/SheetViewModelParser";
import SheetPropertyPanel from "@/components/sheet/panel/SheetPropertyPanel";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";
import GuidUtils from "@/assets/javascript/guid-utils";
import SheetRecordViewModelCreator from "@/components/sheet/basic/SheetRecordViewModelCreator";
import SheetRecordViewModelParser from "@/components/sheet/basic/SheetRecordViewModelParser";
import LinkResultEngine from "@/components/sheet/basic/LinkResultEngine";
import SheetUtils from "@/assets/javascript/sheet-utils";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";

export default {
  name: "Sheet",
  mixins: [
    SheetItemKeyGenerator,
    SheetViewModelCreator,
    SheetViewModelParser,
    SheetRecordViewModelCreator,
    SheetRecordViewModelParser,
    LinkResultEngine,
  ],
  components: {SheetPage},
  props: {
    sheet: {
      type: Object,
      default: null,
    },
    record: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageByPage: {
      type: Boolean,
      default: false,
    },
    propertyDocker: Element,
  },
  data() {
    return {
      parent: null,
      sheetModel: null,
      propertyPanel: null,
      childrenMap: {},  //根据key查找子组件
      keyMap: {}, //根据子组件查找对应的key
      guid: GuidUtils.guid(),
      parserTimeoutId: null,
      pageIndex: 0,
    }
  },
  computed: {
    inputParams() {
      const {sheet, record, mode} = this;
      return {
        sheet,
        record,
        mode,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.update();
      },
      immediate: true,
    },
    sheetModel: {
      handler() {
        let propertyPanel = this.propertyPanel;
        if (propertyPanel) propertyPanel.sheet = this.sheetModel;
      },
      immediate: true,
    },
    page: {
      handler() {
        this.pageIndex = this.page;
      },
      immediate: true,
    }
  },
  methods: {
    isSheet(parent) {
      return this.parent === parent;
    },
    handleInput() {
      switch (this.mode) {
        case ViewMode.MODE_EDIT:
          this.doHandleInputOnEditMode();
          break;
        case ViewMode.MODE_VIEW:
          this.doHandleInputOnViewMode();
          break;
      }
    },
    doHandleInputOnEditMode() {
      if (this.parserTimeoutId) {
        clearTimeout(this.parserTimeoutId);
      }
      this.parserTimeoutId = setTimeout(() => {
        let sheet = this.doParseSheet(this.sheetModel);
        this.$emit('input', {mode: this.mode, sheet});
      }, 200);
    },
    doHandleInputOnViewMode() {
      if (this.parserTimeoutId) {
        clearTimeout(this.parserTimeoutId);
      }
      this.parserTimeoutId = setTimeout(() => {
        this.describeLinks(this.sheetModel);
        this.renderLinkResults(this.sheetModel);
        let sheetRecord = this.doParseSheetRecord(this.sheetModel);
        this.$emit('input', {mode: this.mode, sheet: this.sheet, sheetRecord})
      }, 200)
    },
    setSelectionByKey(key) {
      let children = this.childrenMap[key];
      if (children && typeof children.setSelected === 'function') {
        children.setSelected();
        return true;
      }
      return false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetPropertyPanel);
        this.propertyPanel.sheet = this.sheetModel;
        this.propertyPanel.$on('input', this.handleInput);
      }
    },
    getChildrenByKey(key) {
      return this.childrenMap[key];
    },
    registerChildren(key, children) {
      let previousKey = this.keyMap[children.guid];
      if (key != previousKey) {
        this.unregisterChildren(children);
      }
      if (key) {
        this.keyMap[children.guid] = key;
        this.childrenMap[key] = children;
      }
    },
    unregisterChildren(children) {
      let key = this.keyMap[children.guid];
      if (key) {
        delete this.keyMap[children.guid];
        delete this.childrenMap[key];
      }
    },
    registerSelf() {
      let parent = this.parent;
      let key = this.sheet ? this.keyOfSheet(this.sheet) : null;
      if (parent && key) parent.registerChildren(key, this);
    },
    unregisterSelf() {
      let parent = this.parent;
      if (parent) parent.unregisterChildren(this);
    },
    handleDocumentClick(evt) {
      let clickedChildren = null;
      for (let key in this.childrenMap) {
        let children = this.childrenMap[key];
        if (children.$el.contains(evt.target)) {
          if (clickedChildren) {
            if (clickedChildren.$el.contains(children.$el)) {
              clickedChildren = children;
            }
          } else {
            clickedChildren = children;
          }
        }
      }
      if (clickedChildren) {
        let key = this.keyMap[clickedChildren.guid];
        let parent = this.parent;
        if (parent) parent.onChildrenClicked(clickedChildren, key);
      }
    },
    handleInsert(evt) {
      this.$emit('insert', evt);
    },
    doAddListeners() {
      document.addEventListener('click', this.handleDocumentClick);
    },
    doRemoveListeners() {
      document.removeEventListener('click', this.handleDocumentClick);
    },
    update() {
      let sheet = this.sheet;
      if (!sheet.globalId) sheet.globalId = GuidUtils.guid();
      let sheetModel = null;
      if (sheet) {
        switch (this.mode) {
          case ViewMode.MODE_EDIT:
            sheetModel = this.doCreateSheetModel(sheet);
            break;
          case ViewMode.MODE_VIEW:
            sheetModel = this.createSheetRecordModel(sheet, this.record);
            this.describeLinks(sheetModel);
            break;
        }
      }
      this.sheetModel = sheetModel;
      if (this.sheetModel) {
        this.registerSelf();
      } else {
        this.unregisterSelf();
      }
    },
    clearEmptyGroupModel() {
      for (let page of this.sheetModel.pages) {
        for (let subPage of page.pages) {
          for (let n = subPage.items.length - 1; n >= 0; n--) {
            let item = subPage.items[n];
            if (item.type !== SheetUtils.GROUP) continue;
            let isEmpty = true;
            for (let subItem of item.items) {
              if (!SheetRecordUtils.isSheetItemRecordEmpty(subItem.record, subItem.template)) {
                isEmpty = false;
                break;
              }
            }
            if (isEmpty) {
              let groupRecordCount = 0;
              for (let tmp of subPage.items) {
                if (tmp.number === item.number) groupRecordCount++;
                if (groupRecordCount > 1) break;
              }
              if (groupRecordCount > 1) {
                subPage.items.splice(n, 1);
              }
            }
          }
        }
      }
    },
    checkBeforeSubmit() {
      if (this.mode !== ViewMode.MODE_VIEW) return true;
      let firstRequiredItemKey = null;

      this.clearEmptyGroupModel();

      for (let page of this.sheetModel.pages) {
        for (let subPage of page.pages) {
          for (let item of subPage.items) {
            item.isEmptyChecked = false;
            if (item.type === SheetUtils.ILLUSTRATION) continue;
            if (item.type !== SheetUtils.GROUP) {
              if (SheetRecordUtils.isSheetItemRecordEmpty(item.record, item.template)) {
                if (!item.hide && !item.template.optional) {
                  if (!firstRequiredItemKey) {
                    firstRequiredItemKey = item.key;
                    item.isEmptyChecked = true;
                  }
                }
              }
            } else {
              let isGroupEmpty = true;
              for (let subItem of item.items) {
                if (subItem.type === SheetUtils.ILLUSTRATION) continue;
                subItem.isEmptyChecked = false;
                let isSubItemEmpty = SheetRecordUtils.isSheetItemRecordEmpty(subItem.record, subItem.template);
                if (isSubItemEmpty) {
                  if (!subItem.hide && !subItem.template.optional) {
                    if (!firstRequiredItemKey) {
                      firstRequiredItemKey = subItem.key;
                      subItem.isEmptyChecked = true;
                    }
                  }
                } else {
                  isGroupEmpty = false;
                }
              }
              if (isGroupEmpty && !item.hide && !item.template.optional) {
                if (!firstRequiredItemKey) {
                  firstRequiredItemKey = item.key;
                  item.isEmptyChecked = true;
                }
              }
            }
          }
        }
      }
      if (!firstRequiredItemKey) {
        this.describeLinks(this.sheetModel);
        let sheetRecord = this.doParseSheetRecord(this.sheetModel);
        this.$emit('input', {mode: this.mode, sheet: this.sheet, sheetRecord})
      }
      return firstRequiredItemKey;
    }
  },
  mounted() {
    let parent = this.$parent;
    while (parent && !parent.isSheetContainer) {
      parent = parent.$parent;
    }
    this.parent = parent;
    if (parent) {
      parent.refreshChildren();
    }
    this.registerSelf();
    this.doAddListeners();
  },
  destroyed() {
    if (this.parent) {
      this.parent.refreshChildren();
    }
    if (this.parserTimeoutId) {
      clearTimeout(this.parserTimeoutId);
    }
    this.unregisterSelf();
    this.doRemoveListeners();
  },
  activated() {
    this.registerSelf();
    this.doAddListeners();
  },
  deactivated() {
    if (this.parserTimeoutId) {
      clearTimeout(this.parserTimeoutId);
    }
    this.parserTimeoutId = null;
    this.unregisterSelf();
    this.doRemoveListeners();
  }
}
</script>

<style scoped>

.sheet {
  position: relative;
  width: 380px;
}

</style>