<template>
  <div class="sheet-item sheet-item__address" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} 浙江 · 杭州 · 那然色布斯台音布拉格</div>
    <div class="title layout-horizontal layout-middle padding-horizontal-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div :class="mode === ViewMode.MODE_EDIT ? 'layout-flexible' : 'layout-inflexible'">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            style="width: 100%;"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            @blur="handleTitleBlur"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-horizontal layout-right"
           :class="mode === ViewMode.MODE_EDIT ? 'layout-inflexible' : 'layout-flexible'" v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <ys-address-picker
              style="width: 100%"
              :disabled="mode === ViewMode.MODE_EDIT"
              @pick="handleAddressPicked"
          >
            <div class="address-picked layout-horizontal layout-right layout-middle"
                 :class="{'is-disabled': mode === ViewMode.MODE_EDIT}" slot="reference">
              <span v-if="addressPicked" class="font-color-normal">{{ addressPicked }}</span>
              <span v-else class="font-color-placeholder">请选择地址 <i class="fa fa-angle-right"></i></span>
            </div>
          </ys-address-picker>
        </template>
        <template v-else>
          <div v-if="sheetItemRecord.address">
            <span>{{ sheetItemRecord.address.province }}</span>
            <span class="margin-left" v-if="sheetItemRecord.address.city">{{ sheetItemRecord.address.city }}</span>
            <span class="margin-left" v-if="sheetItemRecord.address.district">{{
                sheetItemRecord.address.district
              }}</span>
          </div>
          <div v-else class="font-color-placeholder">未选择</div>
        </template>
      </div>
    </div>
    <div v-if="isBigGap" class="padding-horizontal-large padding-bottom-large">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <ys-address-picker
            style="width: 100%"
            :disabled="mode === ViewMode.MODE_EDIT"
            @pick="handleAddressPicked"
        >
          <div class="address-picked layout-horizontal layout-right layout-middle"
               :class="{'is-disabled': mode === ViewMode.MODE_EDIT}" slot="reference">
            <span v-if="addressPicked" class="font-color-normal">{{ addressPicked }}</span>
            <span v-else class="font-color-placeholder">请选择地址 <i class="fa fa-angle-right"></i></span>
          </div>
        </ys-address-picker>
      </template>
      <template v-else>
        <div v-if="sheetItemRecord.address">
          <span>{{ sheetItemRecord.address.province }}</span>
          <span class="margin-left" v-if="sheetItemRecord.address.city">{{ sheetItemRecord.address.city }}</span>
          <span class="margin-left" v-if="sheetItemRecord.address.district">{{
              sheetItemRecord.address.district
            }}</span>
        </div>
      </template>
    </div>
    <div v-if="sheetItem.withDetail" class="padding-bottom padding-left-large layout-horizontal">
      <span
          class="font-size-small font-color-secondary layout-inflexible layout-self-stretch layout-horizontal layout-middle padding padding-small margin-right"
          style="background-color: #f1f1f1; border-radius: 8px;">地址详情</span>
      <div class="layout-flexible" v-if="mode === ViewMode.MODE_VIEW">
        <ys-textarea
            v-if="!readOnly"
            style="width: 100%"
            :show-background="false"
            :placeholder="sheetItem.detailPlaceholder"
            :maxlength="SheetUtils.ADDRESS_DETAIL_MAX_LENGTH"
            show-word-limit
            v-model="addressDetailInputted"
            @input="handleAddressDetailInput"
        ></ys-textarea>
        <div v-else-if="sheetItemRecord.address && sheetItemRecord.address.detail">
          {{ sheetItemRecord.address.detail }}
        </div>
        <div v-else class="font-color-placeholder">未填写</div>
      </div>
      <div class="layout-flexible padding-left" v-else>
        <ys-click-to-edit
            type="textarea"
            v-model="detailPlaceholder"
            :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
            style="width: 100%"
            placeholder="双击此处添加占位文字"
            @blur="handlePlaceholderBlur"
        ></ys-click-to-edit>

      </div>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsAddressPicker from "@/components/wedigets/YsAddressPicker";
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemAddressPropertyPanel from "@/components/sheet/panel/SheetItemAddressPropertyPanel";
import AddressUtils from "@/assets/javascript/address-utils";

export default {
  name: "SheetItemAddress",
  mixins: [SheetItemBasic],
  components: {YsTextarea, YsAddressPicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      detailPlaceholder: null,
      isBigGap: false,
      ViewMode,
      SheetUtils,
      propertyPanel: null,

      addressDetailInputted: null,
    }
  },
  computed: {
    addressPicked() {
      if (this.sheetItemRecord && this.sheetItemRecord.address) {
        let address = this.sheetItemRecord.address;
        let result = "";
        if (address.province) {
          result += address.province
        }
        if (address.city) {
          result += " " + address.city;
        }
        if (address.district) {
          result += " " + address.district;
        }
        return result;
      } else {
        return null;
      }
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.detailPlaceholder = this.sheetItem.detailPlaceholder;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if (this.sheetItemRecord) {
          this.addressDetailInputted = this.sheetItemRecord.address ? this.sheetItemRecord.address.detail : null;
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 80;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePlaceholderBlur() {
      this.sheetItem.detailPlaceholder = this.detailPlaceholder;
      this.$emit('input', this.item);
    },
    handlePropertyInput(sheetItem) {
      this.sheetItem = sheetItem;
      this.title = this.sheetItem.title;
      this.detailPlaceholder = this.sheetItem.detailPlaceholder;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleAddressPicked(evt) {
      if (this.sheetItemRecord) {
        if (!this.sheetItemRecord.address) {
          this.sheetItemRecord.address = AddressUtils.defaultAddress();
          this.sheetItemRecord.address.detail = this.addressDetailInputted;
        }
        this.sheetItemRecord.address.province = evt.province;
        this.sheetItemRecord.address.city = evt.city;
        this.sheetItemRecord.address.district = evt.district;
        this.$emit('input', this.item);
      }
    },
    handleAddressDetailInput() {
      if (this.sheetItemRecord && this.sheetItemRecord.address) {
        this.sheetItemRecord.address.detail = this.addressDetailInputted;
        this.$emit('input', this.item);
      }
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemAddressPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.address-picked {
  height: 40px;
  cursor: pointer;
}

.address-picked:hover {
  background-color: #f1f1f1;
}

.address-picked.is-disabled {
  cursor: not-allowed;
}

.text-measurer {
  position: absolute;
  height: 0px;
  background-color: #ff5500;
  overflow: hidden;
}
</style>