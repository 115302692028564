<template>
  <div v-if="item">
    <template v-if="item.type !== SheetUtils.ILLUSTRATION">
      <div v-if="isBigGap && !item.groupKey" class="sheet-item-gap__big"></div>
      <div v-else class="sheet-item-gap__small">
        <div class="divider"></div>
      </div>
    </template>
    <sheet-item-group
        ref="sheetItem"
        :group="item"
        :mode="mode"
        :is-big-gap.sync="isBigGap"
        @input="handleInput"
        @add:group="handleAddGroupClick"
        @delete:group="handleDeleteGroupClick"
        v-bind="$attrs"
        v-if="item.type === SheetUtils.GROUP" ></sheet-item-group>
    <sheet-item ref="sheetItem" :item="item" :mode="mode" :is-big-gap.sync="isBigGap" v-else @input="handleInput" v-bind="$attrs"></sheet-item>
  </div>
</template>

<script>
import SheetUtils , {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetItem from "@/components/sheet/SheetItem";
import SheetItemGroup from "@/components/sheet/items/SheetItemGroup";

export default {
  name: "SheetItemWrapper",
  components: {SheetItemGroup, SheetItem},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    }
  },
  data() {
    return {
      ViewMode,
      SheetUtils,
      isBigGap: false,
    }
  },
  computed: {
    showRequiredBar() {
      if(this.item?.type === SheetUtils.GROUP) {
        if(this.item.isEmptyChecked) return true;
        for(let subItem of this.item.items) {
          if(subItem.isEmptyChecked) return true;
        }
        return false;
      } else {
        return this.item?.isEmptyChecked
      }
    }
  },
  watch: {
    '$refs.sheetItem.isBigGap': {
      handler() {
        if(this.$refs.sheetItem) {
          this.isBigGap = this.$refs.sheetItem.isBigGap;
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleInput(evt) {
      this.$emit('input', evt);
    },
    setSelected() {
      this.$refs.sheetItem.setSelected();
    },
    handleAddGroupClick(evt) {
      this.$emit('add:group', evt);
    },
    handleDeleteGroupClick(evt) {
      this.$emit('delete:group', evt);
    }
  }
}
</script>

<style scoped>

.sheet-item-gap__big {
  height: 5px;
  background-color: #f1f1f1;
}

.sheet-item-gap__small {
  background-color: white;
  padding: 0px 20px;
}

.sheet-item-gap__small > .divider {
  height: 1px;
  background-color: #f1f1f1;
}

.mark-required {
  background-color: #faf4f5;
}


</style>