import SheetUtils from "./sheet-utils";
import SheetItemType from "./sheet-utils";
import TextUtils from "./text-utils";
import Gender from "./gender";
import Sheet from "@/components/sheet/Sheet";

const defaultBaseSheetItemRecord = (type) => {
    return {
        number: 1,
        type: type
    }
}

function defaultChoiceSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.CHOICE);
    baseSheetItemRecord.selection = {
        selectedItems: [],
        others: [],
    }
    return baseSheetItemRecord;
}

function clearChoiceSheetItemRecord(choiceSheetItemRecord) {
    choiceSheetItemRecord.selection = {
        selectedItems: [],
        others: [],
    }
}

function defaultStringSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.STRING);
    baseSheetItemRecord.content = '';
    return baseSheetItemRecord;
}

function clearStringSheetItemRecord(stringSheetItemRecord) {
    stringSheetItemRecord.content = '';
}


const defaultNumberSheetItemRecord = () => {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.NUMBER);
    baseSheetItemRecord.value = null;
    return baseSheetItemRecord;
}

function clearNumberSheetItemRecord(numberSheetItemRecord) {
    numberSheetItemRecord.value = null;
}

function defaultGenderSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.GENDER);
    baseSheetItemRecord.gender = null;
    return baseSheetItemRecord;
}

function clearGenderSheetItemRecord(genderSheetItemRecord) {
    genderSheetItemRecord.gender = null;
}


function defaultAddressSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.ADDRESS);
    baseSheetItemRecord.address = null;
    return baseSheetItemRecord;
}

function clearAddressSheetItemRecord(addressSheetItemRecord) {
    addressSheetItemRecord.address = null;
}

function defaultCertificateSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.CERTIFICATE);
    baseSheetItemRecord.certificate = null;
    return baseSheetItemRecord;
}

function clearCertificateSheetItemRecord(certificateSheetItemRecord) {
    certificateSheetItemRecord.certificate = null;
}

function defaultEthnicitySheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.ETHNICITY);
    baseSheetItemRecord.ethnicity = null;
    return baseSheetItemRecord;
}

function clearEthnicitySheetItemRecord(ethnicitySheetItemRecord) {
    ethnicitySheetItemRecord.ethnicity = null;
}

function defaultDateSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.DATE);
    baseSheetItemRecord.date = null;
    return baseSheetItemRecord;
}

function clearDateSheetItemRecord(dateSheetItemRecord) {
    dateSheetItemRecord.date = null;
}

function defaultTimeSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.TIME);
    baseSheetItemRecord.time = null;
    return baseSheetItemRecord;
}

function clearTimeSheetItemRecord(timeSheetItemRecord) {
    timeSheetItemRecord.time = null;
}

function defaultDateTimeSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.DATETIME);
    baseSheetItemRecord.dateTime = null;
    return baseSheetItemRecord;
}

function clearDateTimeSheetItemRecord(dateTimeSheetItemRecord) {
    dateTimeSheetItemRecord.dateTime = null;
}

function defaultImageSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.IMAGE);
    baseSheetItemRecord.images = [];
    return baseSheetItemRecord;
}

function clearImageSheetItemRecord(imageSheetItemRecord) {
    imageSheetItemRecord.images = [];
}

function defaultPhoneSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.PHONE);
    baseSheetItemRecord.phone = null;
    return baseSheetItemRecord;
}

function clearPhoneSheetItemRecord(phoneSheetItemRecord) {
    phoneSheetItemRecord.phone = null;
}

function defaultFileSheetItemRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.FILE);
    baseSheetItemRecord.resources = [];
    return baseSheetItemRecord;
}

function clearFileSheetItemRecord(fileSheetItemRecord) {
    fileSheetItemRecord.resources = [];
}

function defaultSheetItemGroupRecord() {
    let baseSheetItemRecord = defaultBaseSheetItemRecord(SheetUtils.GROUP);
    baseSheetItemRecord.items = [];
    baseSheetItemRecord.additionalSheetItems = [];
    return baseSheetItemRecord;
}

function clearSheetItemGroupRecord(sheetItemGroupRecord) {
    sheetItemGroupRecord.items = [];
    sheetItemGroupRecord.additionalSheetItems = [];
}

function defaultSheetItemRecord(type) {
    switch (type) {
        case SheetUtils.CHOICE:
            return defaultChoiceSheetItemRecord();
        case SheetUtils.STRING:
            return defaultStringSheetItemRecord();
        case SheetUtils.NUMBER:
            return defaultNumberSheetItemRecord();
        case SheetUtils.GENDER:
            return defaultGenderSheetItemRecord();
        case SheetUtils.ADDRESS:
            return defaultAddressSheetItemRecord();
        case SheetUtils.CERTIFICATE:
            return defaultCertificateSheetItemRecord();
        case SheetUtils.ETHNICITY:
            return defaultEthnicitySheetItemRecord();
        case SheetUtils.DATE:
            return defaultDateSheetItemRecord();
        case SheetUtils.TIME:
            return defaultTimeSheetItemRecord();
        case SheetUtils.DATETIME:
            return defaultDateTimeSheetItemRecord();
        case SheetUtils.IMAGE:
            return defaultImageSheetItemRecord();
        case SheetUtils.PHONE:
            return defaultPhoneSheetItemRecord();
        case SheetUtils.GROUP:
            return defaultSheetItemGroupRecord();
        case SheetUtils.FILE:
            return defaultFileSheetItemRecord();
        case SheetUtils.ILLUSTRATION:
            return {};
    }
    throw new Error("unsupported sheet item type: " + type);
}

function clearSheetItemRecord(sheetItemRecord) {
    switch (sheetItemRecord.type) {
        case SheetUtils.CHOICE:
            clearChoiceSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.STRING:
            clearStringSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.NUMBER:
            clearNumberSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.GENDER:
            clearGenderSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.ADDRESS:
            clearAddressSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.CERTIFICATE:
            clearCertificateSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.ETHNICITY:
            clearEthnicitySheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.DATE:
            clearDateSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.TIME:
            clearTimeSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.DATETIME:
            clearDateTimeSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.IMAGE:
            clearImageSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.PHONE:
            clearPhoneSheetItemRecord(sheetItemRecord);
            break;
        case SheetUtils.GROUP:
            clearSheetItemGroupRecord(sheetItemRecord);
            break;
        case SheetUtils.FILE:
            clearFileSheetItemRecord(sheetItemRecord);
            break;
    }
}

const defaultSheetPageRecord = (number = 1) => {
    let pageRecord = {
        number: typeof number === 'number' ? number : null,
        items: [],
        additionalSheetItems: [],
    }
    if (typeof number === 'object') {
        let page = number;
        for (let sheetItem of page.sheetItems) {
            let sheetItemRecord = defaultSheetItemRecord(sheetItem.type);
            if (sheetItem.type === SheetItemType.GROUP) {
                for (let subSheetItem of sheetItem.items) {
                    let subSheetItemRecord = defaultSheetItemRecord(subSheetItem.type);
                    sheetItemRecord.items.push(subSheetItemRecord);
                }
            }
            pageRecord.items.push(sheetItemRecord);
        }
        pageRecord.number = page.number;
    }
    return pageRecord;
}

const defaultSheetRecord = (sheet) => {
    let sheetRecord = {
        id: null,
        roleId: null,
        role: null,
        organizationId: null,
        accountId: null,
        time: new Date(),
        submitTime: new Date(),
        hidden: false,
        sheetId: null,
        modifiedCode: null,
        result: null,
        pages: [],
    }
    if (sheet) {
        for (let page of sheet.pages) {
            sheetRecord.pages.push(defaultSheetPageRecord(page));
        }
    }
    return sheetRecord;
}


function isChoiceSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord.selection) {
        return true;
    } else {
        return sheetItemRecord.selection.selectedItems.length == 0 && sheetItemRecord.selection.others.length == 0;
    }
}

function isStringSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return TextUtils.isBlank(sheetItemRecord.content);
}

function isNumberSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord.value) {
        return true;
    } else {
        let result = typeof sheetItemRecord.value.value == 'undefined' || sheetItemRecord.value.value == null;
        if (!result && sheetItem.units.length > 0) {
            result = !sheetItemRecord.value.unit;
        }
        return result;
    }
}

function isGenderSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    switch (sheetItemRecord.gender) {
        case Gender.MALE:
            return false;
        case Gender.FEMALE:
            return false;
        case Gender.KEEP:
            return false;
        default:
            return true;
    }
}

function isAddressSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord.address) {
        return true;
    } else {
        let address = sheetItemRecord.address;
        let result = TextUtils.isBlank(address.province) || TextUtils.isBlank(address.city) || TextUtils.isBlank(address.district);
        if (!result && sheetItem.withDetail) {
            result = TextUtils.isBlank(address.detail);
        }
        return result;
    }
}

function isCertificateSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord.certificate) {
        return true;
    } else {
        let certificate = sheetItemRecord.certificate;
        let result = TextUtils.isBlank(certificate.number) || typeof certificate.type == 'undefined' || certificate.type == null;
        return result;
    }
}

function isEthnicitySheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return TextUtils.isBlank(sheetItemRecord.ethnicity);
}

function isDateSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return TextUtils.isBlank(sheetItemRecord.date);
}

function isTimeSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return TextUtils.isBlank(sheetItemRecord.time);
}

function isDateTimeSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return TextUtils.isBlank(sheetItemRecord.dateTime);
}

function isImageSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return sheetItemRecord.images.length == 0;
}

function isPhoneSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord.phone) {
        return true;
    } else {
        return TextUtils.isBlank(sheetItemRecord.phone.phone);
    }
}

function isFileSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    return sheetItemRecord.resources.length == 0;
}

function isGroupSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (sheetItemRecord.items.length == 0) {
        return true;
    } else {
        for (let childSheetItem of sheetItem.items) {
            let childSheetItemRecord = null;
            for (let tmp of sheetItemRecord.items) {
                if (tmp.number === childSheetItem.number && tmp.type === childSheetItem.type) {
                    childSheetItemRecord = tmp;
                    break;
                }
            }
            if (childSheetItemRecord) {
                if (!isSheetItemRecordEmpty(childSheetItemRecord, childSheetItem)) {
                    return false;
                }
            }
        }
        return true;
    }
}

function isSheetItemRecordEmpty(sheetItemRecord, sheetItem) {
    if (!sheetItemRecord) return true;
    switch (sheetItemRecord.type) {
        case SheetItemType.CHOICE:
            return isChoiceSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.STRING:
            return isStringSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.NUMBER:
            return isNumberSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.GENDER:
            return isGenderSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.ADDRESS:
            return isAddressSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.CERTIFICATE:
            return isCertificateSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.ETHNICITY:
            return isEthnicitySheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.DATE:
            return isDateSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.TIME:
            return isTimeSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.DATETIME:
            return isDateTimeSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.IMAGE:
            return isImageSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.PHONE:
            return isPhoneSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.FILE:
            return isFileSheetItemRecordEmpty(sheetItemRecord, sheetItem);
        case SheetItemType.GROUP:
            return isGroupSheetItemRecordEmpty(sheetItemRecord, sheetItem);
    }
    throw new Error("unsupported sheet item type: " + sheetItemRecord.type);
}

const SheetRecordUtils = {
    defaultSheetItemRecord,
    clearSheetItemRecord,
    defaultSheetPageRecord: defaultSheetPageRecord,
    defaultSheetRecord: defaultSheetRecord,
    isSheetItemRecordEmpty,
}


export default SheetRecordUtils;