import ExactNumber from "@/assets/javascript/exact-number";
import CertificateCard from "@/assets/javascript/certificate-card";
import DateTimeLimit from "@/assets/javascript/datetime-limit";
import AddressUtils from "./address-utils";
import GuidUtils from "@/assets/javascript/guid-utils";

export const ViewMode = {
    MODE_VIEW: 'view',
    MODE_EDIT: 'edit',
}

const defaultBaseItem = (type, title, optional) => {
    return {
        number: 0,
        title: title,
        optional: optional,
        type: type,
        globalId: GuidUtils.guid(),
    }
}

const doDefaultChoiceItem = (number, value, score, image) => {
    return {
        number: number,
        value: !value ? value : value.trim(),
        score: !score ? null : score,
        image: image,
        rejective: false
    };
}

const defaultChoice = () => {
    let choiceItem = defaultBaseItem(SheetUtils.CHOICE, '', false);
    choiceItem.multiple = false;
    choiceItem.withOthers = false;
    choiceItem.items = [doDefaultChoiceItem(1, '选项1')]

    return choiceItem;
}

const defaultString = () => {
    let stringItem = defaultBaseItem(SheetUtils.STRING, '', false);
    stringItem.placeholder = null;
    stringItem.maxLength = 20;
    stringItem.multiline = false;
    return stringItem;
}

const defaultNumber = () => {
    let numberItem = defaultBaseItem(SheetUtils.NUMBER, '', false);
    numberItem.placeholder = null;
    numberItem.minValue = ExactNumber.of(0, 0);
    numberItem.maxValue = ExactNumber.of(1000, 0);
    numberItem.digits = 2;
    numberItem.units = [];
    return numberItem;
}

const defaultGender = () => {
    let genderItem = defaultBaseItem(SheetUtils.GENDER, '性别', false);
    return genderItem;
}

const defaultAddress = () => {
    let addressItem = defaultBaseItem(SheetUtils.ADDRESS, '', false);
    addressItem.withDetail = true;
    addressItem.detailPlaceholder = '请输入详细地址，例如：社区、街道、小区、门牌号';
    return addressItem;
}

const defaultCertificate = () => {
    let certificateItem = defaultBaseItem(SheetUtils.CERTIFICATE, '身份证', false);
    certificateItem.certificateTypes = [CertificateCard.TYPE_ID_CARD];
    certificateItem.placeholder = '请输入身份证号码';
    return certificateItem;
}

const defaultEthnicity = () => {
    let ethnicityItem = defaultBaseItem(SheetUtils.ETHNICITY, '民族', false);
    ethnicityItem.placeholder = '请选择民族';
    return ethnicityItem;
}

const defaultDate = () => {
    let dateItem = defaultBaseItem(SheetUtils.DATE, '日期', false);
    dateItem.limit = DateTimeLimit.NO_LIMIT;
    dateItem.defaultNow = false;
    dateItem.sheetDate = false;
    return dateItem;
}

const defaultTime = () => {
    let timeItem = defaultBaseItem(SheetUtils.TIME, '时间', false);
    timeItem.limit = DateTimeLimit.NO_LIMIT;
    timeItem.defaultNow = false;
    timeItem.sheetTime = false;
    return timeItem;
}

const defaultDateTime = () => {
    let dateTimeItem = defaultBaseItem(SheetUtils.DATETIME, '时间', false);
    dateTimeItem.limit = DateTimeLimit.NO_LIMIT;
    dateTimeItem.defaultNow = false;
    dateTimeItem.sheetDateTime = false;
    return dateTimeItem;
}

const defaultPhone = () => {
    let phoneItem = defaultBaseItem(SheetUtils.PHONE, '联系电话', false);
    phoneItem.placeholder = '请输入联系电话';
    return phoneItem;
}

const defaultImage = () => {
    let imageItem = defaultBaseItem(SheetUtils.IMAGE, '上传图片', false);
    imageItem.maxCount = 3;
    imageItem.demoImage = null;
    return imageItem;
}

const defaultItemGroup = () => {
    let itemGroup = defaultBaseItem(SheetUtils.GROUP, null, false);
    itemGroup.maxCount = 1;
    itemGroup.items = [];
    return itemGroup;
}

const defaultIllustration = () => {
    return defaultBaseItem(SheetUtils.ILLUSTRATION, '', true);
}

const defaultFile = () =>{
    let fileItem = defaultBaseItem(SheetUtils.FILE, '上传文件', true);
    fileItem.maxCount = 1;
    return fileItem;
}

const defaultSheetPage = () => {
    let sheetPage = {
        name: null,
        message: null,
        number: 0,
        sheetItems: [],
        globalId: GuidUtils.guid(),
    };
    for (let n = 0; n < sheetPage.sheetItems.length; n++) {
        sheetPage.sheetItems[n].number = n + 1;
    }
    return sheetPage;
}

const defaultSheet = () => {
    let sheet = {
        id: null,
        organizationId: 0,
        name: null,
        message: null,
        hidden: false,
        pages: [],
        dataDefinitionMap: {},
        globalId: GuidUtils.guid(),
    }
    for (let n = 0; n < sheet.pages.length; n++) {
        sheet.pages[n].number = n + 1;
    }
    return sheet;
}

const SheetUtils = {
    CHOICE: 0,
    STRING: 1,
    NUMBER: 2,
    GENDER: 3,
    ADDRESS: 4,
    CERTIFICATE: 5,
    ETHNICITY: 6,
    DATE: 7,
    TIME: 8,
    DATETIME: 9,
    IMAGE: 10,
    PHONE: 11,
    GROUP: 12,
    ILLUSTRATION: 13,
    FILE: 14,

    TITLE_MAX_LENGTH: 100,
    CHOICE_ITEM_MAX_LENGTH: 50,
    PLACEHOLDER_MAX_LENGTH: 30,
    STRING_VALUE_MAX_LENGTH: 10000,
    SCORE_DIGITS: 2,
    SINGLE_LINE_MAX_LENGTH: 25,
    UNIT_MAX_LENGTH: 100,
    ADDRESS_DETAIL_MAX_LENGTH: AddressUtils.ADDRESS_DETAIL_MAX_LENGTH,
    CERTIFICATE_MAX_LENGTH: 20,
    ETHNICITY_MAX_LENGTH: 50,
    PHONE_MAX_LENGTH: 11,
    PAGE_NAME_MAX_LENGTH: 80,
    PAGE_MESSAGE_MAX_LENGTH: 10000,
    SHEET_NAME_MAX_LENGTH: 80,
    SHEET_MESSAGE_MAX_LENGTH: 10000,

    defaultItem(type) {
        switch (type) {
            case this.CHOICE:
                return defaultChoice();
            case this.ILLUSTRATION:
                return defaultIllustration();
            case this.STRING:
                return defaultString();
            case this.NUMBER:
                return defaultNumber();
            case this.GENDER:
                return defaultGender();
            case this.ADDRESS:
                return defaultAddress();
            case this.CERTIFICATE:
                return defaultCertificate();
            case this.ETHNICITY:
                return defaultEthnicity();
            case this.DATE:
                return defaultDate();
            case this.TIME:
                return defaultTime();
            case this.DATETIME:
                return defaultDateTime();
            case this.PHONE:
                return defaultPhone();
            case this.IMAGE:
                return defaultImage();
            case this.GROUP:
                return defaultItemGroup();
            case this.FILE:
                return defaultFile();

        }
        throw new Error('unhandled sheet item type: ' + type);
    },
    defaultChoiceItem: doDefaultChoiceItem,
    defaultSheetPage: defaultSheetPage,
    defaultSheet: defaultSheet,
}

export default SheetUtils
