<template>

  <el-dialog
      class="dialog"
      fullscreen
      v-bind="$attrs"
      :visible.sync="isVisible">
    <div
        class="layout-horizontal"
        style="height: calc(100% - 88px); width: 100vw;"
    >
      <div class="layout-flexible layout-vertical layout-center">
        <sheet-container
            ref="sheetContainer"
            mode="edit"
            :sheet-list="innerSheetList"
            :property-docker="propertyDocker"
            @input="handleSheetInput"
            :multi-choice="multiChoice"
            style="height: calc(100vh - 56px);"
        >
        </sheet-container>
      </div>
      <div class="property-panel">
        <ys-infinite-scroll-view
            class="layout-inflexible"
            style="height: 100%; width: 300px;">
          <property-docker ref="propertyDocker"></property-docker>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <div
        class="footer layout-horizontal layout-middle layout-center"
    >
      <div class="font-size-medium">编辑表单</div>
      <div class="divider divider-vertical margin-horizontal-large"></div>
      <div class="btn" @click="handleAddSheetPageClick">
        <div class="font-size-large">
          <span class="far fa-file"></span>
        </div>
        <div>+页面</div>
      </div>
      <div class="btn" @click="handleAddSheetItemClick">
        <div class="font-size-large">
          <span class="fas fa-list-ul"></span>
        </div>
        <div>+题目</div>
      </div>
      <div class="btn" @click="handleMultiChoiceClick">
        <div class="font-size-large">
          <span class="far fa-check-square"></span>
        </div>
        <div>多选</div>
      </div>
      <div class="btn" @click="handleCopyClick">
        <div class="font-size-large">
          <span class="far fa-copy"></span>
        </div>
        <div>复制</div>
      </div>
      <div class="btn" @click="handleDeleteClick">
        <div class="font-size-large">
          <span class="far fa-trash-alt"></span>
        </div>
        <div>删除</div>
      </div>
      <div
          class="btn"
          :class="{
            disabled: historyIndex === 0
          }"
          @click="handleUndoClick">
        <div class="font-size-large">
          <span class="fas fa-undo"></span>
        </div>
        <div>撤销</div>
      </div>
      <div class="btn"
           :class="{
            disabled: historyIndex === history.length - 1
           }"
           @click="handleRedoClick">
        <div class="font-size-large">
          <span class="fas fa-redo"></span>
        </div>
        <div>重做</div>
      </div>
      <div class="divider divider-vertical margin-horizontal-large"></div>
      <ys-button type="secondary" @click="clickCancel">取消</ys-button>
      <ys-button type="primary" class="margin-left-large" @click="clickConfirm">确定</ys-button>
    </div>
  </el-dialog>
</template>

<script>
import SheetContainer from "@/components/sheet/SheetContainer";
import YsButton from "@/components/wedigets/YsButton";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import PropertyDocker from "@/components/property/PropertyDoker";
import Utils from "@/assets/javascript/utils";
import SheetChecker from "@/components/sheet/basic/SheetChecker";
import SheetUtils from "@/assets/javascript/sheet-utils";

export default {
  name: "SheetEditDialog",
  mixins: [SheetChecker],
  components: {PropertyDocker, YsInfiniteScrollView, YsButton, SheetContainer},
  props: {
    sheetList: Array,
    visible: Boolean,
  },
  data() {
    return {
      isVisible: false,

      innerSheetList: [],

      /**
       * 属性面板
       */
      propertyDocker: null,

      /**
       * 多选
       */
      multiChoice: false,

      /**
       * 保存历史状态，方便撤销和重做
       */
      history: [],
      historyIndex: 0,

    }
  },
  watch: {
    visible: function (visible) {
      this.isVisible = visible;
      if (visible) {
        this.history = [Utils.deepCopy(this.sheetList)];
        this.historyIndex = 0;
        this.innerSheetList = Utils.deepCopy(this.sheetList);
        if (!this.innerSheetList?.length) {
          let sheet = SheetUtils.defaultSheet();
          sheet.name = '未命名表单'
          sheet.pages.push(SheetUtils.defaultSheetPage());
          this.innerSheetList = [sheet];
        }
        this.$nextTick(() => {
          this.propertyDocker = this.$refs.propertyDocker;
        });
      }
    }
  },
  deactivated() {
    // 退出页面时，也强制关闭弹窗
    if(this.isVisible) {
      this.clickCancel();
    }
  },
  methods: {
    clickCancel: function () {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.history = [];
      this.historyIndex = 0;
    },
    clickConfirm: function () {
      setTimeout(() => {
        for (let sheet of this.innerSheetList) {
          let errorKey = this.checkSheet(sheet);
          if (errorKey) {
            this.$refs.sheetContainer.setSelectionByKey(errorKey);
            return;
          }
        }
        this.isVisible = false;
        this.$emit('update:visible', false);
        this.history = [];
        this.historyIndex = 0;
        this.$emit('change', Utils.deepCopy(this.innerSheetList));
      }, 500);

    },
    handleAddSheetPageClick() {
      this.$refs.sheetContainer.addSheetPage();
    },
    handleAddSheetItemClick() {
      this.$refs.sheetContainer.addSheetItem();
    },
    handleCopyClick() {
      if (this.disableCopy) return;
      this.$refs.sheetContainer.copySelected();
    },
    handleDeleteClick() {
      if (this.disableDelete) return;
      this.$refs.sheetContainer.deleteSelected();
    },
    handleMultiChoiceClick() {
      if (this.disableMultiChoice) return;
      this.multiChoice = !this.multiChoice;
    },
    handleUndoClick: function () {
      if (this.historyIndex > 0) {
        this.innerSheetList = Utils.deepCopy(this.history[this.historyIndex - 1]);
        this.historyIndex--;
      }
    },
    handleRedoClick: function () {
      if (this.historyIndex < this.history.length - 1) {
        this.innerSheetList = Utils.deepCopy(this.history[this.historyIndex + 1]);
        this.historyIndex++;
      }
    },
    handleSheetInput(sheetList) {
      if (this.historyIndex < this.history.length - 1) {
        this.history.splice(this.historyIndex + 1, this.history.length - this.historyIndex);
      }
      this.history.push(Utils.deepCopy(sheetList));
      this.historyIndex++;
      if (this.history.length > 20) {
        this.history.splice(0, 1);
        this.historyIndex--;
      }
      this.innerSheetList = sheetList;
    },
    handleMouseEventStop: function() {
      console.log('mouse move.....');
    }
  }
}
</script>

<style scoped>

.dialog >>> .el-dialog__header {
  visibility: hidden;
  height: 0px;
  margin: 0px;
  padding: 0;
}

.dialog >>> .el-dialog__title {
  height: 0px;
}

.dialog >>> .el-dialog__body {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dadada;
}

.footer {
  box-shadow: 0 0 8px #dadada;
  width: 100%;
  height: 56px;
  position: relative;
  z-index: 999;
  background-color: white;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  user-select: none;
  height: 56px;
}

.btn.disabled {
  color: #bec0c0;
  background-color: #f1f1f1;
  pointer-events: none;
}

.btn:hover {
  background-color: #f1f1f1;
}

.property-panel {
  height: calc(100vh - 56px);
  background-color: white;
  box-shadow: 0 0 8px #dadada;
  padding: 16px;
  box-sizing: border-box;
}

.background {
  position: static;
  width: 100vw;
  height: 100vh;
  padding: 0px;
  background-color: #ff5500;
}

</style>