<template>
  <div class="sheet-item sheet-item__datetime" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} 请选择日期和时间</div>
    <div class="title layout-horizontal layout-middle padding-left-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%"
            type="textarea"
            @blur="handleTitleBlur"
        ></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-inflexible layout-horizontal layout-middle" v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <ys-date-picker
              type="datetime"
              :date="dateTimeStr"
              :disabled="mode === ViewMode.MODE_EDIT"
              :picker-options="{disabledDate: disabledDate, selectableRange: selectableRange}"
              @pick="handleDateTimePicked"
          >
            <div slot="reference"
                 class="datetime-picked layout-horizontal layout-middle layout-right padding-right-large"
                 :class="{'is-disabled': mode === ViewMode.MODE_EDIT}">
              <span v-if="dateTimeStr" class="font-color-normal">{{ dateTimeStr }}</span>
              <span v-else class="font-color-placeholder">请选择日期和时间</span>
              <i class="fa fa-angle-right margin-left-small"></i>
            </div>
          </ys-date-picker>
        </template>
        <template v-else>
          <div v-if="dateTimeStr" class="padding-right-large">{{ dateTimeStr }}</div>
          <div v-else class="font-color-placeholder padding-right-large">未选择</div>
        </template>
      </div>
    </div>
    <div v-if="isBigGap" class="padding-bottom-large">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <ys-date-picker
            type="datetime"
            :date="dateTimeStr"
            :disabled="mode === ViewMode.MODE_EDIT"
            :picker-options="{disabledDate: disabledDate, selectableRange: selectableRange}"
            @pick="handleDateTimePicked"
        >
          <div slot="reference" class="datetime-picked layout-horizontal layout-middle layout-right"
               :class="{'is-disabled': mode === ViewMode.MODE_EDIT}">
            <span v-if="dateTimeStr" class="font-color-normal">{{ dateTimeStr }}</span>
            <span v-else class="font-color-placeholder">请选择日期和时间</span>
            <i class="fa fa-angle-right margin-left-small"></i>
          </div>
        </ys-date-picker>
      </template>
      <template v-else>
        <div v-if="dateTimeStr" class="padding-right-large">{{ dateTimeStr }}</div>
        <div v-else class="font-color-placeholder padding-right-large">未选择</div>
      </template>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import {TimeUtils} from "@/assets/javascript/time-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsDatePicker from "@/components/wedigets/YsDatePicker";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemDateTimePropertyPanel from "@/components/sheet/panel/SheetItemDateTimePropertyPanel";
import DateTimeLimit from "@/assets/javascript/datetime-limit";

export default {
  name: "SheetItemDateTime",
  mixins: [SheetItemBasic],
  components: {YsDatePicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      ViewMode,
      SheetUtils,
      TimeUtils,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  computed: {
    dateTimeStr() {
      let dateTime = this.sheetItemRecord?.dateTime;
      if (dateTime) {
        return TimeUtils.format('yyyy-MM-dd HH:mm', dateTime);
      } else {
        return null;
      }
    },
    selectableRange() {
      if (this.sheetItemRecord && this.sheetItemRecord.dateTime == null) return null;
      if (!TimeUtils.isToday(this.sheetItemRecord?.dateTime)) return "00:00:00-23:59:59";
      let today = new Date();
      switch (this.sheetItem.limit) {
        case DateTimeLimit.PASSED:
          return `00:00:00-${TimeUtils.format('HH:mm:00', today)}`
        case DateTimeLimit.FUTURE:
          return `${TimeUtils.format('HH:mm:00', today)}-23:59:59`;
      }
      return null;
    },
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if (this.mode === ViewMode.MODE_VIEW && !this.readOnly) {
          if (!this.sheetItemRecord.dateTime && this.sheetItem.defaultNow) {
            this.sheetItemRecord.dateTime = new Date().getTime();
            this.$emit('input', this.item);
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 80;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemDateTimePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleDateTimePicked(dateTime) {
      this.sheetItemRecord.dateTime = TimeUtils.parseDate(dateTime).getTime();
      this.$emit('input', this.item);
    },
    disabledDate(date) {
      switch (this.sheetItem.limit) {
        case DateTimeLimit.PASSED: {
          date = new Date(date);
          let today = new Date();
          if (date.getFullYear() > today.getFullYear()) return true;
          if (date.getFullYear() < today.getFullYear()) return false;
          if (date.getMonth() > today.getMonth()) return true;
          if (date.getMonth() < today.getMonth()) return false;
          if (date.getDate() > today.getDate()) return true;
          return false;
        }
        case DateTimeLimit.FUTURE: {
          date = new Date(date);
          let today = new Date();
          if (date.getFullYear() > today.getFullYear()) return false;
          if (date.getFullYear() < today.getFullYear()) return true;
          if (date.getMonth() > today.getMonth()) return false;
          if (date.getMonth() < today.getMonth()) return true;
          if (date.getDate() < today.getDate()) return true;
          return false;
        }
      }
      return false;
    },

  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.datetime-picked {
  height: 40px;
  cursor: pointer;
}

.datetime-picked.is-disabled {
  cursor: not-allowed;
}

.datetime-picked:hover {
  background-color: #f1f1f1;
}

.text-measurer {
  position: absolute;
  height: 0px;
  overflow: hidden;
}

</style>