const defaultAddress = (province, city, district, detail) => {
    return {
        country: '中国',
        province: province,
        city: city,
        district: district,
        detail: detail,
    }
}



const AddressUtils = {
    ADDRESS_DETAIL_MAX_LENGTH: 200,
    defaultAddress: defaultAddress,
}

export default AddressUtils;