<template>
  <div class="sheet-item sheet-item__date" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }}</div>
    <div class="title layout-horizontal layout-middle padding-left-large">
      <span v-if="!sheetItem.optional" class="font-color-danger layout-inflexible">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit v-if="mode === ViewMode.MODE_EDIT" v-model="title" placeholder="双击此处添加标题"
                          :maxlength="SheetUtils.TITLE_MAX_LENGTH" type="textarea" @blur="handleTitleBlur"
                          style="width: 100%"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <template v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <ys-date-picker
              class="layout-inflexible layout-self-stretch"
              :date="sheetItemRecord ? sheetItemRecord.date : null"
              :disabled="mode === ViewMode.MODE_EDIT"
              :picker-options="{ disabledDate: disabledDate}"
              @pick="handleDatePicked">
            <div slot="reference"
                 class="date date-singleline layout-horizontal layout-middle layout-right font-color-placeholder padding-horizontal-large">
              <span v-if="sheetItemRecord && sheetItemRecord.date"
                    class="font-color-normal">{{ sheetItemRecord.date }}</span>
              <span style="margin-right: 5px;" v-else>请选择日期</span>
              <i class="fa fa-angle-right margin-left-small"></i>
            </div>
          </ys-date-picker>
        </template>
        <template v-else>
          <div class="padding-right-large" v-if="sheetItemRecord.date">{{ sheetItemRecord.date }}</div>
          <div class="padding-right-large font-color-placeholder" v-else>未选择</div>
        </template>
      </template>
    </div>
    <div v-if="isBigGap" class="padding-bottom-large">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <ys-date-picker
            class="layout-inflexible layout-self-stretch padding-horizontal-large"
            :date="sheetItemRecord ? sheetItemRecord.date : null"
            :disabled="mode === ViewMode.MODE_EDIT"
            :picker-options="{ disabledDate: disabledDate}"
            @pick="handleDatePicked"
        >
          <div slot="reference" class="date layout-horizontal layout-middle layout-right font-color-placeholder">
            <span v-if="sheetItemRecord && sheetItemRecord.date"
                  class="font-color-normal">{{ sheetItemRecord.date }}</span>
            <span v-else>请选择日期</span>
            <i class="fa fa-angle-right margin-left-small"></i>
          </div>
        </ys-date-picker>
      </template>
      <template v-else>
        <div class="padding-right-large" v-if="sheetItemRecord.date">{{ sheetItemRecord.date }}</div>
        <div class="padding-right-large font-color-placeholder" v-else>未选择</div>
      </template>
    </div>
  </div>
</template>

<script>
import {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsDatePicker from "@/components/wedigets/YsDatePicker";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemDatePropertyPanel from "@/components/sheet/panel/SheetItemDatePropertyPanel";
import {TimeUtils} from "@/assets/javascript/time-utils";
import DateTimeLimit from "@/assets/javascript/datetime-limit";

export default {
  name: "SheetItemDate",
  mixins: [SheetItemBasic],
  components: {YsDatePicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      SheetUtils,
      ViewMode,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if (this.mode === ViewMode.MODE_VIEW && !this.readOnly) {
          if (this.sheetItem && this.sheetItemRecord) {
            if (!this.sheetItemRecord.date && this.sheetItem.defaultNow) {
              this.sheetItemRecord.date = TimeUtils.format('yyyy-MM-dd', new Date());
              this.$emit('input', this.item);
            }
          }
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 140;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemDatePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleDatePicked(date) {
      this.sheetItemRecord.date = TimeUtils.format('yyyy-MM-dd', date);
      this.$emit('input', this.item);
    },
    disabledDate(date) {
      switch (this.sheetItem.limit) {
        case DateTimeLimit.NO_LIMIT:
          return false;
        case DateTimeLimit.PASSED: {
          date = new Date(date);
          let today = new Date();
          if (date.getFullYear() > today.getFullYear()) return true;
          if (date.getFullYear() < today.getFullYear()) return false;
          if (date.getMonth() > today.getMonth()) return true;
          if (date.getMonth() < today.getMonth()) return false;
          if (date.getDate() > today.getDate()) return true;
          return false;
        }
        case DateTimeLimit.FUTURE: {
          date = new Date(date);
          let today = new Date();
          if (date.getFullYear() < today.getFullYear()) return true;
          if (date.getFullYear() > today.getFullYear()) return false;
          if (date.getMonth() < today.getMonth()) return true;
          if (date.getMonth() > today.getMonth()) return false;
          if (date.getDate() < today.getDate()) return true;
          return false;
        }
      }
      return false;
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.date {
  min-height: 40px;
  text-align: right;
}

.date.date-singleline {
  height: 40px;
}

.date:hover {
  background-color: #f1f1f1;
}

.text-measurer {
  height: 0px;
  overflow: hidden;
  position: absolute;
}

</style>