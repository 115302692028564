<template>
    <span>
        <i class="fa fa-file-pdf"
           v-if="fileType == FileTypes.PDF"></i>
        <i class="fa fa-file-word"
           v-else-if="fileType == FileTypes.WORD"></i>
        <i class="fa fa-file-excel"
           v-else-if="fileType == FileTypes.EXCEL"></i>
        <i class="fa fa-file-powerpoint"
           v-else-if="fileType == FileTypes.POWERPOINT"></i>
        <i class="fa fa-file-alt"
           v-else-if="fileType == FileTypes.TEXT"></i>
        <el-image
            style="width: 16px; height: 16px; display: block; border: 1px solid #686868; background-color: #686868;"
            fit="cover"
            :src="$prepareImageUrl(file.url)"
            v-else-if="fileType == FileTypes.IMAGE"></el-image>
        <i class="fa fa-file" v-else></i>
    </span>
</template>

<script>
import FileResourceUtils, {FileTypes} from "@/assets/javascript/file-resource-utils";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "YsFileIcon",
  mixins: [httpapi],
  props: {
    file: {
      type: Object,
    }
  },
  data() {
    return {
      FileTypes: FileTypes,
      fileType: FileTypes.UNKNOWN,
    }
  },
  watch: {
    file: {
      handler() {
        this.fileType = FileResourceUtils.fileTypeOf(this.file?.name);
      },
      immediate: true,
    }
  }
}
</script>

<style scoped>


</style>