var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheet-item sheet-item__gender",class:{'is-selected': _vm.selected}},[_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleTitleResize),expression:"handleTitleResize"}],ref:"titleMeasurer",staticClass:"title-measurer"},[_vm._v(_vm._s(_vm.sheetItem.title))]),_c('div',{staticClass:"title layout-horizontal padding-left-large layout-middle"},[(!_vm.sheetItem.optional)?_c('span',{staticClass:"font-color-danger layout-inflexible"},[_vm._v("*")]):_vm._e(),_c('div',{staticClass:"layout-flexible"},[(_vm.mode === _vm.ViewMode.MODE_EDIT)?_c('ys-click-to-edit',{staticStyle:{"width":"100%"},attrs:{"placeholder":"双击此处添加标题","maxlength":_vm.SheetUtils.TITLE_MAX_LENGTH,"type":"textarea"},on:{"blur":_vm.handleTitleBlur},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}):_c('span',[_vm._v(_vm._s(_vm.title))])],1),(!_vm.isBigGap)?_c('div',{staticClass:"layout-flexible layout-horizontal layout-middle layout-right layout-self-stretch font-color-secondary"},[(_vm.mode === _vm.ViewMode.MODE_EDIT || !_vm.readOnly)?[_c('div',{staticClass:"gender gender-male layout-self-stretch layout-horizontal layout-middle padding-horizontal-large",class:{
                'is-active': _vm.sheetItemRecord && _vm.sheetItemRecord.gender === _vm.Gender.MALE
            },on:{"click":function($event){return _vm.handleGenderClick(_vm.Gender.MALE)}}},[_vm._m(0),_c('span',[_vm._v("男")])]),_c('div',{staticClass:"gender gender-female layout-self-stretch layout-horizontal layout-middle padding-horizontal-large",class:{
                'is-active': _vm.sheetItemRecord && _vm.sheetItemRecord.gender === _vm.Gender.FEMALE,
            },on:{"click":function($event){return _vm.handleGenderClick(_vm.Gender.FEMALE)}}},[_vm._m(1),_c('span',[_vm._v("女")])])]:[(_vm.sheetItemRecord.gender === _vm.Gender.MALE)?_c('div',{staticClass:"padding-right-large font-color-normal"},[_vm._m(2)]):(_vm.sheetItemRecord.gender === _vm.Gender.FEMALE)?_c('div',{staticClass:"padding-right-large font-color-normal"},[_vm._m(3)]):_c('div',{staticClass:"font-color-placeholder padding-right-large"},[_vm._v("未选择")])]],2):_vm._e()]),(_vm.isBigGap)?_c('div',{staticClass:"layout-horizontal layout-right font-color-secondary"},[(_vm.mode === _vm.ViewMode.MODE_EDIT || !_vm.readOnly)?[_c('div',{staticClass:"gender gender-male layout-self-stretch layout-horizontal layout-middle padding-horizontal-large",class:{
                'is-active': _vm.sheetItemRecord && _vm.sheetItemRecord.gender === _vm.Gender.MALE
            },on:{"click":function($event){return _vm.handleGenderClick(_vm.Gender.MALE)}}},[_vm._m(4),_c('span',[_vm._v("男")])]),_c('div',{staticClass:"gender gender-female layout-self-stretch layout-horizontal layout-middle padding-horizontal-large",class:{
                'is-active': _vm.sheetItemRecord && _vm.sheetItemRecord.gender === _vm.Gender.FEMALE,
            },on:{"click":function($event){return _vm.handleGenderClick(_vm.Gender.FEMALE)}}},[_vm._m(5),_c('span',[_vm._v("女")])])]:[(_vm.sheetItemRecord.gender === _vm.Gender.MALE)?_c('div',{staticClass:"padding-right-large font-color-normal"},[_vm._m(6)]):(_vm.sheetItemRecord.gender === _vm.Gender.FEMALE)?_c('div',{staticClass:"padding-right-large font-color-normal"},[_vm._m(7)]):_c('div',{staticClass:"font-color-placeholder padding-right-large"},[_vm._v("未选择")])]],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gender-icon-wrap"},[_c('i',{staticClass:"fas fa-mars"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gender-icon-wrap"},[_c('i',{staticClass:"fas fa-venus"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-mars font-color-primary"}),_vm._v(" 男")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-venus font-color-danger"}),_vm._v(" 女")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gender-icon-wrap"},[_c('i',{staticClass:"fas fa-mars"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gender-icon-wrap"},[_c('i',{staticClass:"fas fa-venus"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-mars font-color-primary"}),_vm._v(" 男")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-venus font-color-danger"}),_vm._v(" 女")])}]

export { render, staticRenderFns }