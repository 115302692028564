<template>
  <div class="sheet-item-property__address padding" v-if="sheetItem">
    <div class="font-size-small"><span class="font-color-primary font-weight-bold"><span class="fas fa-list-ul"></span> 地址</span>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible">占位文字：</span>
      <ys-textarea v-model="sheetItem.detailPlaceholder" placeholder="输入占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal">
      <span>要求输入详细地址：</span>
      <ys-switch v-model="sheetItem.withDetail" @input="handleInput"></ys-switch>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemAddressPropertyPanel",
  components: {SheetItemUserDataBinder, YsSwitch, YsTextarea},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    }
  }
}
</script>

<style scoped>

</style>