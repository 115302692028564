<template>
  <div class="ys-switch__wrapper"
       :class="[switchState ? 'switch-on' : 'switch-off',
       {'is-disabled' : disabled}]"
       @click="handleClick">
    <div class="ys-switch">
      <div class="ys-switch__button" :class="[switchState ? 'switch-on' : 'switch-off', {'is-animation' : showAnimation}]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      switchState: false,
      showAnimation: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.switchState = this.value;
      },
      immediate: true,
    }
  },
  methods: {
    handleClick() {
      if(this.disabled) return;
      this.showAnimation = true;
      this.switchState = !this.switchState;
      this.$emit('input', this.switchState);
    }
  }
}
</script>

<style scoped>

.ys-switch__wrapper {
  background-color: #f1f1f1;
  box-shadow: 0 0 2px #bec0c0 inset;
  width: 48px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
}

.ys-switch__button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #bec0c0;
  box-shadow: 0 0 4px #bec0c0;
  position: relative;
}

.ys-switch__wrapper:hover {
  box-shadow: 0 0 4px #3a8ee6 inset;
  background-color: #d7edff;
}

@keyframes switch-on {
  from {
    left: 0;
    background-color: #bec0c0;
  }
  to {
    left: 24px;
    background-color: #3a8ee6;
  }
}

@keyframes switch-off {
  from {
    left: 24px;
    background-color: #3a8ee6;
  }
  to {
    left: 0px;
    background-color: #bec0c0;
  }
}

@keyframes switch-on-background {
  from {
    background-color: #f1f1f1;
  }
  to {
    background-color: #d7edff;
  }
}

@keyframes switch-off-background {
  from {
    background-color: #d7edff;
  }
  to {
    background-color: #f1f1f1;
  }
}

.ys-switch__button.switch-on.is-animation {
  animation-name: switch-on;
  animation-duration: .5s;
}

.ys-switch__button.switch-on {
  left: 24px;
  background-color: #3a8ee6;
}

.ys-switch__button.switch-off.is-animation {
  animation-name: switch-off;
  animation-duration: .5s;
}

.ys-switch__button.switch-off {
  left: 0px;
  background-color: #bec0c0;
}

.ys-switch__wrapper.switch-on {
  animation-name: switch-on-background;
  animation-duration: .5s;
  background-color: #d7edff;
}

.ys-switch__wrapper.switch-off {
  animation-name: switch-off-background;
  animation-duration: .5s;
  background-color: #f1f1f1;
}

.ys-switch__wrapper.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>