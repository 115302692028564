<template>
  <div class="sheet-item sheet-item__image" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="title layout-horizontal layout-middle padding-horizontal-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%"
            @blur="handleTitleBlur"
        ></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
    </div>
    <div class="padding-horizontal-large padding-bottom-large">
      <div class="layout-horizontal layout-wrap">
        <template v-if="mode === ViewMode.MODE_EDIT">
          <div class="image-thumbnail-container layout-vertical layout-center layout-middle"
               v-for="(image, index) in simulatedImageList" :key="image.id"
               v-show="index < 3">
            <div v-if="sheetItem.maxCount > 3 && index === 2">{{ sheetItem.maxCount - 2 }}+</div>
            <i class="fa fa-camera" v-else></i>
          </div>
        </template>
        <template v-else-if="!readOnly">
          <ys-image-picker
              v-for="(image, index) in sheetItemRecord.images"
              :key="`${image.id}#${index}`"
              :image.sync="sheetItemRecord.images[index]"
              @update:image="handleImageUploaded"
              closable
              @delete="handleImageDeleteClick(index)"
              class="margin-right-small"
          >
          </ys-image-picker>
          <ys-image-picker
              v-show="(sheetItemRecord.images.length < sheetItem.maxCount)"
              only-pick
              @update:image="handleImagePicked"
          >
            <div class="image-thumbnail-container layout-vertical layout-center layout-middle" style="margin: 0">
              <i class="fas fa-camera"></i>
            </div>
          </ys-image-picker>
        </template>
        <template v-else>
          <div v-for="(image, index) in sheetItemRecord.images" :key="`${image.id}#${index}`"
               class="image-thumbnail-container"
               @click="handleImageClick(index)">
            <el-image class="image-thumbnail" fit="cover" :src="$prepareImageUrl(image)"></el-image>
          </div>
          <div class="empty font-size-medium font-color-placeholder" v-if="sheetItemRecord.images.length == 0">未上传</div>
        </template>
      </div>
    </div>
    <ys-image-preview :visible.sync="imagePreviewVisible" :image-list="sheetItemRecord ? sheetItemRecord.images : []"
                      :current-image-index="currentImageIndex"></ys-image-preview>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import GuidUtils from "@/assets/javascript/guid-utils";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemImagePropertyPanel from "@/components/sheet/panel/SheetItemImagePropertyPanel";
import httpapi from "@/assets/javascript/httpapi";
import YsImagePreview from "@/components/wedigets/YsImagePreviewer";
import YsImagePicker from "@/components/wedigets/YsImagePicker";
import ImageUtils from "@/assets/javascript/image-utils";

export default {
  name: "SheetItemImage",
  mixins: [SheetItemBasic, httpapi],
  components: {YsImagePicker, YsImagePreview, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      ViewMode,
      SheetUtils,
      isBigGap: true,
      propertyPanel: null,
      currentImageIndex: 0,
      imagePreviewVisible: false,
    }
  },
  computed: {
    simulatedImageList() {
      let result = new Array();
      for (let n = 0; n < this.sheetItem.maxCount; n++) {
        result.push({
          id: GuidUtils.guid()
        })
      }
      return result;
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.$emit('update:is-big-gap', true);
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleImageClick(index) {
      this.currentImageIndex = index;
      this.imagePreviewVisible = true;
    },
    handleImagePicked(image) {
      if (this.sheetItemRecord.images.length >= this.sheetItem.maxCount) {
        this.$message.error(`最多上传${this.sheetItem.maxCount}张图片`)
        return;
      }
      this.sheetItemRecord.images.push(image);
      this.$emit('input', this.item);
    },
    handleImageUploaded() {
      this.$emit('input', this.item);
    },
    handleImageDeleteClick(index) {
      this.sheetItemRecord.images.splice(index, 1);
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemImagePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.image-thumbnail-container {
  width: 88px;
  height: 88px;
  background-color: #f091a6;
  border-radius: 4px;
  margin-left: 5px;
  color: #f9a7a7;
  font-size: 32px;
  box-shadow: 0 0 4px #f091a6;
  cursor: pointer;
}

.image-thumbnail-container:first-child {
  margin-left: 0px;
}

.image-thumbnail {
  width: 88px;
  height: 88px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.empty {
  padding: 8px 16px;
  border-radius: 100px;
  background-color: #f1f1f1;
}

.image-upload >>> .el-upload {
  width: 88px;
  height: 88px;
}

.image-upload__container .mask-container {
  height: 0px;
}

.image-upload__container .mask-container .image-mask {
  width: 88px;
  height: 88px;
  position: relative;
  top: -88px;
  cursor: pointer;
  background-color: #f091a6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px !important;
  pointer-events: none;
}

</style>