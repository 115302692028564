<template>
  <div class="sheet-item-property__datetime padding" v-if="sheetItem">
    <div><span class="font-color-primary font-weight-bold"><i
        class="fa fa-list-ul"></i> 日期+时间选择</span></div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal">
      <span>默认显示当前时间：</span>
      <ys-switch v-model="sheetItem.defaultNow" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top">
      <div>时间选择范围：</div>
      <div class="datetime-range-item margin-top" :class="{'is-active': sheetItem.limit === DateTimeLimit.NO_LIMIT}"
           @click="handleDateTimeRangeClick(DateTimeLimit.NO_LIMIT)">全部时间
      </div>
      <div class="datetime-range-item margin-top" :class="{'is-active': sheetItem.limit === DateTimeLimit.PASSED}"
           @click="handleDateTimeRangeClick(DateTimeLimit.PASSED)">过去时间
      </div>
      <div class="datetime-range-item margin-top" :class="{'is-active': sheetItem.limit === DateTimeLimit.FUTURE}"
           @click="handleDateTimeRangeClick(DateTimeLimit.FUTURE)">将来时间
      </div>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import DateTimeLimit from "@/assets/javascript/datetime-limit";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemDateTimePropertyPanel",
  components: {SheetItemUserDataBinder, YsSwitch, YsTextarea},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
      DateTimeLimit,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    },
    handleDateTimeRangeClick(limit) {
      this.sheetItem.limit = limit;
      this.handleInput();
    }
  }
}
</script>

<style scoped>

.datetime-range-item {
  height: 38px;
  width: 240px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}

.datetime-range-item:hover {
  box-shadow: 0 0 4px #f091a6;
}

.datetime-range-item.is-active {
  background-color: #f091a6;
  color: white;
  box-shadow: 0 0 4px #f091a6;
}


</style>