<template>
  <div
      class="ys-file-picker layout-horizontal"
      :class="{
        'is-delete-enable': isDeleteEnable && innerFile
      }"
      @click="handleClick"
      @mousemove="handleMouseMove"
  >
    <div style="width: 100%" class="file-picker-content layout-horizontal">
      <template v-if="innerFile">
        <div class="file-icon">
          <el-image
              v-if="isImage"
              class="image-thumbnail"
              fit="cover"
              :src="$prepareImageUrl(innerFile)"
          ></el-image>
          <ys-file-icon
              v-else
              :file="innerFile"
          ></ys-file-icon>
        </div>
        <div class="margin-left layout-flexible" style="overflow: hidden">
          <div class="font-size-medium font-nowrap">{{ innerFile.name }}</div>
          <div>{{ fileSize }}</div>
          <ys-progressbar v-if="isUploading" :progress="progress"></ys-progressbar>
        </div>
      </template>
      <template v-else>
        <div class="file-icon">
          <i class="fa fa-file-upload"></i>
        </div>
        <div class="margin-left layout-flexible" style="overflow: hidden">
          <div>上传文件</div>
        </div>
      </template>
      <div class="btn-delete__container layout-self-stretch">
        <div class="btn-delete" @click.stop="handleDeleteClick">
          <i class="fas fa-trash-alt"></i>
        </div>
      </div>
    </div>
    <el-upload
        class="upload"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :multiple="false"
        :on-change="handleFilePick"
        :disabled="disabled"
        :accept="accept">
      <div slot="trigger" ref="trigger"></div>
    </el-upload>
  </div>
</template>

<script>
import YsFileIcon from "@/components/wedigets/YsFileIcon";
import FileResourceUtils, {FileTypes} from "@/assets/javascript/file-resource-utils";
import httpapi from "@/assets/javascript/httpapi";
import ExactNumber from "@/assets/javascript/exact-number";
import YsProgressbar from "@/components/wedigets/YsProgressbar";

export default {
  name: "YsFilePicker",
  mixins: [httpapi],
  components: {YsProgressbar, YsFileIcon},
  props: {
    file: Object,
    onlyPick: Boolean,
    accept: {
      type: String,
      default: '.doc,docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.jpg,.jpeg,.png,.gif,.bmp,.DOC,.DOCX,.XLS,.XLSX,.PPT,.PPTX,.PDF,.TXT,.JPG,.JPEG,.PBG,.GIF,.BMP',
    }
  },
  data() {
    return {
      FileResourceUtils,
      FileTypes,

      innerFile: null,
      isUploading: false,
      progress: 0,

      isDeleteEnable: false,
    }
  },
  computed: {
    isImage() {
      if (this.innerFile) {
        return FileResourceUtils.fileTypeOf(this.innerFile.name) === FileTypes.IMAGE;
      }
      return false;
    },
    fileSize() {
      return FileResourceUtils.stringifyFileLength(this.innerFile);
    }
  },
  watch: {
    file: {
      handler() {
        this.isDeleteEnable = false;
        this.innerFile = this.file;
        this.startUpload();
      },
      immediate: true,
    }
  },
  methods: {
    startUpload() {
      if (this.isUploading) return;
      let file = this.innerFile;
      if (!file?.__localFile) return;
      this.isUploading = true;

      this.response = this.$uploadFile({
        file: file.__localFile,
      }).then(res => {
        this.innerFile = res.data;
        this.$emit('update:file', this.innerFile);
        this.$emit('finished');
        this.isUploadFailed = false;
      }).progress(progress => {
        this.progress = progress;
      }).catch(() => {
        this.isUploadFailed = true;
      }).complete(() => {
        this.isUploading = false;
      });
    },
    handleFilePick(file) {
      let innerFile = FileResourceUtils.defaultLocalFile(file);
      this.$emit('update:file', innerFile);


      if (this.onlyPick) return;
      this.innerFile = innerFile;
      this.startUpload();
    },
    handleClick() {
      this.$refs.trigger.click();
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleMouseMove() {
      this.isDeleteEnable = true;
    }
  }
}
</script>

<style scoped>

.ys-file-picker {
  overflow: hidden;
  border-radius: 4px;
}

.ys-file-picker:hover {
  background-color: #f1f1f1;
}

.upload >>> .el-upload {
  width: 0px;
  height: 0px;
}

.file-icon {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f091a6;
  color: white;
  font-size: 18px;
  border-radius: 4px;
  overflow: hidden;
}

.image-thumbnail {
  width: 44px;
  height: 44px;
}

.ys-file-picker {
  cursor: pointer;
}

.btn-delete__container {
  width: 0px;
  max-width: 0px;
}

.btn-delete {
  width: 50px;
  height: 100%;
  background-color: #ff5500;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-picker-content {
  position: relative;
}


@keyframes slide-to-left {
  from {
    left: 0;
  }
  to {
    left: -50px;
  }
}

@keyframes slide-to-right {
  from {
    left: -50px;
  }
  to {
    left: 0px;
  }
}

.ys-file-picker.is-delete-enable .file-picker-content {
  animation-name: slide-to-right;
  animation-duration: .2s;
  animation-fill-mode: both;
}

.ys-file-picker.is-delete-enable:hover .file-picker-content {
  animation-name: slide-to-left;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-delay: .5s;
}

</style>