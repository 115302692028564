<template>
  <div class="contents">
    <ys-movable v-if="movable" :offset-x="offsetY" :offset-y="offsetY" style="height: 100%;"
                :disabled="isMultiChoice" @move="handleMove">
      <el-scrollbar ref="scrollbar" class="scrollbar" style="height: 100%;">
        <div class="layout-vertical layout-right">
          <slot></slot>
        </div>
      </el-scrollbar>
    </ys-movable>
    <el-scrollbar ref="scrollbar" class="scrollbar" style="height: 100%" v-else>
      <div class="layout-vertical layout-right">
        <slot></slot>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import YsMovable from "@/components/wedigets/YsMovable";

export default {
  name: "Contents",
  components: {YsMovable},
  provide() {
    return {
      provideData: this.provideData,
    }
  },
  props: {
    movable: {
      type: Boolean,
      default: true,
    },
    multiChoice: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      provideData: {
        level: 1,
        multiChoice: false,
      },
      mouseDown: false,
      movingDirect: 'none',
      movingIntervalId: null,
      dispatchMouseEvent: false,
      offsetX: 0,
      offsetY: 0,
      maxScrollTop: 0,
      requireRefresh: false,
      childrenItems: [],
      isMultiChoice: false,
      choiceChildrenItems: [],
    }
  },
  watch: {
    multiChoice: {
      handler() {
        this.isMultiChoice = this.multiChoice;
        this.provideData.multiChoice = this.multiChoice;
      }
    },
    isMultiChoice: {
      handler() {
        if (!this.isMultiChoice && this.choiceChildrenItems.length > 1) {
          for (let n = 1; n < this.choiceChildrenItems.length; n++) {
            this.choiceChildrenItems[n].isChoice = false;
          }
          this.choiceChildrenItems = [this.choiceChildrenItems[0]];
        }
      }
    }
  },
  methods: {
    isContents() {
      return true;
    },
    handleMouseDown(evt) {
      let rect = this.$el.getBoundingClientRect();
      if (evt.x < rect.left || evt.x > rect.right || evt.y < rect.top || evt.y > rect.bottom) return;
      this.mouseDown = true;
    },
    handleMouseUp() {
      this.mouseDown = false;
      if (this.movingIntervalId) clearInterval(this.movingIntervalId);
    },
    handleMouseMove(evt) {
      if (!this.mouseDown) return;
      if (this.dispatchMouseEvent) return;
      if (this.movingIntervalId) clearInterval(this.movingIntervalId);

      let rect = this.$el.getBoundingClientRect();
      this.maxScrollTop = this.$refs.scrollbar.wrap.scrollHeight - rect.height;

      if (evt.y < rect.top + 20) {
        this.movingIntervalId = setInterval(() => {
          let scrollTop = this.$refs.scrollbar.wrap.scrollTop;
          if (scrollTop < 0) scrollTop = 0;
          this.$refs.scrollbar.wrap.scrollTop = scrollTop - 2;
          if (this.$refs.scrollbar.wrap.scrollTop == 0) {
            clearInterval(this.movingIntervalId);
          } else {
            this.dispatchMouseEvent = true;
            document.dispatchEvent(evt);
            this.$nextTick(() => this.dispatchMouseEvent = false);
          }
        }, 8);
      } else if (evt.y > rect.bottom - 124) {
        this.movingIntervalId = setInterval(() => {
          let scrollTop = this.$refs.scrollbar.wrap.scrollTop;
          this.$refs.scrollbar.wrap.scrollTop = scrollTop + 2;
          if (this.$refs.scrollbar.wrap.scrollTop >= this.maxScrollTop) {
            clearInterval(this.movingIntervalId);
          } else {
            this.dispatchMouseEvent = true;
            document.dispatchEvent(evt);
            this.$nextTick(() => this.dispatchMouseEvent = false);
          }
        }, 8);
      }
    },
    handleTitleInput(evt) {
      this.$emit('input', evt);
    },
    doInitMouseEvents() {
      document.addEventListener('mousedown', this.handleMouseDown);
      document.addEventListener('mouseup', this.handleMouseUp);
      document.addEventListener('mousemove', this.handleMouseMove);
    },
    doReleaseMouseEvents() {
      this.mouseDown = false;
      if (this.movingIntervalId) clearInterval(this.movingIntervalId);
      document.removeEventListener('mousedown', this.handleMouseDown);
      document.removeEventListener('mouseup', this.handleMouseUp);
      document.removeEventListener('mousemove', this.handleMouseMove);
    },
    refreshChildren() {
      this.requireRefresh = true;
      this.$nextTick(() => {
        if (this.requireRefresh) {
          this.requireRefresh = false;
          this.childrenItems = this.doRefreshChildrens(this);
          for(let childrenItem of this.childrenItems) {
            childrenItem.$on('input', this.handleTitleInput);
          }
        }
      })
    },
    doRefreshChildrens(view) {
      let childrenItems = new Array();
      for (let children of view.$children) {
        childrenItems = childrenItems.concat(this.doRefreshChildrens(children));
        if (children.isContentsItem && children.isContentsItem(this)) {
          childrenItems.push(children);
        }
      }
      return childrenItems;
    },
    pickUp(level) {
      for (let children of this.childrenItems) {
        if (children.level === level) {
          children.showChildrenTemp = false;
        }
      }
    },
    spread(level) {
      for (let children of this.childrenItems) {
        if (children.level === level) {
          children.showChildrenTemp = true;
        }
      }
    },
    getContentsItemByTag(tag) {
      for(let children of this.childrenItems) {
        if(children.tag === tag) return children;
      }
      return null;
    },
    getChoiceTags() {
      let result = new Array();
      for(let item of this.choiceChildrenItems) {
        if(item.tag) {
          result.push(item.tag);
        }
      }
      return result;
    },
    choiceItemByTag(tag) {
      let contentsItem = this.getContentsItemByTag(tag);
      this.isMultiChoice = false;
      this.provideData.multiChoice = this.isMultiChoice;
      this.$emit('update:multi-choice', this.isMultiChoice);
      if(contentsItem) {
        this.choice(contentsItem, false);
      }
    },
    choice(item, multiChoice) {
      if (this.choiceChildrenItems.indexOf(item) < 0) {
        item.isChoice = true;
        if (!multiChoice) {
          for (let choiceItem of this.choiceChildrenItems) {
            choiceItem.isChoice = false;
          }
          this.choiceChildrenItems = [item];
        } else {
          for(let n = this.choiceChildrenItems.length - 1; n >= 0; n--) {
            if(!this.choiceChildrenItems[n].enableMultiChoice) {
              this.choiceChildrenItems[n].isChoice = false;
              this.choiceChildrenItems.splice(n, 1);
            }
          }
          this.choiceChildrenItems.push(item);
        }
      }
      let childrenRect = null;
      if(item.$refs.content) {
        if(item.$refs.content.$el) {
          childrenRect = item.$refs.content.$el.getBoundingClientRect();
        } else {
          childrenRect = item.$refs.content.getBoundingClientRect();
        }
      }
      if(childrenRect) {
        let parentRect = this.$el.getBoundingClientRect();
        if (childrenRect.top < parentRect.top || childrenRect.bottom > parentRect.bottom) {
          this.$refs.scrollbar.wrap.scrollTo({
            top: childrenRect.top + this.$refs.scrollbar.wrap.scrollTop - parentRect.top - 40,
            behavior: "smooth"
          });
        }
      }
      this.$emit('choice', {tags: this.choiceChildrenItems.map(x=>x.tag), tag: item.tag});
    },
    unchoice(item) {
      item.isChoice = false;
      let index = this.choiceChildrenItems.indexOf(item);
      if (index >= 0) this.choiceChildrenItems.splice(index, 1);
      this.$emit('unchoice', {tags: this.choiceChildrenItems.map(x=>x.tag)});
    },
    unchoiceAll() {
      for(let children of this.childrenItems) {
        this.unchoice(children);
      }
    },
    handleMultiChoiceClick() {
      if(this.disableMultiChoice) return;
      this.isMultiChoice = !this.isMultiChoice;
      this.provideData.multiChoice = this.isMultiChoice;
      this.$emit('update:multi-choice', this.isMultiChoice);
    },
    handleCopyClick() {
      if(this.disableCopy) return;
      this.$emit('copy');
    },
    handleDeleteClick() {
      if(this.disableDelete) return;
      this.$emit('delete');
    },
    handleMove(evt) {
      this.$emit('move', evt);
    },
    handleSaveClick(evt) {
      this.$emit('save', evt);
    }
  },
  mounted() {
    this.doInitMouseEvents();
  },
  destroyed() {
    this.doReleaseMouseEvents();
    this.unchoiceAll();
  },
  activated() {
    this.doInitMouseEvents();
  },
  deactivated() {
    this.doReleaseMouseEvents();
    this.unchoiceAll();
  }

}
</script>

<style scoped>
@import "../../assets/css/style.css";

.contents {
  height: inherit;
  width: auto;
  padding-right: 8px;
}

.scrollbar {
  max-width: 322px;
  width: calc(20vw + 10px);
  box-sizing: border-box;
}

.scrollbar>>>.el-scrollbar__thumb {
  background-color: transparent !important;
}


</style>