<template>
  <div class="sheet-item-property__file padding" v-if="sheetItem">
    <div class="font-size-small"><span class="font-color-primary font-weight-bold"><span class="fas fa-list-ul"></span> 文件</span></div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal layout-middle">
      <span>最多上传文件数量：</span>
      <ys-number v-model="maxCount" :min="ExactNumber.of(1)" :max="ExactNumber.of(9)" placeholder="最多上传文件数量（1-9）"></ys-number>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsNumber from "@/components/wedigets/YsNumber";
import ExactNumber from "@/assets/javascript/exact-number";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemFilePropertyPanel",
  components: {SheetItemUserDataBinder, YsNumber, YsSwitch, YsTextarea},
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
      maxCount: null,
      ExactNumber,
    }
  },
  watch: {
    sheetItem: {
      handler() {
        this.maxCount = this.sheetItem && this.sheetItem.maxCount ? ExactNumber.of(this.sheetItem.maxCount) : ExactNumber.of(0);
      },
      immediate: true,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    },
    handleMaxCountInput(value) {
      this.sheetItem.maxCount = value ? ExactNumber.toFloat(value) : 1;
      this.handleInput();
    }
  }
}
</script>

<style scoped>

</style>