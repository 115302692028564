import SheetUtils from "@/assets/javascript/sheet-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import {TimeUtils} from "@/assets/javascript/time-utils";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";
import ca from "element-ui/src/locale/lang/ca";
import el from "element-ui/src/locale/lang/el";

export const SheetLinkOperations = {
    /**
     * 不做任何操作
     */
    NONE: 0,

    /**
     * 隐藏目标
     */
    HIDE_TARGET: 1,

    /**
     * 显示目标
     */
    SHOW_TARGET: 2,

    /**
     * 设置目标选填
     */
    SET_TARGET_OPTIONAL: 3,

    /**
     * 设置目标必填
     */
    SET_TARGET_REQUIRED: 4,

    /**
     * 清空目标
     */
    CLEAR: 5,

}

export const SheetLinkTriggers = {

    NONE: 0,

    HIDE: 1,

    SHOW: 2,

    EQUALS: 3,

    NOT_EQUALS: 4,

    GREATER_THAN: 5,

    LESS_THAN: 6,

    GREATER_THAN_OR_EQUAL: 7,

    LESS_THAN_OR_EQUAL: 8,

    CONTAINS: 9,

    EXCLUDE: 10,

    NOT_EMPTY: 11,

    EMPTY: 12,

}

export const SheetLinkTargets = {
    SHEET: 0,

    SHEET_PAGE: 1,

    SHEET_ITEM: 2,
}

export const SheetLinkHook = {
    ALL: 0,

    INIT: 1,

    INPUT: 2,

    FINISHED: 3,
}


function doGetTriggerElements(sheet, sheetRecord) {
    let globalIds = new Set();
    let result = {};

    if (!sheet.links) return result;

    for (let sheetLink of sheet.links) {
        globalIds.add(sheetLink.globalId);
        for (let trigger of sheetLink.triggers) {
            globalIds.add(trigger.globalId);
        }
    }

    if(globalIds.size == 0) return result;

    for (let n = 0; n < sheet.pages.length; n++) {
        let sheetPage = sheet.pages[n];
        if (n >= sheetRecord.pages.length) break;
        if (globalIds.has(sheetPage.globalId)) {
            let element = {
                target: SheetLinkTargets.SHEET_PAGE,
                sheetPage: sheetPage,
                sheetPageRecord: sheetRecord.pages[n],
            }
            if (result[sheetPage.globalId]) {
                result[sheetPage.globalId].push(element);
            } else {
                result[sheetPage.globalId] = [element];
            }
        }

        for (let m = 0; m < sheetPage.sheetItems.length; m++) {
            let sheetItem = sheetPage.sheetItems[m];
            if (globalIds.has(sheetItem.globalId)) {
                let sheetItemRecords = [];
                for (let itemRecord of sheetRecord.pages[n].items) {
                    if (itemRecord.number === sheetItem.number && itemRecord.type === sheetItem.type) {
                        sheetItemRecords.push(itemRecord);
                        if(sheetItem.type != SheetUtils.GROUP) break;
                    }
                }
                if(sheetItemRecords.length == 0) sheetItemRecords.push(SheetRecordUtils.defaultSheetRecord(sheetItem.type));
                for (let p = 0; p < sheetItemRecords.length; p++) {
                    let element = {
                        target: SheetLinkTargets.SHEET_ITEM,
                        sheetItem: sheetItem,
                        sheetItemRecord: sheetItemRecords[p],
                        index: p,
                    }
                    if (result[sheetItem.globalId]) {
                        result[sheetItem.globalId].push(element);
                    } else {
                        result[sheetItem.globalId] = [element];
                    }
                }
            }
            if (sheetItem.type === SheetUtils.GROUP) {
                for (let childSheetItem of sheetItem.items) {
                    if (globalIds.has(childSheetItem.globalId)) {
                        let sheetItemRecords = [];
                        for (let itemRecord of sheetRecord.pages[n].items) {
                            if (itemRecord.number === sheetItem.number && itemRecord.type === sheetItem.type) {
                                sheetItemRecords.push(itemRecord);
                            }
                        }
                        for (let p = 0; p < sheetItemRecords.length; p ++) {
                            let recordGroup = sheetItemRecords[p];
                            let childSheetItemRecord = null;
                            for (let tmpSheetItemRecord of recordGroup.items) {
                                if (tmpSheetItemRecord.number === childSheetItem.number && tmpSheetItemRecord.type === childSheetItem.type) {
                                    childSheetItemRecord = tmpSheetItemRecord;
                                    break;
                                }
                            }
                            if(!childSheetItemRecord) childSheetItemRecord = SheetRecordUtils.defaultSheetItemRecord(childSheetItem.type);

                            if(childSheetItemRecord) {
                                let element = {
                                    target: SheetLinkTargets.SHEET_ITEM,
                                    sheetItem: childSheetItem,
                                    sheetItemRecord: childSheetItemRecord,
                                    index: p,
                                };
                                if (result[childSheetItem.globalId]) {
                                    result[childSheetItem.globalId].push(element);
                                } else {
                                    result[childSheetItem.globalId] = [element];
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return result;
}

function isSheetItemEqual(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.CHOICE:
                return isSheetItemContains(trigger, element);
            case SheetUtils.STRING:
                return trigger.args[0] === element.sheetItemRecord.content;
            case SheetUtils.NUMBER:
                return ExactNumber.compare(JSON.parse(trigger.args[0]), element.sheetItemRecord.value) == 0;
            case SheetUtils.GENDER:
                return parseInt(trigger.args[0]) === element.sheetItemRecord.gender;
            case SheetUtils.ADDRESS:
                return false;
            case SheetUtils.CERTIFICATE:
                return false;
            case SheetUtils.ETHNICITY:
                return false;
            case SheetUtils.DATE:
                return trigger.args[0] === element.sheetItemRecord.date;
            case SheetUtils.TIME:
                return trigger.args[0] === element.sheetItemRecord.time;
            case SheetUtils.DATETIME:
                return TimeUtils.parseDate(trigger.args[0]).getTime() === element.sheetItemRecord.dateTime;
            case SheetUtils.IMAGE:
                return false;
            case SheetUtils.PHONE:
                return false;
            case SheetUtils.GROUP:
                return false;
            case SheetUtils.ILLUSTRATION:
                return false;
            case SheetUtils.FILE:
                return false;
            default:
                return false;
        }
    } catch (e) {
        return false;
    }
}

function isSheetItemContains(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.CHOICE: {
                let triggered = false;
                for (let selectionItem of element.sheetItemRecord.selection.selectedItems) {
                    if (selectionItem.name === trigger.args[0]) {
                        triggered = true;
                        break;
                    }
                }
                return triggered;
            }
            case SheetUtils.STRING:
                return element.sheetItemRecord.content && element.sheetItemRecord.content.indexOf(trigger.args[0]) >= 0;
            case SheetUtils.NUMBER:
                return false;
            case SheetUtils.GENDER:
                return parseInt(trigger.args[0]) == element.sheetItemRecord.gender;
            case SheetUtils.ADDRESS:
                return false;
            case SheetUtils.CERTIFICATE:
                return false;
            case SheetUtils.ETHNICITY:
                return false;
            case SheetUtils.DATE:
                return trigger.args[0] === element.sheetItemRecord.date;
            case SheetUtils.TIME:
                return trigger.args[0] === element.sheetItemRecord.time;
            case SheetUtils.DATETIME:
                return TimeUtils.parseDate(trigger.args[0]) === element.sheetItemRecord.dateTime;
            case SheetUtils.IMAGE:
                return false;
            case SheetUtils.PHONE:
                return false;
            case SheetUtils.GROUP:
                return false;
            case SheetUtils.ILLUSTRATION:
                return false;
            case SheetUtils.FILE:
                return false;
            default:
                return false;
        }
    } catch (e) {
        return false;
    }
}

function isSheetItemGreaterThan(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.NUMBER:
                return element.sheetItemRecord.value && ExactNumber.compare(element.sheetItemRecord.value, JSON.parse(trigger.args[0])) > 0;
            default:
                return 0;
        }
    } catch (e) {
        return false;
    }
}

function isSheetItemGreaterThanOrEqual(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.NUMBER:
                return element.sheetItemRecord.value && ExactNumber.compare(element.sheetItemRecord.value, JSON.parse(trigger.args[0])) >= 0;
            default:
                return 0;
        }
    } catch (e) {
        return false;
    }
}

function isSheetItemLessThan(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.NUMBER:
                return element.sheetItemRecord.value && ExactNumber.compare(element.sheetItemRecord.value, JSON.parse(trigger.args[0])) < 0;
            default:
                return 0;
        }
    } catch (e) {
        return false;
    }
}

function isSheetItemLessThanOrEqual(trigger, element) {
    if (trigger.args.length == 0) return false;
    try {
        switch (element.sheetItem.type) {
            case SheetUtils.NUMBER:
                return element.sheetItemRecord.value && ExactNumber.compare(element.sheetItemRecord.value, JSON.parse(trigger.args[0])) <= 0;
            default:
                return 0;
        }
    } catch (e) {
        return false;
    }
}

function isSheetTriggered() {
    return false;
}

function isSheetPageTriggered(trigger, element) {
    try {
        switch (trigger.type) {
            case SheetLinkTriggers.NONE:
                return true;
            case SheetLinkTriggers.HIDE:
                return element.sheetPage.hidden;
            case SheetLinkTriggers.SHOW:
                return !element.sheetPage.hidden;
            default:
                return false;
        }
    } catch(e) {
        return false;
    }
}

function isSheetItemTriggered(trigger, element) {
    try {
        switch (trigger.type) {
            case SheetLinkTriggers.NONE:
                return true;
            case SheetLinkTriggers.HIDE:
                return element.sheetItem.hidden;
            case SheetLinkTriggers.SHOW:
                return !element.sheetItem.hidden;
            case SheetLinkTriggers.EQUALS:
                return isSheetItemEqual(trigger, element);
            case SheetLinkTriggers.NOT_EQUALS:
                return !isSheetItemEqual(trigger, element);
            case SheetLinkTriggers.GREATER_THAN:
                return isSheetItemGreaterThan(trigger, element);
            case SheetLinkTriggers.GREATER_THAN_OR_EQUAL:
                return isSheetItemGreaterThanOrEqual(trigger, element);
            case SheetLinkTriggers.LESS_THAN:
                return isSheetItemLessThan(trigger, element);
            case SheetLinkTriggers.LESS_THAN_OR_EQUAL:
                return isSheetItemLessThanOrEqual(trigger, element);
            case SheetLinkTriggers.CONTAINS:
                return isSheetItemContains(trigger, element);
            case SheetLinkTriggers.EXCLUDE:
                return !isSheetItemContains(trigger, element);
            case SheetLinkTriggers.NOT_EMPTY:
                return !SheetRecordUtils.isSheetItemRecordEmpty(element.sheetItemRecord, element.sheetItem);
            case SheetLinkTriggers.EMPTY:
                return SheetRecordUtils.isSheetItemRecordEmpty(element.sheetItemRecord, element.sheetItem);
            default:
                return false;
        }
    } catch(e) {
        return false;
    }
}

function isTriggered(trigger, targetElement, elements, or) {
    if (Object.prototype.toString.call(trigger) === '[object Array]') {
        for (let n = 0; n < trigger.length; n++) {
            let childTrigger = trigger[n];
            let triggered = isTriggered(childTrigger, targetElement, elements, !or);
            if (or && triggered) return true;
            if (!or && !triggered) return false;
            if (n == trigger.length - 1) return triggered;
        }
        return false;
    } else {
        let sourceElements = elements[trigger.globalId];
        if (!sourceElements) return false;
        if (targetElement.index >= 0) {
            let list = new Array();
            for (let sourceElement of sourceElements) {
                if (sourceElement.index >= 0) {
                    if (sourceElement.index === targetElement.index) {
                        list.push(sourceElement);
                    }
                } else {
                    list.push(sourceElement);
                }
            }
            sourceElements = list;
        }
        for (let sourceElement of sourceElements) {
            switch (sourceElement.target) {
                case SheetLinkTargets.SHEET:
                    if (isSheetTriggered(trigger, sourceElement)) return true;
                    break;
                case SheetLinkTargets.SHEET_PAGE:
                    if (isSheetPageTriggered(trigger, sourceElement)) return true;
                    break;
                case SheetLinkTargets.SHEET_ITEM:
                    if (isSheetItemTriggered(trigger, sourceElement)) return true;
                    break;
            }
        }
        return false;
    }
}

function triggerSheetLink(sheetLink, element, elements, hook) {
    if (sheetLink.hook != SheetLinkHook.ALL) {
        if (sheetLink.hook !== hook) return null;
    }
    let linkResult = new Array();

    let triggered = false;
    for (let trigger of sheetLink.triggers) {
        if (isTriggered(trigger, element, elements, false)) {
            triggered = true;
            break;
        }
    }

    if (triggered) {
        for (let operation of sheetLink.operations) {
            linkResult.push({
                globalId: sheetLink.globalId,
                operation: operation,
                index: element.index,
            });
        }
    }

    return linkResult;
}

function doTrigger(sheet, sheetRecord, elements, hook) {
    let linkResult = new Array();
    if (!sheet.links) return linkResult;

    for (let sheetLink of sheet.links) {
        let targetElements = elements[sheetLink.globalId];
        if (!targetElements) continue;
        for (let element of targetElements) {
            let result = triggerSheetLink(sheetLink, element, elements, hook);
            if (result) {
                linkResult = linkResult.concat(result);
            }
        }
    }

    return linkResult;
}

function trigger(sheet, sheetRecord, hook) {
    if (!sheet.links) return new Array();

    let elements = doGetTriggerElements(sheet, sheetRecord);

    let linkResult = doTrigger(sheet, sheetRecord, elements, hook);

    return linkResult;
}

function updateSheetItem(sheetItem, sheetItemRecord, linkResult) {
    switch (linkResult.operation.type) {
        case SheetLinkOperations.NONE:
            break;
        case SheetLinkOperations.HIDE_TARGET:
            sheetItem.hidden = true;
            break;
        case SheetLinkOperations.SHOW_TARGET:
            sheetItem.hidden = false;
            break;
        case SheetLinkOperations.SET_TARGET_OPTIONAL:
            sheetItem.optional = true;
            break;
        case SheetLinkOperations.SET_TARGET_REQUIRED:
            sheetItem.optional = false;
            break;
        case SheetLinkOperations.CLEAR:
            if(sheetItemRecord) {
                SheetRecordUtils.clearSheetItemRecord(sheetItemRecord);
            }
            break;
    }
}

const SheetLinkUtils = {
    SheetLinkOperations,
    trigger,
    updateSheetItem,
}

export default SheetLinkUtils;
