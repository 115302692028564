<template>
  <div class="ys-date-picker" :class="{'is-disabled': disabled}">
    <div @click="handleClick" ref="reference">
      <slot name="reference">
        <div class="reference layout-horizontal layout-middle padding-horizontal">
          <span v-if="dateStr">{{ dateStr }}</span>
          <span class="font-color-placeholder">{{ placeholder }}</span>
        </div>
      </slot>
    </div>
    <div class="date-panel">
      <el-date-picker
          ref="datePanel"
          @input="handlePick"
          v-bind="$attrs"
          :format="type === 'datetime' ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'"
          :type="type"
          v-model="datePicked"></el-date-picker>
    </div>
  </div>
</template>

<script>
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "YsDatePicker",
  props: {
    date: String,
    placeholder: {
      type: String,
      default: '请选择日期',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: String,
  },
  data() {
    return {
      TimeUtils,
      datePicked: null,
    }
  },
  computed: {
    dateStr() {
      return TimeUtils.format('yyyy/MM/dd', this.date ? this.date : new Date());
    }
  },
  watch: {
    date: {
      handler() {
        this.datePicked = this.date ? TimeUtils.parseDate(this.date) : new Date();
      },
      immediate: true,
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      if (this.$refs.datePanel.$refs.reference.click) {
        this.$refs.datePanel.$refs.reference.click();
      } else {
        this.$refs.datePanel.$refs.reference.focus();
      }
    },
    handlePick(evt) {
      if(this.type === 'datetime') {
        this.$emit('pick', TimeUtils.format('yyyy-MM-dd HH:mm', evt));
      } else {
        this.$emit('pick', TimeUtils.format('yyyy-MM-dd', evt));
      }
    }
  }
}
</script>

<style scoped>

.ys-date-picker {
  cursor: pointer;
}

.ys-date-picker.is-disabled {
  cursor: not-allowed;
}

.reference {
  min-height: 56px;
}

.reference:hover {
  background-color: #f1f1f1;
}

.date-panel {
  width: 0px;
  height: 0px;
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
  position: relative;
  top: -10px;
}

</style>