var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-align-right layout-vertical layout-right"},[_c('ys-movable-item',{attrs:{"inner-class":"layout-right","tag":_vm.tag,"disable-moving":_vm.disable || _vm.titleEdit === 'edit' || _vm.disableMoving,"disable-touching":_vm.disableMoving,"disable-touching-top":_vm.disableTouchingTop,"disable-touching-bottom":_vm.disableTouchingBottom},on:{"update:moving":_vm.updateMovingState}},[_vm._t("default",[_c('div',{ref:"content",staticClass:"contents-item layout-horizontal layout-middle",class:{
         'contents-item__level1': _vm.level === 1,
         'contents-item__level2': _vm.level === 2,
         'contents-item__level3': _vm.level === 3,
         'contents-item__level4': _vm.level >= 4,
         'is-choice': _vm.isChoice,
         'is-choice__by-ancestor': _vm.multiChoice && _vm.isChoiceByAncestor
        },on:{"click":function($event){$event.stopPropagation();return _vm.handleClick($event)}}},[(_vm.multiChoice && _vm.enableMultiChoice)?_c('span',{staticClass:"margin-right-small"},[(_vm.isChoiceByAncestor)?_c('i',{staticClass:"fas fa-check-square font-color-danger",staticStyle:{"opacity":"0.5"}}):_c('i',{class:[("" + (_vm.isChoice ? 'fas font-color-danger' : 'far font-color-placeholder')), 'fa-check-square']})]):_vm._e(),(_vm.$slots.children)?_c('i',{staticClass:"far",class:_vm.showChildren && _vm.showChildrenTemp ? 'fa-minus-square' : 'fa-plus-square',on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMouseDown($event)},"mouseup":function($event){$event.stopPropagation();return _vm.handleMouseUp($event)},"click":function($event){$event.stopPropagation();return _vm.handleShowChildrenClick($event)}}}):_vm._e(),_c('ys-click-to-edit',{staticStyle:{"text-overflow":"ellipsis","margin-left":"4px","width":"100%"},attrs:{"mode":_vm.titleEdit,"disable":_vm.disable || _vm.disableEdit,"placeholder":_vm.placeholder ? _vm.placeholder : '未命名'},on:{"update:mode":function($event){_vm.titleEdit=$event},"blur":_vm.handleTitleBlur},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(!_vm.moving)?_c('el-collapse-transition',{attrs:{"name":"normal-expand"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren && _vm.showChildrenTemp),expression:"showChildren && showChildrenTemp"}],staticClass:"layout-vertical layout-right"},[(_vm.subMovableRegion)?_c('ys-movable',[_vm._t("children")],2):_vm._t("children")],2)]):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }