<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible => $emit('update:visible', visible)" title="选择题 · 选项">
    <div class="padding" v-if="choiceItem">
      <div>修改/创建选择题选项</div>
      <div class="layout-horizontal margin-top" v-if="choiceItem">
        <span class="layout-inflexible">选项：</span>
        <ys-textarea v-model="choiceItem.value" :maxlength="SheetUtils.CHOICE_ITEM_MAX_LENGTH"
                     placeholder="请输入表单选项内容"></ys-textarea>
      </div>
      <div class="layout-horizontal margin-top layout-middle" v-if="choiceItem">
        <span class="layout-inflexible">分数：</span>
        <ys-number v-model="choiceItem.score"></ys-number>
        <span class="font-color-primary margin-left-small">"</span>
      </div>
      <div class="layout-horizontal margin-top">
        <span>与其他选项互斥：</span>
        <ys-switch v-model="choiceItem.rejective"></ys-switch>
      </div>
    </div>
    <div class="layout-horizontal layout-right padding-top-small" style="background-color: #f1f1f1;" slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button type="primary" lighting class="margin-left" @click="handleConfirmClick">确定</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsNumber from "@/components/wedigets/YsNumber";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsButton from "@/components/wedigets/YsButton";
import Utils from "@/assets/javascript/utils";

export default {
  name: "ChoiceItemEditDialog",
  components: {YsButton, YsSwitch, YsNumber, YsTextarea, YsDialog},
  props: {
    value: Object,
    visible: Boolean,
  },
  data() {
    return {
      SheetUtils,
      isVisible: false
    }
  },
  watch: {
    value: {
      handler() {
        this.choiceItem = this.value ? Utils.deepCopy(this.value) : null;
      },
      immediate: true,
    },
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  methods: {
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', this.isVisible);
    },
    handleConfirmClick() {
      this.isVisible = false;
      this.$emit('update:visible', this.isVisible);
      this.$emit('input', this.choiceItem);
    }
  }
}
</script>

<style scoped>

</style>