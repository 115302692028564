<script>

import Utils from "@/assets/javascript/utils";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";
import SheetUtils from "@/assets/javascript/sheet-utils";

function doCreateSheetRecordModel(sheet, sheetRecord) {
  let pageModelList = new Array();
  if(!sheetRecord) sheetRecord = SheetRecordUtils.defaultSheetRecord();
  for (let page of sheet.pages) {
    let pageRecord = null;
    for(let sheetPageRecord of sheetRecord.pages) {
      if(sheetPageRecord.number === page.number) {
        pageRecord = sheetPageRecord;
        break;
      }
    }
    if(!pageRecord) {
      pageRecord = SheetRecordUtils.defaultSheetPageRecord(page.number);
    }
    pageModelList.push(doCreatePageRecordModel(page, pageRecord));
  }
  return {
    id: sheet.id,
    sheetRecordId: sheetRecord.id,
    modifiedCode: sheetRecord.modifiedCode,
    name: sheet.name,
    message: sheet.message,
    globalId: sheet.globalId,
    links: Utils.deepCopy(sheet.links),
    key: SheetItemKeyGenerator.methods.keyOfSheet(sheet),
    pages: pageModelList,
    linkResults: sheetRecord.linkResults,
  }
}

function doCreatePageRecordModel(page, pageRecord) {
  let itemModelList = new Array();
  for (let sheetItem of page.sheetItems) {
    let itemModel;
    let sheetItemRecordList = new Array();
    for(let sheetItemRecord of pageRecord.items) {
      if(sheetItemRecord.number === sheetItem.number) {
        sheetItemRecordList.push(sheetItemRecord);
      }
    }
    if(sheetItemRecordList.length === 0) {
      let sheetItemRecord = SheetRecordUtils.defaultSheetItemRecord(sheetItem.type);
      sheetItemRecord.number = sheetItem.number;
      sheetItemRecordList.push(sheetItemRecord);
    }
    for(let n = 0; n < sheetItemRecordList.length; n++) {
      if (sheetItem.type === SheetUtils.GROUP) {
        itemModel = doCreateItemGroupModel(sheetItem, sheetItemRecordList[n], n, sheetItemRecordList.length);
      } else {
        itemModel = doCreateItemModel(sheetItem, sheetItemRecordList[n], n);
      }
      itemModelList.push(itemModel);
    }
  }
  doCreateAdditionalSheetItems(pageRecord.additionalSheetItems, itemModelList);
  return {
    name: page.name,
    message: page.message,
    number: page.number,
    globalId: page.globalId,
    key: SheetItemKeyGenerator.methods.keyOfSheetPage(page),
    pages: [{
      key: SheetItemKeyGenerator.methods.keyOfSubSheetPage(page),
      items: itemModelList,
    }]
  }
}

function doCreateItemModel(sheetItem, sheetItemRecord, index = 0) {
  return {
    number: sheetItem.number,
    globalId: sheetItem.globalId,
    type: sheetItem.type,
    key: SheetItemKeyGenerator.methods.keyOfSheetItem(sheetItem, index),
    template: Utils.deepCopy(sheetItem),
    record: Utils.deepCopy(sheetItemRecord),
  }
}

function doCreateItemGroupModel(itemGroup, itemRecordGroup, index = 0, count = 1) {
  let items = new Array();
  let key = SheetItemKeyGenerator.methods.keyOfSheetItem(itemGroup, index);
  for(let sheetItem of itemGroup.items) {
    let sheetItemRecord = null;
    for(let subSheetItem of itemRecordGroup.items) {
      if(subSheetItem.number === sheetItem.number) {
        sheetItemRecord = subSheetItem;
        break;
      }
    }
    if(!sheetItemRecord) sheetItemRecord = SheetRecordUtils.defaultSheetItemRecord(sheetItem.type);
    let itemModel = doCreateItemModel(sheetItem, sheetItemRecord, index);
    itemModel.groupKey = key;
    items.push(itemModel);
  }
  doCreateAdditionalSheetItems(itemRecordGroup.additionalSheetItems, items);
  let template = Utils.deepCopy(itemGroup);
  delete template.items;
  return {
    number: itemGroup.number,
    globalId: itemGroup.globalId,
    type: itemGroup.type,
    index: index,
    count: count,
    key,
    template,
    items,
  }
}

function doCreateAdditionalSheetItems(additionalSheetItems, itemModelList) {
  for(let additionalSheetItem of additionalSheetItems) {
    let itemModel;
    if(additionalSheetItem.sheetItem.type === SheetUtils.GROUP) {
      itemModel = doCreateItemGroupModel(additionalSheetItem.sheetItem, additionalSheetItem.itemRecord);
    } else {
      itemModel = doCreateItemModel(additionalSheetItem.sheetItem, additionalSheetItem.itemRecord);
    }
    let inserted = false;
    for(let n = itemModelList.length - 1; n >= 0; n--) {
      if(itemModelList[n].number === additionalSheetItem.sheetItem.number) {
        itemModelList.splice(n + 1, 0, itemModel);
        inserted = true;
        break;
      }
    }
    if(!inserted) {
      itemModelList.splice(0, 0, itemModel);
    }
  }
}

export default {
  name: "SheetRecordViewModelCreator",
  methods: {
    createSheetRecordModel(sheet, sheetRecord) {
      return doCreateSheetRecordModel(sheet, sheetRecord);
    },
    createSheetItemRecordGroupModel(group, recordGroup, index, count) {
      return doCreateItemGroupModel(group, recordGroup, index, count);
    }
  }
}
</script>

<style scoped>

</style>