<template>
  <div class="sheet-item sheet-item__string" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} {{ simulatedInputText }}</div>
    <div class="title layout-horizontal layout-middle padding-left-large padding-right-small">
      <span class="font-color-danger layout-inflexible" v-if="!sheetItem.optional">*</span>
      <div :class="mode === ViewMode.MODE_EDIT ? 'layout-flexible' : 'layout-inflexible'">
        <ys-click-to-edit v-model="title" placeholder="双击此处添加标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                          v-if="mode === ViewMode.MODE_EDIT" style="width: 100%;" @blur="handleTitleBlur"
                          type="textarea"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-flexible layout-horizontal layout-right layout-middle" v-if="!isBigGap">
        <ys-click-to-edit v-model="placeholder" placeholder="双击此处添加占位文字"
                          class="font-color-placeholder font-align-right padding-right-large"
                          style="text-align: right !important; width: 100%;"
                          :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                          v-if="mode === ViewMode.MODE_EDIT" @blur="handlePlaceholderBlur"
                          type="textarea"></ys-click-to-edit>
        <ys-input class="font-color-normal" style="text-align: right; width: 100%" v-else-if="!readOnly"
                  v-model="sheetItemRecord.content"
                  :placeholder="placeholder ? placeholder : '请输入'"
                  :show-background="false"
                  :maxlength="sheetItem.maxLength"
                  :disabled="readOnly"
                  @input="handleContentInput"></ys-input>
        <span v-else-if="sheetItemRecord && sheetItemRecord.content"
              class="padding-right-large">{{ sheetItemRecord.content }}</span>
        <span v-else class="font-color-placeholder padding-right-large">未填写</span>
      </div>
    </div>
    <div v-if="isBigGap" class="padding-horizontal-large padding-bottom-large">
      <ys-click-to-edit v-model="placeholder" placeholder="双击此处添加占位文字"
                        class="font-color-placeholder padding-right-large"
                        style="text-align: left !important; width: 100%;" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                        v-if="mode === ViewMode.MODE_EDIT" type="textarea"></ys-click-to-edit>
      <ys-textarea
          v-else-if="!readOnly"
          class="font-color-normal"
          :placeholder="placeholder"
          :show-background="false"
          :disabled="readOnly"
          v-model="sheetItemRecord.content"
          @input="handleContentInput"
      ></ys-textarea>
      <span v-else-if="sheetItemRecord.content">{{ sheetItemRecord.content }}</span>
      <span v-else class="font-color-placeholder">未填写</span>
    </div>
  </div>
</template>

<script>
import {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsInput from "@/components/wedigets/YsInput";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemStringPropertyPanel from "@/components/sheet/panel/SheetItemStringPropertyPanel";

export default {
  name: "SheetItemString",
  mixins: [SheetItemBasic],
  components: {YsInput, YsTextarea, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    propertyDocker: Element,
    readOnly: Boolean,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      placeholder: null,
      isBigGap: false,
      SheetUtils,
      ViewMode,
      propertyPanel: null,
    }
  },
  computed: {
    simulatedInputText() {
      let maxlength = this.sheetItem.maxLength;
      let result = '';
      for (let n = 0; n < maxlength && n < 50; n++) {
        result += '缮';
      }
      let placeholder = this.sheetItem.placeholder ? this.sheetItem.placeholder : '双击此处添加占位文字';
      if (placeholder.length > result.length) result = placeholder;
      return result;
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.placeholder = this.sheetItem.placeholder;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    }
  },
  methods: {
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = evt.width + 20 > itemWidth;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePlaceholderBlur() {
      this.sheetItem.placeholder = this.placeholder;
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.placeholder = this.sheetItem.placeholder;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = this.propertyDocker.mountPanel(SheetItemStringPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleContentInput() {
      this.$emit('input', this.item);
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.text-measurer {
  height: 0px;
  background-color: #ff5500;
  position: fixed;
  overflow: hidden;
}

.title {
  min-height: 40px;
  box-sizing: border-box;
}

</style>