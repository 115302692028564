import GuidUtils from "@/assets/javascript/guid-utils";

const defaultLocalImage = (file) => {
    if(file && !file.url) {
        let URL = window.URL || window.webkitURL;
        file.url = URL.createObjectURL(file.raw);
    }
    return {
        id: 0,
        url: file ? file.url : null,
        __localImageFile: file,
    }
}

const containsUncommittedLocalImages = (obj) => {
    let targetList = [];
    let localImageFiles = [];
    if (typeof obj === 'object') {
        targetList.push(obj);
    }
    for (let n = 0; n < targetList.length; n++) {
        let target = targetList[n];
        if (Object.prototype.toString.call(target) === '[object Array]') {
            for (let childTarget of target) {
                if (typeof childTarget === 'object') {
                    targetList.push(childTarget);
                }
            }
        } else if (typeof target === 'object') {
            for (let key in target) {
                if (key === '__localImageFile') {
                    if (target.id === 0) {
                        if (!target.guid) target.guid = GuidUtils.guid();
                        localImageFiles.push(target);
                    }
                    continue;
                }
                let childrenTarget = target[key];
                if (typeof childrenTarget === 'object') {
                    targetList.push(childrenTarget);
                }
            }
        }
    }
    return localImageFiles.length > 0;
}

const ImageUtils = {
    defaultLocalImage: defaultLocalImage,
    containsUncommittedLocalImages,
}

export default ImageUtils;