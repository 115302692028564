<template>
  <div>
    <el-popover v-model="visible" v-bind="$attrs">
      <slot name="reference" slot="reference">
        <div class="reference">
          <span v-if="ethnicity">{{ ethnicity }}</span>
          <span v-else class="font-color-placeholder">{{ placeholder }} <i class="fa fa-angle-right"></i></span>
        </div>
      </slot>
      <div class="panel">
        <div class="font-size-small">民族：</div>
        <div class="margin-top">
          <ys-button :type="ethnicityReturn === '汉族' ? 'primary' : 'secondary'" @click="handleHanClick">汉族</ys-button>
        </div>
        <div class="margin-top">
          <ys-input placeholder="其他民族" v-model="ethnicityInput" @focus="handleOtherEthnicityBlur" @input="handleEthnicityInput" :maxlength="50"
                    @keyup.enter.native="handleConfirmClick"></ys-input>
        </div>
        <div class="margin-top-small font-align-right">
          <ys-button @click="handleConfirmClick" lighting size="small">确定</ys-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import YsButton from "@/components/wedigets/YsButton";
import YsInput from "@/components/wedigets/YsInput";

export default {
  name: "YsEthnicityPicker",
  components: {YsInput, YsButton},
  props: {
    ethnicity: String,
    placeholder: {
      type: String,
      default: '请选择民族'
    }
  },
  data() {
    return {
      visible: false,
      ethnicityReturn: null,
      ethnicityInput: null,
    }
  },
  watch: {
    ethnicity: {
      handler() {
        this.ethnicityInput = this.ethnicity === '汉族' ? null : this.ethnicity;
        this.ethnicityReturn = this.ethnicity;
      },
      immediate: true,
    }
  },
  methods: {
    handleHanClick() {
      this.visible = false;
      this.$emit('pick', '汉族');
    },
    handleOtherEthnicityBlur() {
      this.ethnicityReturn = this.ethnicityInput;
    },
    handleEthnicityInput() {
      this.ethnicityReturn = this.ethnicityInput;
    },
    handleConfirmClick() {
      this.visible = false;
      this.$emit('pick', this.ethnicityReturn);
    },
  }
}
</script>

<style scoped>

.reference {
  cursor: pointer;
}

</style>