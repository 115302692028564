<script>

import {SheetLinkOperations, SheetLinkTriggers} from "@/assets/javascript/sheet-link-utils";
import SheetUtils from "@/assets/javascript/sheet-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";

function doGenerateKey(globalId, index = 0) {
  return `${globalId}#${index}`;
}

function doPrepareLinkTargets(sheetModel) {
  let linkTargets = {};
  let linkMap = {};

  if (sheetModel.links) {
    for (let link of sheetModel.links) {
      linkMap[link.globalId] = link;
    }
  }

  for (let pageModel of sheetModel.pages) {
    linkTargets[doGenerateKey(pageModel.globalId)] = {
      key: pageModel.key,
      isPage: true,
      model: sheetModel,
      link: linkMap[pageModel.globalId],
    }
    for (let subPage of pageModel.pages) {
      for (let itemModel of subPage.items) {
        linkTargets[doGenerateKey(itemModel.globalId, itemModel.index ?? 0)] = {
          key: itemModel.key,
          isItem: true,
          model: itemModel,
          link: linkMap[itemModel.globalId],
        }
        if (itemModel.type === SheetUtils.GROUP) {
          for (let subItemModel of itemModel.items) {
            linkTargets[doGenerateKey(subItemModel.globalId, itemModel.index ?? 0)] = {
              key: subItemModel.key,
              isItem: true,
              model: subItemModel,
              link: linkMap[subItemModel.globalId],
            }
          }
        }
      }
    }
  }

  return linkTargets;
}

function isEmptyTriggered(trigger, reference) {
  if (reference.isPage) {
    return false;
  } else if (reference.isItem) {
    if (reference.model.type === SheetUtils.GROUP) {
      for (let subItemModel of reference.model.items) {
        if (!SheetRecordUtils.isSheetItemRecordEmpty(subItemModel.record, subItemModel.template)) {
          return false;
        }
      }
      return true;
    } else {
      return SheetRecordUtils.isSheetItemRecordEmpty(reference.model.record, reference.model.template);
    }
  } else {
    return false;
  }
}

function isEqualTriggered(trigger, reference) {
  if (reference.isPage) {
    return false;
  } else if (reference.isItem) {
    switch (reference.model.type) {
      case SheetUtils.STRING:
        return reference.model.record.content === trigger.args[0];
      case SheetUtils.NUMBER:
        return ExactNumber.compare(reference.model.record.value, JSON.parse(trigger.args[0])) === 0;
      case SheetUtils.CHOICE:
        if (reference.model.record.selection.selectedItems.length + reference.model.record.selection.others.length > 1) return false;
        for (let selectedItem of reference.model.record.selection.selectedItems) {
          if (selectedItem.name === trigger.args[0]) {
            return true;
          }
        }
        for (let other of reference.model.record.selection.others) {
          if (other === trigger.args[0]) {
            return true;
          }
        }
        return false;
      case SheetUtils.GENDER:
        return parseInt(trigger.args[0]) === reference.model.record.gender;
      case SheetUtils.DATE:
        return trigger.args[0] === reference.model.record.date;
      case SheetUtils.TIME:
        return trigger.args[0] === reference.model.record.time;
      case SheetUtils.DATETIME:
        return parseInt(trigger.args[0]) === reference.model.record.dateTime;
      default:
        return false;
    }
  } else {
    return false;
  }
}

function compareByTrigger(trigger, reference) {
  if (reference.isPage) {
    return 0;
  } else if (reference.isItem) {
    switch (reference.model.type) {
      case SheetUtils.NUMBER:
        return ExactNumber.compare(reference.model.record.value, JSON.parse(trigger.args[0]))
      default:
        return 0;
    }
  } else {
    return 0;
  }
}

function isContainsByTriggered(trigger, reference) {
  if (reference.isPage) {
    return false;
  } else if (reference.isItem) {
    switch (reference.model.type) {
      case SheetUtils.STRING:
        return reference.model.record.content.indexOf(trigger.args[0]) >= 0;
      case SheetUtils.CHOICE:
        for (let selectedItem of reference.model.record.selection.selectedItems) {
          if (selectedItem.name === trigger.args[0]) {
            return true;
          }
        }
        for (let other of reference.model.record.selection.others) {
          if (other === trigger.args[0]) {
            return true;
          }
        }
        return false;
      default:
        return false;
    }
  } else {
    return false;
  }
}

function isTriggerTriggered(trigger, target, linkTargets) {
  let reference = linkTargets[doGenerateKey(trigger.globalId, target.model.index ?? 0)];
  if (!reference) {
    reference = linkTargets[doGenerateKey(trigger.globalId)];
  }
  if (!reference) return false;
  switch (trigger.type) {
    case SheetLinkTriggers.NONE:
      return true;
    case SheetLinkTriggers.HIDE:
      return reference.model.hide === true;
    case SheetLinkTriggers.SHOW:
      return reference.model.hide !== true;
    case SheetLinkTriggers.EQUALS:
      if (isEmptyTriggered(trigger, reference)) return false;
      return isEqualTriggered(trigger, reference);
    case SheetLinkTriggers.NOT_EQUALS:
      if (isEmptyTriggered(trigger, reference)) return false;
      return !isEqualTriggered(trigger, reference);
    case SheetLinkTriggers.GREATER_THAN:
      if (isEmptyTriggered(trigger, reference)) return false;
      return compareByTrigger(trigger, reference) > 0;
    case SheetLinkTriggers.GREATER_THAN_OR_EQUAL:
      if (isEmptyTriggered(trigger, reference)) return false;
      return compareByTrigger(trigger, reference) >= 0;
    case SheetLinkTriggers.LESS_THAN:
      if (isEmptyTriggered(trigger, reference)) return false;
      return compareByTrigger(trigger, reference) < 0;
    case SheetLinkTriggers.LESS_THAN_OR_EQUAL:
      if (isEmptyTriggered(trigger, reference)) return false;
      return compareByTrigger(trigger, reference) <= 0;
    case SheetLinkTriggers.CONTAINS:
      if (isEmptyTriggered(trigger, reference)) return false;
      return isContainsByTriggered(trigger, reference);
    case SheetLinkTriggers.EXCLUDE:
      if (isEmptyTriggered(trigger, reference)) return true;
      return !isContainsByTriggered(trigger, reference);
    case SheetLinkTriggers.NOT_EMPTY:
      return !isEmptyTriggered(trigger, reference);
    case SheetLinkTriggers.EMPTY:
      return isEmptyTriggered(trigger, reference);
    default:
      return false;
  }
}

function isTriggerOrLinkTriggered(triggerList, target, linkTargets) {
  for (let trigger of triggerList) {
    let triggered = false;
    if (Object.prototype.toString.call(trigger) === '[object Array]') {
      triggered = isTriggerAndLinkTriggered(trigger, target, linkTargets);
    } else {
      triggered = isTriggerTriggered(trigger, target, linkTargets);
    }
    if (triggered) return true;
  }
  return false;
}

function isTriggerAndLinkTriggered(triggerList, target, linkTargets) {
  for (let trigger of triggerList) {
    let triggered = false;
    if (Object.prototype.toString.call(trigger) === '[object Array]') {
      triggered = isTriggerOrLinkTriggered(trigger, target, linkTargets);
    } else {
      triggered = isTriggerTriggered(trigger, target, linkTargets);
    }
    if (!triggered) return false;
  }
  return true;
}

function isTargetTriggered(target, linkTargets) {
  return isTriggerOrLinkTriggered(target.link.triggers, target, linkTargets);
}

function doDescribeLink(target, linkTargets) {
  let linkResults = new Array();
  if (isTargetTriggered(target, linkTargets)) {
    for (let operation of target.link.operations) {
      linkResults.push({
        operation: operation,
        globalId: target.model.globalId,
        index: target.model.index ?? 0,
      })
    }
  }
  return linkResults;
}

function doDescribeLinks(sheetModel) {
  let linkTargets = doPrepareLinkTargets(sheetModel);
  let linkResults = new Array();
  for (let key in linkTargets) {
    let target = linkTargets[key];
    if (target.link) {
      let stepLinkResult = doDescribeLink(target, linkTargets);
      if (stepLinkResult.length > 0) {
        linkResults = linkResults.concat(stepLinkResult);
      }
    }
  }
  sheetModel.linkResults = linkResults;
  doRenderLinkResults(sheetModel, linkTargets);
}

function doRenderLinkResult(linkResult, linkTargets) {
  let target = linkTargets[doGenerateKey(linkResult.globalId, linkResult.index)];
  if (!target) {
    target = linkTargets[doGenerateKey(linkResult.globalId, 0)];
  }
  if (!target) return;

  switch (linkResult.operation.type) {
    case SheetLinkOperations.NONE:
      break;
    case SheetLinkOperations.HIDE_TARGET:
      target.model.hide = true;
      break;
    case SheetLinkOperations.SHOW_TARGET:
      target.model.hide = false;
      break;
    case SheetLinkOperations.SET_TARGET_OPTIONAL:
      target.model.template.optional = true;
      break;
    case SheetLinkOperations.SET_TARGET_REQUIRED:
      target.model.template.optional = false;
      break;
    case SheetLinkOperations.CLEAR:
      if (target.isItem) {
        target.model.record = SheetRecordUtils.defaultSheetItemRecord(target.model.template.type);
        target.model.record.number = target.model.template.number;
      }
      break;
  }
}

function doRenderLinkResults(sheetModel, linkTargets) {
  if (sheetModel.linkResults.length == 0) return;

  if (!linkTargets) {
    linkTargets = doPrepareLinkTargets(sheetModel);
  }
  for (let linkResult of sheetModel.linkResults) {
    doRenderLinkResult(linkResult, linkTargets);
  }
}

export default {
  name: "LinkResultEngine",
  data() {
    return {}
  },
  methods: {
    describeLinks(sheetModel) {
      doDescribeLinks(sheetModel);
    },
    renderLinkResults(sheetModel) {
      doRenderLinkResults(sheetModel);
    }
  }
}
</script>

<style scoped>

</style>