
const defaultQuestionnaire = () => {
    return {
        title: null,
        message: null,
        organizationId: 0,
        modifiedCode: 1,
        buffet: false,
        sheetIdList: [],
    }
}

const QuestionnaireUtils = {
    TITLE_MAX_LENGTH: 80,
    MESSAGE_MAX_LENGTH: 10000,

    SHEET_MAX_COUNT: 20,

    defaultQuestionnaire: defaultQuestionnaire,

}

export default QuestionnaireUtils;