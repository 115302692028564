<template>
  <div class="ys-number-wrapper">
    <el-input
        :class="[
          'ys-number',
          size ? 'ys-number--size-' + size : '',
          {
            'is-focused': focused,
            'is-disabled': disabled,
            'is-dark': dark,
            'is-hollow': hollow,
            'is-hide-background': !showBackground,
          }
      ]"
        v-bind="$attrs"
        :tabindex="tabindex"
        type="string"
        :disabled="disabled"
        :readonly="readonly"
        :auto-complete="autocomplete"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        :label="label"
        v-model="innerValue"
        :prefix-icon="prefixIcon"
        :suffix-icon="suffixIcon"
    >
      <slot slot="prefix" name="prefix"></slot>
      <slot slot="suffix" name="suffix"></slot>
      <slot slot="append" name="append"></slot>
    </el-input>
  </div>
</template>

<script>
import RegexDef from "@/assets/javascript/regex-definitions";
import ExactNumber from "@/assets/javascript/exact-number";
import Utils from "@/assets/javascript/utils";

export default {
  name: "YsNumber",
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: Object,
    resize: String,
    form: String,
    readonly: Boolean,
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    suffixIcon: String,
    prefixIcon: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    hollow: {
      type: Boolean,
      default: false,
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
    tabindex: String,
    max: {
      type: Object,
      default() {
        return ExactNumber.of(10000, 0);
      }
    },
    min: {
      type: Object,
      default() {
        return ExactNumber.of(0, 0);
      }
    },
    digits: {
      type: Number,
      default: 2,
    }
  },
  data() {
    return {
      previousValue: null,
      innerValue: null,
      focused: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.previousValue = null;
        this.innerValue = this.value ? this.filterNumber(ExactNumber.stringify(this.value), true) : null;
      },
      immediate: true,
    }
  },
  methods: {
    handleInput(value) {
      if(value) {
        let filteredValue = this.filterNumber(value, false);
        if (!filteredValue) {
          this.innerValue = this.previousValue;
        } else {
          if(this.innerValue !== filteredValue) this.innerValue = filteredValue;
          this.previousValue = this.innerValue;
        }
      }
      //this.$emit('input', value);
    },
    handleChange(value) {
      let filteredValue = this.filterNumber(value, true);
      if (!filteredValue) {
        this.innerValue = this.previousValue;
      } else {
        this.previousValue = this.innerValue;
      }
      let result = null;
      if (filteredValue) {
        result = ExactNumber.parse(filteredValue);
      }
      this.$emit('input', result);
      this.$emit('change', result);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    filterNumber(str, forceMinNumber) {
      if (!str) return null;
      if (str == '-') {
        if(this.min && this.min.value < 0) {
          return str;
        } else {
          return ""
        }
      }
      if (this.digits == null || this.digits == 0) {
        if (!RegexDef.INTEGER.test(str)) {
          return this.preLocalValue;
        }
      } else if (RegexDef.limitDigits(this.display)) {
        if (!RegexDef.limitDigits(this.digits).test(str)) {
          return this.preLocalValue;
        }
      }
      let val = ExactNumber.parse(str);
      let changed = false;

      if (forceMinNumber && this.min != null) {
        if (this.min && ExactNumber.compare(val, this.min) < 0) {
          val = Utils.deepCopy(this.min);
          changed = true;
        }
      }
      if (this.max && ExactNumber.compare(val, this.max) > 0) {
        val = Utils.deepCopy(this.max);
        changed = true;
      }

      return changed ? ExactNumber.stringify(val) : str;
    },
  }
}
</script>

<style scoped>

.ys-number-wrapper {
  display: inline-block;
}


.ys-number {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.ys-number.is-focused {
  border: 2px solid #f091a6;
}

.ys-number.is-disabled {
  opacity: 0.5;
}

.ys-number.is-hollow {
  box-shadow: 0 0 2px #dfdfdf inset;
}

.ys-number.is-dark {
  background-color: #f1f1f1;
}

.ys-number.is-dark.is-hollow {
  box-shadow: 0 0 2px #bec0c0 inset;
}

.ys-number.is-hide-background {
  border: none;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ys-number.is-focused.is-hide-background {
  border: none;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ys-number--size-normal {
  height: 40px;
  width: inherit;
  border-radius: 20px;
  font-size: 16px;
}

.ys-number--size-large {
  height: 48px;
  width: inherit;
  border-radius: 24px;
  font-size: 18px;
}

.ys-number--size-large >>> .el-input__inner {
  height: 48px;
}

.ys-number >>> .el-input__inner {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  text-align: inherit;
}


</style>