<script>
import SheetItemCoordinateHelper from "@/components/sheet/basic/SheetItemCoordinateHelper";
import SheetUtils from "@/assets/javascript/sheet-utils";
import GuidUtils from "@/assets/javascript/guid-utils";
import Utils from "@/assets/javascript/utils";

function doMarkSheetByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  sheet.__copy = true;
}

function doMarkPageByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  let page = sheet.pages[coordinate.page];
  page.__copy = true;
}

function doMarkSheetItemByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  let page = sheet.pages[coordinate.page];
  let sheetItem = page.sheetItems[coordinate.item];
  sheetItem.__copy = true;
}

function doMarkSubSheetItemByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  let page = sheet.pages[coordinate.page];
  let sheetItem = page.sheetItems[coordinate.item];
  if (sheetItem.type === SheetUtils.GROUP) {
    let subSheetItem = sheetItem.items[coordinate.s_item];
    subSheetItem.__copy = true;
  }
}

function doGenerateGlobalId(obj) {
  if(typeof obj !== 'object') return;
  if(Object.prototype.toString.call(obj) === '[object Array]') {
    for(let item of obj) {
      doGenerateGlobalId(item);
    }
  } else {
    for(let key in obj) {
      let children = obj[key];
      if(children) {
        doGenerateGlobalId(children);
      }
    }
    if(obj.globalId) obj.globalId = GuidUtils.guid();
  }
}

function doCopySheet(sheetList) {
  for(let sheetIndex = 0; sheetIndex < sheetList.length; sheetIndex ++) {
    let source = sheetList[sheetIndex];
    if(source.__copy) {
      delete source.__copy;
      let target = Utils.deepCopy(source);
      target.__copied = true;
      doGenerateGlobalId(target);
      sheetList.splice(sheetIndex + 1, 0, target);
      continue;
    }
    doCopyPage(source.pages);
  }
}

function doCopyPage(pages) {
  let isCopied = false;
  for(let pageIndex = 0; pageIndex < pages.length; pageIndex ++) {
    let source = pages[pageIndex];
    if(source.__copy) {
      delete source.__copy;
      let target = Utils.deepCopy(source);
      target.__copied = true;
      doGenerateGlobalId(target);
      pages.splice(pageIndex + 1, 0, target);
      isCopied = true;
      continue;
    }
    doCopySheetItem(source.sheetItems);
  }
  if(isCopied) {
    let number = 1;
    for(let page of pages) {
      page.number = number ++;
    }
  }
}

function doCopySheetItem(items) {
  let isCopied = false;
  for(let itemIndex = 0; itemIndex < items.length; itemIndex ++) {
    let source = items[itemIndex];
    if(source.__copy) {
      delete source.__copy;
      let target = Utils.deepCopy(source);
      target.__copied = true;
      doGenerateGlobalId(target);
      items.splice(itemIndex + 1, 0, target);
      isCopied = true;
      continue;
    }
    if(source.type === SheetUtils.GROUP) {
      doCopySubSheetItem(source.items);
    }
  }
  if(isCopied) {
    let number = 1;
    for(let item of items) {
      item.number = number ++;
    }
  }
}

function doCopySubSheetItem(items) {
  let isCopied = false;
  for(let itemIndex = 0; itemIndex < items.length; itemIndex ++) {
    let source = items[itemIndex];
    if(source.__copy) {
      delete source.__copy;
      let target = Utils.deepCopy(source);
      target.__copied = true;
      doGenerateGlobalId(target);
      items.splice(itemIndex + 1, 0, target);
      isCopied = true;
    }
  }
  if(isCopied) {
    let number = 1;
    for(let item of items) {
      item.number = number ++;
    }
  }
}

export default {
  name: "SheetItemCopyHelper",
  mixins: [SheetItemCoordinateHelper],
  methods: {
    copySheetItems(sheetList, keys) {
      let candidates = this.getCoordinatesByKeys(keys, sheetList);
      for (let coordinate of candidates) {
        switch (coordinate.type) {
          case this.CoordinateTypes.SHEET:
            doMarkSheetByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.PAGE:
            doMarkPageByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.SHEET_ITEM:
            doMarkSheetItemByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.SUB_SHEET_ITEM:
            doMarkSubSheetItemByCoordinate(sheetList, coordinate);
            break;
        }
      }
      doCopySheet(sheetList);

      let copiedKeys = new Array();
      for(let sheet of sheetList) {
        if(sheet.__copied) {
          delete sheet.__copied;
          copiedKeys.push(this.keyOfSheet(sheet));
          continue;
        }

        for(let page of sheet.pages) {
          if(page.__copied) {
            delete page.__copied;
            copiedKeys.push(this.keyOfSheetPage(page));
            continue;
          }

          for(let sheetItem of page.sheetItems) {
            if(sheetItem.__copied) {
              delete sheetItem.__copied;
              copiedKeys.push(this.keyOfSheetItem(sheetItem));
              continue;
            }
            if(sheetItem.type === SheetUtils.GROUP) {
              for(let subSheetItem of sheetItem.items) {
                if(subSheetItem.__copied) {
                  delete subSheetItem.__copied;
                  copiedKeys.push(this.keyOfSheetItem(subSheetItem));
                }
              }
            }
          }
        }
      }
      return copiedKeys;
    }
  }
}
</script>

<style scoped>

</style>