function sleep(milliseconds) {
    return new Promise((resolve)=>{
        setTimeout(resolve, milliseconds);
    })
}

async function execteTasksAsync(tasks) {
    while (tasks.length > 0) {
        let task = tasks[0];
        for (let n = tasks.length - 1; n >= 1; n--) {
            let tmp = tasks[n];
            if (tmp.tag === task.tag && tmp.params === task.params && tmp.runFunc === task.runFunc) {
                tasks.splice(n, 1);
            }
        }
        await task.runFunc(task.params);
        tasks.splice(0, 1);
        await sleep(500);
    }
}

const AutosubmitHelper = {
    data() {
        return {
            tasks: [],
            isExecuting: false,
        }
    },
    activated() {
        this.isExecuting = false;
        this.tasks = [];
    },
    methods: {
        /**
         * 创建任务
         * @param tag 任务id number / string
         * @param params runFunc函数的参数
         * @param runFunc 执行参数
         */
        createTask({tag, params, runFunc}) {
            let isDuplicated = false;
            for (let n = 1; n < this.tasks.len; n++) {
                let task = this.tasks[n];
                if (task.tag === tag && task.params === params && task.runFunc === runFunc) {
                    isDuplicated = true;
                    break;
                }
            }
            if (!isDuplicated) {
                this.tasks.push({
                    tag,
                    params,
                    runFunc
                });
            }
            this.execute();
        },

        execute() {
            if (this.isExecuting) return;
            this.isExecuting = true;
            execteTasksAsync(this.tasks)
                .then(() => {
                    this.isExecuting = false;
                })
                .catch((res) => {
                    this.isExecuting = false;
                    throw new Error(res);
                })
        }
    }
};

export default AutosubmitHelper;