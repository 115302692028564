<template>
  <div class="page layout-vertical" v-if="questionnaire">
    <div class="padding-horizontal-large padding-vertical layout-horizontal font-size-medium layout-inflexible">
      <span class="clickable font-color-primary" @click="$router.back()">问卷列表</span>
      <span class="margin-left-small"> / {{ questionnaire.title }}</span>
    </div>
    <div class="layout-vertical layout-center layout-flexible" style="overflow: hidden;">
      <div style="height: 100%;">
        <div style="height: 88px;">
          <ys-input
              class="input-title font-weight-bold"
              v-model="questionnaire.title"
              size="extra-large"
              placeholder="请输入问卷标题"
              :maxlength="QuestionnaireUtils.TITLE_MAX_LENGTH"
              @input="handleTitleInput"
          ></ys-input>
          <ys-button
              class="margin-left"
              @click="clickEditSheet"
              lighting
          >
            <span class="fas fa-pen-alt"></span>
            <span class="margin-left-small">编辑表单</span>
          </ys-button>
        </div>
        <sheet-container
            class="layout-flexible"
            style="height: calc(100% - 88px);"
            :sheet-list="sheetList"
            :sheet-record-list="sheetRecordList"
            mode="view"
        ></sheet-container>
      </div>
    </div>
    <sheet-edit-dialog
        :visible.sync="sheetEditDialogVisible"
        :sheet-list="sheetList"
        @change="handleSheetListChange"
    ></sheet-edit-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import SheetContainer from "@/components/sheet/SheetContainer";
import SheetEditDialog from "@/components/sheet/SheetEditDialog";
import TextUtils from "@/assets/javascript/text-utils";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import SheetUtils from "@/assets/javascript/sheet-utils";
import QuestionnaireUtils from "@/assets/javascript/questionnaire-utils";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import SheetChecker from "@/components/sheet/basic/SheetChecker";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";
import YsButton from "@/components/wedigets/YsButton";


export default {
  name: "Questionnaire",
  components: {YsButton, SheetEditDialog, SheetContainer, YsInput},
  mixins: [httpapi, AutosubmitHelper, SheetChecker],
  data() {
    return {
      QuestionnaireUtils: QuestionnaireUtils,

      organizationId: null,

      questionnaireId: null,

      questionnaire: null,

      sheetList: [],

      sheetRecordList: [],

      sheetEditDialogVisible: false,
    }
  },
  activated() {
    this.loadingCode++;
    this.operations = [];
    this.questionnaireId = this.$route.query.id;
    if (this.questionnaireId) {
      this.loadQuestionnaire();
    } else {
      this.organizationId = OrganizationUtils.$getOrganization().id;
      this.questionnaireId = null;
      let questionnaire = QuestionnaireUtils.defaultQuestionnaire();
      questionnaire.title = '未命名问卷-1';
      let sheetList = [SheetUtils.defaultSheet()];
      sheetList[0].name = '未命名表单';
      sheetList[0].pages.push(SheetUtils.defaultSheetPage());
      sheetList[0].pages[0].number = 1;
      this.questionnaire = questionnaire;
      this.sheetList = sheetList;
    }

  },
  deactivated() {
    this.sheetEditDialogVisible = false;
  },
  methods: {
    handleTitleInput: function (title) {
      if (TextUtils.isBlank(title)) {
        this.questionnaire.title = "未命名";
      }
      this.saveQuestionnaire();
    },
    handleSheetListChange: function (sheetList) {
      this.sheetList = sheetList;
      /*let sheetRecordList = [];
      for (let sheet of sheetList) {
        sheetRecordList.push(SheetRecordUtils.defaultSheetRecord(sheet));
      }
      this.sheetRecordList = sheetRecordList;*/
      if (TextUtils.isBlank(this.questionnaire.title)) {
        this.questionnaire.title = "未命名";
      }
      this.saveQuestionnaire();
    },
    clickEditSheet: function () {
      this.sheetEditDialogVisible = true;
    },
    loadQuestionnaire: function () {
      this.$reqGet({
        path: `/questionnaire/${this.questionnaireId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.questionnaire = res.data;
            let sheetList = [];
            let sheetRecordList = [];
            for (let sheetRecord of this.questionnaire.sheetRecordList) {
              sheetRecordList.push(sheetRecord);
              sheetList.push(sheetRecord.sheet);
              delete sheetRecord.sheet;
            }
            this.sheetList = sheetList;
            this.sheetRecordList = sheetRecordList;
          })
          .catch(() => {
            this.$message.error('加载失败');
          });
    },
    saveQuestionnaire() {
      if (!this.checkSheets(this.sheetList, true)) {
        this.createTask({
          tag: 'submit',
          runFunc: this.submitQuestionnaire,
        })
      }
    },
    async submitQuestionnaire() {
      if (this.questionnaire.id) {
        await this.editQuestionnaire();
      } else {
        await this.addQuestionnaire();
      }
    },
    async addQuestionnaire() {
      await this.$reqPostJSON({
        path: `/questionnaire/${this.organizationId}/${encodeURIComponent(this.questionnaire.title)}`,
        data: {
          sheets: this.sheetList,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.questionnaire.id = res.data.id;
            for (let n = 0; n < this.sheetList.length; n++) {
              this.sheetList[n].id = res.data.sheetRecordList[n].id;
            }
            window.eventBus.$emit('notify:questionnaire_add', this.questionnaire.id);
          })
          .catch(() => {
            this.$message.error('保存失败');
          })
          .promise();
    },
    async editQuestionnaire() {
      await this.$reqPut({
        path: `/questionnaire/${this.questionnaire.id}/${encodeURIComponent(this.questionnaire.title)}`,
        data: {
          sheets: this.sheetList,
        }
      })
          .returnWhenCodeMatches()
          .catch(() => {
            this.$message.error('保存失败');
          })
          .promise();
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.input-title {
  width: 600px;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.btn-edit-sheet {
  padding: 4px 8px;
  border-radius: 4px;
  color: #f091a6;
  cursor: pointer;
  user-select: none;
  width: max-content;
}

.btn-edit-sheet:hover {
  background-color: #f1f1f1;
}

</style>