<template>
  <div class="sheet-item sheet-item__time" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} 请选择时间</div>
    <div class="title layout-horizontal layout-middle padding-left-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%"
            @blur="handleTitleBlur"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-inflexible layout-horizontal layout-middle" v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <ys-time-picker
              format="HH:mm"
              :disabled="mode === ViewMode.MODE_EDIT"
              :time="sheetItemRecord.time"
              :picker-options="{selectableRange: selectableRange}"
              @pick="handleTimePicked"
          >
            <div class="time-picked layout-horizontal layout-middle layout-middle padding-horizontal-large"
                 :class="{'is-disabled': mode === ViewMode.MODE_EDIT}" slot="reference">
              <span v-if="sheetItemRecord && sheetItemRecord.time"
                    class="font-color-normal">{{ sheetItemRecord.time }}</span>
              <span class="font-color-placeholder" v-else>请选择时间</span>
              <i class="fa fa-angle-right margin-left-small font-color-placeholder"></i>
            </div>
          </ys-time-picker>
        </template>
        <template v-else>
          <div v-if="sheetItemRecord.time" class="padding-right-large">{{ sheetItemRecord.time }}</div>
          <div v-else class="font-color-placeholder padding-right-large">未填写</div>
        </template>
      </div>
    </div>
    <div v-if="isBigGap" class="padding-horizontal padding-bottom layout-horizontal layout-right">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <ys-time-picker
            format="HH:mm"
            :disabled="mode === ViewMode.MODE_EDIT"
            :time="sheetItemRecord.time"
            :picker-options="{selectableRange: selectableRange}"
            @pick="handleTimePicked"
        >
          <div class="time-picked layout-horizontal layout-middle layout-middle padding-horizontal-large"
               :class="{'is-disabled': mode === ViewMode.MODE_EDIT}" slot="reference">
            <span v-if="sheetItemRecord && sheetItemRecord.time"
                  class="font-color-normal">{{ sheetItemRecord.time }}</span>
            <span class="font-color-placeholder" v-else>请选择时间</span>
            <i class="fa fa-angle-right margin-left-small font-color-placeholder"></i>
          </div>
        </ys-time-picker>
      </template>
      <template v-else>
        <div v-if="sheetItemRecord.time" class="padding-right-large">{{ sheetItemRecord.time }}</div>
        <div v-else class="font-color-placeholder padding-right-large">未填写</div>
      </template>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsTimePicker from "@/components/wedigets/YsTimePicker";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemTimePropertyPanel from "@/components/sheet/panel/SheetItemTimePropertyPanel";
import {TimeUtils} from "@/assets/javascript/time-utils";
import DateTimeLimit from "@/assets/javascript/datetime-limit";

export default {
  name: "SheetItemTime",
  mixins: [SheetItemBasic],
  components: {YsTimePicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      ViewMode,
      SheetUtils,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  computed: {
    selectableRange() {
      if (this.sheetItem) {
        switch (this.sheetItem.limit) {
          case DateTimeLimit.FUTURE:
            return TimeUtils.format('HH:mm:00-23:59:59', new Date())
          case DateTimeLimit.PASSED:
            return TimeUtils.format('00:00:00-HH:mm:00', new Date())
          default:
            return null;
        }
      } else {
        return null;
      }
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if (this.mode === ViewMode.MODE_VIEW && !this.readOnly) {
          if (!this.sheetItemRecord.time && this.sheetItem.defaultNow) {
            this.sheetItemRecord.time = TimeUtils.format('HH:mm', new Date());
            this.$emit('input', this.item);
          }
        }

      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 80;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemTimePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleTimePicked(time) {
      this.sheetItemRecord.time = time;
      this.$emit('input', this.item);
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 48px;
}

.time-picked {
  height: 48px;
}

.time-picked:hover {
  background-color: #f1f1f1;
}

.time-picked.is-disabled {
  cursor: not-allowed;
}

.text-measurer {
  height: 0px;
  overflow: hidden;
  position: absolute;
}

</style>