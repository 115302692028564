<script>
import SheetItemCoordinateHelper from "@/components/sheet/basic/SheetItemCoordinateHelper";
import SheetUtils from "@/assets/javascript/sheet-utils";


function doMarkSheetByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  sheet.__deleted = true;
}

function doMarkSheetPageByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  if(sheet.pages.length == 1) return;
  sheet.pages[coordinate.page].__deleted = true;
  let number = 1;
  for (let page of sheet.pages) {
    if(!page.__deleted) page.number = number ++;
  }
}

function doMarkSheetItemByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  let page = sheet.pages[coordinate.page];
  page.sheetItems[coordinate.item].__deleted = true;
  let number = 1;
  for(let sheetItem of page.sheetItems) {
    if(!sheetItem.__deleted) sheetItem.number = number ++;
  }
}

function doMarkSubSheetItemByCoordinate(sheetList, coordinate) {
  let sheet = sheetList[coordinate.sheet];
  let page = sheet.pages[coordinate.page];
  let itemGroup = page.sheetItems[coordinate.item];
  itemGroup.items[coordinate.s_item].__deleted = true;
  let number = 1;
  for(let sheetItem of itemGroup.items) {
    if(!sheetItem.__deleted) sheetItem.number = number ++;
  }
}

function doDeleteMarkedSheetItems(sheetList) {
  for(let sheetIndex = sheetList.length - 1; sheetIndex >= 0; sheetIndex --) {
    let sheet = sheetList[sheetIndex];
    if(sheet.__deleted) {
      sheetList.splice(sheetIndex, 1);
      continue;
    }
    for(let pageIndex = sheet.pages.length - 1; pageIndex >= 0; pageIndex --) {
      let page = sheet.pages[pageIndex];
      if(page.__deleted) {
        sheet.pages.splice(pageIndex, 1);
        continue;
      }

      for(let itemIndex = page.sheetItems.length - 1; itemIndex >= 0; itemIndex --) {
        let sheetItem = page.sheetItems[itemIndex];
        if(sheetItem.__deleted) {
          page.sheetItems.splice(itemIndex, 1);
          continue;
        }

        if(sheetItem.type === SheetUtils.GROUP) {
          for(let s_itemIndex = sheetItem.items.length - 1; s_itemIndex >= 0; s_itemIndex --) {
            let subSheetItem = sheetItem.items[s_itemIndex];
            if(subSheetItem.__deleted) sheetItem.items.splice(s_itemIndex, 1);
          }
        }
      }
    }
  }
}

export default {
  mixins: [SheetItemCoordinateHelper],
  methods: {
    deleteSheetItems(sheetList, keys) {
      let candidates = this.getCoordinatesByKeys(keys, sheetList);

      for (let coordinate of candidates) {
        switch (coordinate.type) {
          case this.CoordinateTypes.SHEET:
            doMarkSheetByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.PAGE:
            doMarkSheetPageByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.SHEET_ITEM:
            doMarkSheetItemByCoordinate(sheetList, coordinate);
            break;
          case this.CoordinateTypes.SUB_SHEET_ITEM:
            doMarkSubSheetItemByCoordinate(sheetList, coordinate);
            break;
        }
      }
      doDeleteMarkedSheetItems(sheetList);
    }
  }
}
</script>

<style scoped>

</style>