<template>
  <ys-dialog class="sheet-item-type-dialog" title="表单类型" :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div class="padding padding-small">选择要创建的表单项类型：</div>
    <div class="margin-horizontal">基本类型：</div>
    <div class="layout-horizontal padding-horizontal margin-top-small">
      <div class="sheet-item-type sheet-item-type__primary layout-vertical layout-center margin-horizontal-small"
        @click="handleSheetItemTypeClick(SheetUtils.STRING)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-font font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">文本输入</div>
      </div>
      <div class="sheet-item-type sheet-item-type__primary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.NUMBER)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-ruler font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">数值输入</div>
      </div>
      <div class="sheet-item-type sheet-item-type__primary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.CHOICE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-check-circle font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">选择题</div>
      </div>
      <div class="sheet-item-type sheet-item-type__primary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.ILLUSTRATION)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-italic font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">说明文字</div>
      </div>
      <div class="sheet-item-type sheet-item-type__primary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.GROUP)" v-if="!disableGroupType">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="far fa-object-group font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">分组</div>
      </div>
    </div>
    <div class="margin-horizontal margin-top-small">日期时间：</div>
    <div class="layout-horizontal padding-horizontal margin-top-small">
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.DATE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="far fa-calendar-check font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">日期</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.TIME)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-clock font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">时间</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small" style="width: 72px;"
           @click="handleSheetItemTypeClick(SheetUtils.DATETIME)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-calendar-day font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">日期+时间</div>
      </div>
    </div>
    <div class="margin-horizontal margin-top-small">更多类型：</div>
    <div class="layout-horizontal padding-horizontal margin-top-small">
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.GENDER)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-venus-mars font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">性别</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.ADDRESS)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-map-marked-alt font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">地址</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.PHONE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-mobile font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">手机号码</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.IMAGE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-camera font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">照片</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.FILE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-file font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">文件</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.CERTIFICATE)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-ad font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">身份证</div>
      </div>
      <div class="sheet-item-type sheet-item-type__secondary layout-vertical layout-center margin-horizontal-small"
           @click="handleSheetItemTypeClick(SheetUtils.ETHNICITY)">
        <div class="layout-flexible layout-vertical layout-center layout-middle">
          <i class="fas fa-globe-asia font-size-extra-large"></i>
        </div>
        <div class="sheet-item-type__title font-size-extra-small layout-self-stretch">民族</div>
      </div>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import SheetUtils from "@/assets/javascript/sheet-utils";

export default {
  name: "SheetItemTypesDialog",
  components: { YsDialog},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    disableGroupType: Boolean,
  },
  data() {
    return {
      isVisible: false,
      SheetUtils,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
      },
      immediate: true,
    }
  },
  methods: {
    handleSheetItemTypeClick(sheetItemType) {
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', sheetItemType);
    }
  }
}
</script>

<style scoped>

.sheet-item-type {
  border-radius: 4px;
  border: 1px solid #dadada;
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  color: #686868;
}

.sheet-item-type:hover {
  opacity: 0.8;
  box-shadow: 0 0 4px #bec0c0;
}

.sheet-item-type__primary {
  width: 64px;
  height: 72px;
}

.sheet-item-type__secondary {
  width: 64px;
  height: 64px;
}

.sheet-item-type__title {
  text-align: center;
  background-color: #f1f1f1;
  padding: 4px 8px;
}

</style>