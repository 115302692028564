<template>
  <div class="sheet-page-property padding" v-if="page">
    <div class="font-size-small"><span class="font-color-primary font-weight-bold"><i class="far fa-file"></i> 表单页</span></div>
    <div class="margin-top layout-horizontal">
      <div class="layout-inflexible">名称：</div>
      <ys-textarea :maxlength="SheetUtils.SHEET_NAME_MAX_LENGTH" placeholder="请输入页面名称（选填）" v-model="page.name" @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <div class="layout-inflexible">说明：</div>
      <ys-textarea :maxlength="SheetUtils.SHEET_MESSAGE_MAX_LENGTH" placeholder="请输入页面说明（选填）" v-model="page.message" @input="handleInput"></ys-textarea>
    </div>
  </div>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import SheetUtils from "@/assets/javascript/sheet-utils";

export default {
  name: "SheetPagePropertyPanel",
  components: {YsTextarea},
  props: {
    page: Object,
  },
  data() {
    return {
      SheetUtils,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.page);
    }
  }
}
</script>

<style scoped>

</style>