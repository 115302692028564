<template>
  <div class="sheet-item sheet-item__certificate" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{sheetItem.title}} 12345678901234567890</div>
    <div class="title layout-horizontal layout-middle padding-left-large padding-right-small">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div :class="mode === ViewMode.MODE_EDIT ? 'layout-flexible' : 'layout-inflexible'">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%"
            @blur="handleTitleBlur"
        ></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-flexible font-align-right" v-if="!isBigGap">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="placeholder"
            placeholder="双击此处添加占位文字"
            :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
            class="font-color-placeholder"
            style="width: 100%; text-align: right !important;"
            @blur="handlePlaceholderBlur"
        ></ys-click-to-edit>
        <ys-input
            v-else-if="!readOnly"
            :show-background="false"
            :placeholder="placeholder"
            :maxlength="SheetUtils.CERTIFICATE_MAX_LENGTH"
            style="width: 100%; text-align: right !important;"
            v-model="certificateInputted"
            @input="handleCertificateInput"
        ></ys-input>
        <span v-else-if="sheetItemRecord.certificate" class="padding-right-large">{{sheetItemRecord.certificate.number}}</span>
        <span v-else class="font-color-placeholder padding-right-large">未填写</span>
      </div>
    </div>
    <div class="padding-left-large padding-bottom-large" v-if="isBigGap">
      <ys-click-to-edit
          v-if="mode === ViewMode.MODE_EDIT"
          type="textarea"
          v-model="placeholder"
          placeholder="双击此处添加占位文字"
          :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
          class="font-color-placeholder"
          style="width: 100%; text-align: right !important;"
          @blur="handlePlaceholderBlur"
      ></ys-click-to-edit>
      <ys-input
          v-else-if="!readOnly"
          :show-background="false"
          :placeholder="placeholder"
          :maxlength="SheetUtils.CERTIFICATE_MAX_LENGTH"
          style="width: 100%; text-align: right !important;"
          v-model="certificateInputted"
          @input="handleCertificateInput"
      ></ys-input>
      <span v-else-if="sheetItemRecord.certificate" class="padding-right-large">{{sheetItemRecord.certificate.number}}</span>
      <span v-else class="font-color-placeholder padding-right-large">未填写</span>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsInput from "@/components/wedigets/YsInput";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemCertificatePropertyPanel from "@/components/sheet/panel/SheetItemCertificatePropertyPanel";
import TextUtils from "@/assets/javascript/text-utils";
import CertificateCard from "@/assets/javascript/certificate-card";

export default {
  name: "SheetItemCertificate",
  mixins: [SheetItemBasic],
  components: {YsInput, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      placeholder: null,
      ViewMode,
      SheetUtils,
      isBigGap: false,
      propertyPanel: null,

      certificateInputted: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        this.placeholder = this.sheetItem.placeholder;
        if(this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;

        if(this.sheetItemRecord && this.sheetItemRecord.certificate) {
          this.certificateInputted = this.sheetItemRecord.certificate.number;
        }
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePlaceholderBlur() {
      this.sheetItem.placeholder = this.placeholder;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 80;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.placeholder = this.sheetItem.placeholder;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleCertificateInput() {
      if(this.sheetItemRecord) {
        if(TextUtils.isBlank(this.certificateInputted)) {
          this.sheetItemRecord.certificate = null;
        } else {
          if(this.sheetItemRecord.certificate) {
            this.sheetItemRecord.certificate.number = this.certificateInputted;
          } else {
            this.sheetItemRecord.certificate = CertificateCard.defaultCertificate();
            this.sheetItemRecord.certificate.number = this.certificateInputted;
          }
        }
        this.$emit('input', this.item);
      }
    },
    setSelected() {
      if(this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if(propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemCertificatePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.text-measurer {
  position: absolute;
  height: 0px;
  overflow: hidden;
}

</style>