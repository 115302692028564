<template>
  <div class="sheet-item sheet-item__choice" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="title padding-horizontal-large padding-vertical layout-horizontal">
      <span v-if="!sheetItem.optional" class="font-color-danger layout-inflexible">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit
            v-model="sheetItem.title"
            v-if="mode === ViewMode.MODE_EDIT"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            placeholder="双击此处输入标题" type="textarea"
            style="width: 100%;"
            @blur="handleTitleBlur"></ys-click-to-edit>
        <span v-else>{{ sheetItem.title }}</span>
      </div>
      <span v-if="sheetItem.multiple" class="font-weight-bold font-color-secondary layout-inflexible">(多选)</span>
    </div>
    <div class="choice-panel layout-vertical padding-horizontal-large padding-bottom-large">
      <ys-movable @move="handleChoiceItemMove" v-if="mode === ViewMode.MODE_EDIT">
        <ys-movable-item v-for="(choiceItem, index) in sheetItem.items" :key="choiceItem.guid"
                         :disabled="choiceItem.editMode === 'edit'" :tag="index">
          <div class="choice-item" ref="choiceItems" @dblclick="handleChoiceItemDbClick(choiceItem, index)">
            <ys-click-to-edit
                v-model="choiceItem.value"
                v-if="mode === ViewMode.MODE_EDIT"
                placeholder="双击此处修改选项"
                type="textarea"
                :mode.sync="choiceItem.editMode"
                @blur="handleChoiceItemBlur"
            ></ys-click-to-edit>
            <span v-else>{{ choiceItem.value }}</span>
          </div>
        </ys-movable-item>
        <transition name="el-fade-in">
          <ys-movable-item disabled v-if="sheetItem.withOthers">
            <div class="choice-item layout-self-left">
              其他
            </div>
          </ys-movable-item>
        </transition>
      </ys-movable>
      <div v-else class="layout-vertical layout-left padding-bottom-large">
        <div class="choice-item"
             :class="{
                'is-active': choiceItem.__selected,
                'is-writable': !readOnly,
             }"
             ref="choiceItems"
             v-for="(choiceItem) in sheetItem.items"
             :key="choiceItem.guid"
             v-show="choiceItem.__selected || !readOnly || showAllChoiceItems"
             @click="handleChoiceItemClick(choiceItem)">
          <span v-if="choiceItem.score && readOnly"
                class="margin-right-small">({{ ExactNumber.stringify(choiceItem.score) }}")</span>
          <span>{{ choiceItem.value }}</span>
        </div>
        <div
            class="choice-item is-active"
            :class="{
              'is-writable': !readOnly
            }"
            v-for="otherItem in sheetItemRecord.selection.others"
            :key="otherItem"
            @click="handleOtherItemClick(otherItem)"
        >
          <span>{{ otherItem }}</span>
          <span v-if="!readOnly" class="btn-delete-other margin-left-small font-size-extra-small"><i
              class="el-icon-close"></i></span>
        </div>
        <div
            class="choice-item"
            v-if="sheetItem.withOthers && !readOnly"
            @click="handleOtherItemClick()"
        >
          <span><i class="fas fa-plus-circle"></i> 其他</span>
        </div>
        <el-collapse-transition>
          <ys-textarea ref="otherInput"
                       class="margin-top"
                       placeholder="其他选项"
                       :maxlength="80"
                       v-if="showOtherInput"
                       v-model="otherItemInputted"
                       @blur="handleOtherItemInput"
                       @keyup.enter.native="handleOtherItemInput"
          ></ys-textarea>
        </el-collapse-transition>
        <div
            v-if="readOnly && !showAllChoiceItems && sheetItemRecord.selection.selectedItems.length + sheetItemRecord.selection.others.length == 0">
          <div class="choice-item">未选择</div>
        </div>

        <div class="btn-show-all-choices font-color-placeholder margin-top" v-if="readOnly && !showAllChoiceItems"
             @click="showAllChoiceItems = true">显示全部选项
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <choice-item-edit-dialog v-if="choiceItemEditDialogVisible" :visible.sync="choiceItemEditDialogVisible"
                               :value="currentChoiceItem" @input="handleChoiceItemInput"></choice-item-edit-dialog>
    </transition>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsMovable from "@/components/wedigets/YsMovable";
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemChoicePropertyPanel from "@/components/sheet/panel/SheetItemChoicePropertyPanel";
import ChoiceItemEditDialog from "@/components/sheet/dialog/ChoiceItemEditDialog";
import YsTextarea from "@/components/wedigets/YsTextarea";
import TextUtils from "@/assets/javascript/text-utils";

export default {
  name: "SheetItemChoice",
  mixins: [SheetItemBasic],
  components: {YsTextarea, ChoiceItemEditDialog, YsMovableItem, YsMovable, YsClickToEdit},
  props: {
    item: Object,
    mode: String,
    isBigGap: {
      type: Boolean,
      default: true,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      ExactNumber,
      sheetItem: null,
      sheetItemRecord: null,
      SheetUtils: SheetUtils,
      isMultiline: true,
      ViewMode: ViewMode,
      propertyPanel: null,
      currentChoiceItem: null,
      currentChoiceItemIndex: null,
      choiceItemEditDialogVisible: false,
      showAllChoiceItems: false,
      selectedCount: 0,

      showOtherInput: false,
      otherItemInputted: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.$emit('update:is-big-gap', true);
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
        this.refreshSelectionState();
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.$emit('input', this.item);
    },
    handleChoiceItemBlur() {
      this.$emit('input', this.item);
    },
    handleChoiceItemDbClick(choiceItem, index) {
      this.currentChoiceItem = choiceItem;
      this.currentChoiceItemIndex = index;
      this.choiceItemEditDialogVisible = true;
    },
    handleChoiceItemInput(choiceItem) {
      this.sheetItem.items.splice(this.currentChoiceItemIndex, 1, choiceItem);
      this.handleInput();
    },
    handleChoiceItemMove(evt) {
      let direct = evt.direct;
      let from = evt.from;
      let to = evt.to;
      if (from < to) {
        to--;
      }
      if (direct === 'bottom') {
        to++;
      }
      let choiceItem = this.sheetItem.items[from];
      this.sheetItem.items.splice(from, 1);
      this.sheetItem.items.splice(to, 0, choiceItem);
      this.handleInput();
    },
    handleInput() {
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleChoiceItemClick(choiceItem) {
      if (this.readOnly) return;
      let selectedItemIndex = this.getSelectedItemIndex(choiceItem);
      if (selectedItemIndex >= 0) {
        this.sheetItemRecord.selection.selectedItems.splice(selectedItemIndex, 1);
      } else {
        if (!this.sheetItem.multiple || choiceItem.rejective) {
          if (this.sheetItemRecord.selection.others.length > 0) {
            this.sheetItemRecord.selection.others = [];
          }
          if (this.sheetItemRecord.selection.selectedItems.length > 0) {
            this.sheetItemRecord.selection.selectedItems = [];
          }
        }
        this.unselectRejectiveChoiceItems();
        this.sheetItemRecord.selection.selectedItems.push({
          number: choiceItem.number,
          name: choiceItem.value,
          image: choiceItem.image,
        });
      }
      this.refreshSelectionState();
      this.$emit('input', this.item);
    },
    handleOtherItemClick(other) {
      if (!other) {
        if (!this.showOtherInput) {
          this.showOtherInput = true;
          this.otherItemInputted = null;
          this.$nextTick(() => this.$refs.otherInput.focus());
        }
      } else {
        for (let n = this.sheetItemRecord.selection.others.length - 1; n >= 0; n--) {
          if (this.sheetItemRecord.selection.others[n] === other) {
            this.sheetItemRecord.selection.others.splice(n, 1);
            break;
          }
        }
        this.refreshSelectionState();
        this.$emit('input', this.item);
      }
    },
    handleOtherItemInput() {
      this.showOtherInput = false;

      let otherItemInputted = this.otherItemInputted;
      this.otherItemInputted = null;
      otherItemInputted = otherItemInputted.trim();
      if (TextUtils.isBlank(otherItemInputted)) return;

      let choiceItem = null;
      for (let item of this.sheetItem.items) {
        if (item.value === otherItemInputted) {
          choiceItem = item;
          break;
        }
      }

      let isDuplicated = false;
      if (!choiceItem) {
        for (let other of this.sheetItemRecord.selection.others) {
          if (other === otherItemInputted) {
            isDuplicated = true;
            break;
          }
        }
      }

      if (isDuplicated) return;

      if (choiceItem) {
        let selectedIndex = this.getSelectedItemIndex(choiceItem);
        if (selectedIndex < 0) {
          this.handleChoiceItemClick(choiceItem);
        } else {
          this.$message.warning('已重复选择');
        }
      } else {
        if (!this.sheetItem.multiple) {
          this.sheetItemRecord.selection.selectedItems = [];
          this.sheetItemRecord.selection.others = [otherItemInputted];
        } else {
          this.sheetItemRecord.selection.others.push(otherItemInputted);
          this.unselectRejectiveChoiceItems();
        }
      }
      this.refreshSelectionState();
      this.$emit('input', this.item);
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemChoicePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    refreshSelectionState() {
      if (!this.sheetItemRecord) return;
      for (let choiceItem of this.sheetItem.items) {
        let selected = false;
        for (let selectedItem of this.sheetItemRecord.selection.selectedItems) {
          if (selectedItem.number === choiceItem.number) {
            selected = true;
            break;
          }
        }
        choiceItem.__selected = selected;
      }
      this.$forceUpdate();
    },
    unselectRejectiveChoiceItems() {
      for (let n = this.sheetItemRecord.selection.selectedItems.length - 1; n >= 0; n--) {
        let selectedItem = this.sheetItemRecord.selection.selectedItems[n];
        let isRejective = false;
        for (let item of this.sheetItem.items) {
          if (!item.rejective) continue;
          if (item.number === selectedItem.number) {
            isRejective = true;
            break;
          }
        }
        if (isRejective) this.sheetItemRecord.selection.selectedItems.splice(n, 1);
      }
    },
    getSelectedItemIndex(choiceItem) {
      for (let n = 0; n < this.sheetItemRecord.selection.selectedItems.length; n++) {
        if (this.sheetItemRecord.selection.selectedItems[n].number === choiceItem.number) {
          return n;
        }
      }
      return -1;
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.choice-item {
  background-color: #f1f1f1;
  border-radius: 32px;
  padding: 6px 12px;
  margin: 5px 0px;
  cursor: pointer;
}

.choice-item.is-active {
  background-color: #f091a6;
  color: white;
  box-shadow: 0 0 4px #f091a6;
}

.choice-item.is-writable:hover {
  filter: brightness(90%);
}

.sheet-item .btn-show-all-choices {
  opacity: 0;
}

.sheet-item:hover .btn-show-all-choices {
  opacity: 1;
}

.choice-item .btn-delete-other {
  visibility: hidden;
}

.choice-item:hover .btn-delete-other {
  visibility: visible;
}

</style>