var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ys-number-wrapper"},[_c('el-input',_vm._b({class:[
        'ys-number',
        _vm.size ? 'ys-number--size-' + _vm.size : '',
        {
          'is-focused': _vm.focused,
          'is-disabled': _vm.disabled,
          'is-dark': _vm.dark,
          'is-hollow': _vm.hollow,
          'is-hide-background': !_vm.showBackground,
        }
    ],attrs:{"tabindex":_vm.tabindex,"type":"string","disabled":_vm.disabled,"readonly":_vm.readonly,"auto-complete":_vm.autocomplete,"label":_vm.label,"prefix-icon":_vm.prefixIcon,"suffix-icon":_vm.suffixIcon},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'el-input',_vm.$attrs,false),[_vm._t("prefix",null,{"slot":"prefix"}),_vm._t("suffix",null,{"slot":"suffix"}),_vm._t("append",null,{"slot":"append"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }