<template>
  <div class="sheet-item sheet-item__file" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="title layout-horizontal layout-middle padding-horizontal-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit
            v-if="mode === ViewMode.MODE_EDIT"
            type="textarea"
            v-model="title"
            placeholder="双击此处添加标题"
            :maxlength="SheetUtils.TITLE_MAX_LENGTH"
            style="width: 100%"
            @blur="handleTitleBlur"
        ></ys-click-to-edit>
        <span v-else>
          {{ title }}
        </span>
      </div>
    </div>
    <div class="padding-horizontal-large padding-bottom">
      <template v-if="mode === ViewMode.MODE_VIEW && !readOnly">
        <ys-file-picker
            v-for="(file, index) in sheetItemRecord.resources"
            :key="file.guid"
            :file.sync="sheetItemRecord.resources[index]"
            class="margin-top"
            @update:file="handleFileUpdate"
            @delete="handleFileDelete(index)"
        >
        </ys-file-picker>
      </template>
      <template v-else-if="mode === ViewMode.MODE_VIEW">
        <div class="file-item layout-horizontal" v-for="(file, index) in sheetItemRecord.resources" :key="file.id"
             @click="handleFileClick(file)">
          <div class="file-icon">
            <ys-file-icon :file="file"
                          v-if="FileResourceUtils.fileTypeOf(file.name) !== FileTypes.IMAGE"></ys-file-icon>
            <el-image class="image-thumbnail" v-else :src="$prepareImageUrl(file)" fit="cover"></el-image>
          </div>
          <div class="margin-left layout-flexible" style="overflow: hidden">
            <div class="font-size-medium font-nowrap">{{ file.name }}</div>
            <div class="font-color-secondary margin-top-small">{{ FileResourceUtils.stringifyFileLength(file) }}</div>
          </div>
          <div class="operations layout-inflexible padding-horizontal">
            <ys-button type="secondary" size="small" lighting round>
              <i class="fas fa-download"></i>
            </ys-button>
          </div>
        </div>
      </template>
      <ys-file-picker
          only-pick
          @update:file="handleFilePicked"
          class="margin-top"
          v-if="mode === ViewMode.MODE_VIEW && !readOnly && sheetItemRecord.resources.length < sheetItem.maxCount"
      >
      </ys-file-picker>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import FileResourceUtils, {FileTypes} from "@/assets/javascript/file-resource-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import GuidUtils from "@/assets/javascript/guid-utils";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemFilePropertyPanel from "@/components/sheet/panel/SheetItemFilePropertyPanel";
import YsFilePicker from "@/components/wedigets/YsFilePicker";
import YsFileIcon from "@/components/wedigets/YsFileIcon";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "SheetItemFile",
  mixins: [httpapi, SheetItemBasic],
  components: {YsButton, YsFileIcon, YsFilePicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  computed: {
    simulatedFileList() {
      let result = new Array();
      for (let n = 0; n < this.sheetItem.maxCount; n++) {
        result.push({
          id: GuidUtils.guid(),
        })
      }
      return result;
    }
  },
  data() {
    return {
      FileResourceUtils,
      FileTypes,

      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      ViewMode,
      SheetUtils,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        if (this.sheetItemRecord) {
          for (let file of this.sheetItemRecord.resources) {
            if (!file.guid) file.guid = GuidUtils.guid();
          }
        }
        this.title = this.sheetItem.title;
        this.$emit('update:is-big-gap', true);
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    },
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleFilePicked(file) {
      if (this.sheetItemRecord) {
        if (this.sheetItemRecord.resources.length < this.sheetItem.maxCount) {
          this.sheetItemRecord.resources.push(file);
          this.$emit('input', this.item);
        }
      }
    },
    handleFileUpdate() {
      this.$emit('input', this.item);
    },
    handleFileDelete(index) {
      this.sheetItemRecord.resources.splice(index, 1);
      this.$emit('input', this.item);
    },
    handleFileClick(file) {
      window.open(this.$prepareImageUrl(file), '_blank')
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemFilePropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.file-item {
  background-color: white;
  border-radius: 8px;
  margin-top: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.file-item:hover {
  box-shadow: 0 0 16px #dedede;
}

.file-icon {
  width: 44px;
  height: 44px;
  background-color: #f091a6;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-thumbnail {
  width: 44px;
  height: 44px;
}

.operations {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.file-item:hover .operations {
  visibility: visible;
}

</style>