<template>
  <div class="sheet-item sheet-item__gender" :class="{'is-selected': selected}">
    <div ref="titleMeasurer" class="title-measurer" v-resize="handleTitleResize">{{ sheetItem.title }}</div>
    <div class="title layout-horizontal padding-left-large layout-middle">
      <span v-if="!sheetItem.optional" class="font-color-danger layout-inflexible">*</span>
      <div class="layout-flexible">
        <ys-click-to-edit v-model="title" placeholder="双击此处添加标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                          v-if="mode === ViewMode.MODE_EDIT" @blur="handleTitleBlur" style="width: 100%;"
                          type="textarea"></ys-click-to-edit>
        <span v-else>{{ title }}</span>

      </div>
      <div class="layout-flexible layout-horizontal layout-middle layout-right layout-self-stretch font-color-secondary"
           v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <div
              class="gender gender-male layout-self-stretch layout-horizontal layout-middle padding-horizontal-large"
              :class="{
                  'is-active': sheetItemRecord && sheetItemRecord.gender === Gender.MALE
              }"
              @click="handleGenderClick(Gender.MALE)"
          >
            <div class="gender-icon-wrap"><i class="fas fa-mars"></i></div>
            <span>男</span>
          </div>
          <div
              class="gender gender-female layout-self-stretch layout-horizontal layout-middle padding-horizontal-large"
              :class="{
                  'is-active': sheetItemRecord && sheetItemRecord.gender === Gender.FEMALE,
              }"
              @click="handleGenderClick(Gender.FEMALE)"
          >
            <div class="gender-icon-wrap"><i class="fas fa-venus"></i></div>
            <span>女</span>
          </div>
        </template>
        <template v-else>
          <div v-if="sheetItemRecord.gender === Gender.MALE" class="padding-right-large font-color-normal">
            <span><i class="fas fa-mars font-color-primary"></i> 男</span>
          </div>
          <div v-else-if="sheetItemRecord.gender === Gender.FEMALE" class="padding-right-large font-color-normal">
            <span><i class="fas fa-venus font-color-danger"></i> 女</span>
          </div>
          <div v-else class="font-color-placeholder padding-right-large">未选择</div>
        </template>
      </div>
    </div>
    <div v-if="isBigGap" class="layout-horizontal layout-right font-color-secondary">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <div
            class="gender gender-male layout-self-stretch layout-horizontal layout-middle padding-horizontal-large"
            :class="{
                  'is-active': sheetItemRecord && sheetItemRecord.gender === Gender.MALE
              }"
            @click="handleGenderClick(Gender.MALE)"
        >
          <div class="gender-icon-wrap"><i class="fas fa-mars"></i></div>
          <span>男</span>
        </div>
        <div
            class="gender gender-female layout-self-stretch layout-horizontal layout-middle padding-horizontal-large"
            :class="{
                  'is-active': sheetItemRecord && sheetItemRecord.gender === Gender.FEMALE,
              }"
            @click="handleGenderClick(Gender.FEMALE)"
        >
          <div class="gender-icon-wrap"><i class="fas fa-venus"></i></div>
          <span>女</span>
        </div>
      </template>
      <template v-else>
        <div v-if="sheetItemRecord.gender === Gender.MALE" class="padding-right-large font-color-normal">
          <span><i class="fas fa-mars font-color-primary"></i> 男</span>
        </div>
        <div v-else-if="sheetItemRecord.gender === Gender.FEMALE" class="padding-right-large font-color-normal">
          <span><i class="fas fa-venus font-color-danger"></i> 女</span>
        </div>
        <div v-else class="font-color-placeholder padding-right-large">未选择</div>
      </template>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import Gender from "@/assets/javascript/gender";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemGenderPropertyPanel from "@/components/sheet/panel/SheetItemGenderPropertyPanel";

export default {
  name: "SheetItemGender",
  mixins: [SheetItemBasic],
  components: {YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      Gender,
      sheetItem: null,
      sheetItemRecord: null,
      ViewMode,
      SheetUtils,
      title: null,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    },
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleTitleResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;

      this.isBigGap = evt.width > itemWidth - 320;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemGenderPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleGenderClick(gender) {
      if (this.mode !== ViewMode.MODE_VIEW) return;
      this.sheetItemRecord.gender = gender;
      this.$emit('input', this.item);
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.gender {
  cursor: pointer;
}

.gender:hover {
  background-color: #f1f1f1;
}

.gender.is-active {
  font-weight: bold;
}

.gender.is-active .gender-icon-wrap {
  background-color: #faf4f5;
}

.gender.gender-male.is-active {
  color: #3a8ee6;
}

.gender.gender-female.is-active {
  color: #f091a6;
}

.gender > .gender-icon-wrap {
  width: 28px;
  height: 28px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.title-measurer {
  height: 0px;
  background-color: #ff5500;
  position: fixed;
  overflow: hidden;
}
</style>