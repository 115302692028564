<template>
  <div class="ys-time-picker" :class="{'is-disabled': disabled}">
    <div @click="handleClick" ref="reference">
      <slot name="reference">
        <div class="reference layout-horizontal layout-middle padding-horizontal">
          <span v-if="time">{{ time }}</span>
          <span class="font-color-placeholder">{{ placeholder }}</span>
        </div>
      </slot>
    </div>
    <div class="time-panel">
      <el-time-picker ref="timePanel" @change="handlePick" v-bind="$attrs" format="HH:mm"
                      v-model="timePicked"></el-time-picker>
    </div>
  </div>
</template>

<script>

import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "YsTimePicker",
  props: {
    time: String,
    placeholder: {
      type: String,
      default: '请选择时间',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      TimeUtils,

      timePicked: null,
    }
  },
  watch: {
    time: {
      handler() {
        this.timePicked = this.time ? TimeUtils.parseDate('2021-08-03 ' + this.time) : new Date();
      },
      immediate: true,
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      if (this.$refs.timePanel.$refs.reference.click) {
        this.$refs.timePanel.$refs.reference.click();
      } else {
        this.$refs.timePanel.$refs.reference.focus();
      }
    },
    handlePick(evt) {
      this.$emit('pick', TimeUtils.format('HH:mm', evt));
    }
  }
}
</script>

<style scoped>

.ys-time-picker {
  cursor: pointer;
}

.ys-time-picker.is-disabled {
  cursor: not-allowed;
}

.reference {
  min-height: 56px;
}

.reference:hover {
  background-color: #f1f1f1;
}

.time-panel {
  width: 0px;
  height: 0px;
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
  position: relative;
  top: -10px;
}

</style>