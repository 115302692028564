<script>

import SheetItemCoordinateHelper from "@/components/sheet/basic/SheetItemCoordinateHelper";
import SheetUtils from "@/assets/javascript/sheet-utils";
import Utils from "@/assets/javascript/utils";
import GuidUtils from "@/assets/javascript/guid-utils";

function doMoveSheet(fromCoordinate, toCoordinate, sheetList) {
  let source = sheetList[fromCoordinate.sheet];
  let newSheet = Utils.deepCopy(source);
  source.__deleted = true;
  sheetList.splice(toCoordinate.sheet + 1, 0, newSheet);
  for (let n = sheetList.length - 1; n >= 0; n--) {
    if (sheetList[n].__deleted) {
      sheetList.splice(n, 1);
      break;
    }
  }
  return newSheet;
}

function doMoveSheetPage(fromCoordinate, toCoordinate, sheetList) {
  let sourceSheet = sheetList[fromCoordinate.sheet];
  let source = sourceSheet.pages[fromCoordinate.page];

  let newPage = Utils.deepCopy(source);
  source.__deleted = true;

  let targetSheet;
  if (toCoordinate.sheet < 0) {
    targetSheet = SheetUtils.defaultSheet();
    targetSheet.globalId = GuidUtils.guid();
    sheetList.splice(toCoordinate.sheet + 1, 0, targetSheet);
  } else {
    targetSheet = sheetList[toCoordinate.sheet];
  }

  targetSheet.pages.splice(toCoordinate.page + 1, 0, newPage);

  for (let n = sourceSheet.pages.length - 1; n >= 0; n--) {
    if (sourceSheet.pages[n].__deleted) {
      sourceSheet.pages.splice(n, 1);
      break;
    }
  }
  let number = 1;
  for (let page of sourceSheet.pages) {
    page.number = number++;
  }

  if (targetSheet != sourceSheet) {
    number = 1;
    for (let page of targetSheet.pages) {
      page.number = number++;
    }
  }
  return newPage;
}

function doMoveSheetItem(fromCoordinate, toCoordinate, sheetList) {
  let sourcePage = sheetList[fromCoordinate.sheet].pages[fromCoordinate.page];
  let sourceSheetItems;
  let source;
  if (fromCoordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
    sourceSheetItems = sourcePage.sheetItems[fromCoordinate.item].items;
    source = sourceSheetItems[fromCoordinate.s_item];
  } else {
    sourceSheetItems = sourcePage.sheetItems;
    source = sourceSheetItems[fromCoordinate.item];
  }

  let newSheetItem = Utils.deepCopy(source);
  source.__deleted = true;

  let targetSheet;
  if (toCoordinate.sheet < 0) {
    targetSheet = SheetUtils.defaultSheet();
    targetSheet.globalId = GuidUtils.guid();
    sheetList.splice(toCoordinate.sheet + 1, 0, targetSheet);
  } else {
    targetSheet = sheetList[toCoordinate.sheet];
  }

  let targetPage;
  if (toCoordinate.page < 0) {
    targetPage = SheetUtils.defaultSheetPage();
    targetPage.globalId = GuidUtils.guid();
    targetSheet.pages.splice(toCoordinate.page + 1, 0, targetPage);
    let number = 1;
    for (let page of targetSheet.pages) {
      page.number = number++;
    }
  } else {
    targetPage = targetSheet.pages[toCoordinate.page];
  }

  let targetSheetItemList;
  if (toCoordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
    let targetGroup;
    if (toCoordinate.item < 0) {
      targetGroup = SheetUtils.defaultItem(SheetUtils.GROUP);
      targetGroup.globalId = GuidUtils.guid();
      targetPage.sheetItems.splice(toCoordinate.item + 1, 0, targetGroup);
      let number = 1;
      for (let item of targetPage.sheetItems) {
        item.number = number++;
      }
    } else {
      targetGroup = targetPage.sheetItems[toCoordinate.item];
    }
    targetSheetItemList = targetGroup.items;
    targetSheetItemList.splice(toCoordinate.s_item + 1, 0, newSheetItem);
  } else {
    targetSheetItemList = targetPage.sheetItems;
    targetSheetItemList.splice(toCoordinate.item + 1, 0, newSheetItem);
  }

  for (let n = sourceSheetItems.length - 1; n >= 0; n--) {
    if (sourceSheetItems[n].__deleted) {
      sourceSheetItems.splice(n, 1);
      break;
    }
  }

  let number = 1;
  for (let item of targetSheetItemList) {
    item.number = number++;
  }

  return newSheetItem;
}

function doNormalizeCoordinate(coordinate, direct, type, sheetList) {
  switch (type) {
    case this.CoordinateTypes.SHEET:
      return doNormalizeSheetCoordinate.bind(this)(coordinate, direct);
    case this.CoordinateTypes.PAGE:
      return doNormalizePageCoordinate.bind(this)(coordinate, direct, sheetList);
    case this.CoordinateTypes.SHEET_ITEM:
    case this.CoordinateTypes.SUB_SHEET_ITEM:
      return doNormalizeSheetItemCoordinate.bind(this)(coordinate, direct, sheetList);
  }
}

function doNormalizeSheetCoordinate(coordinate, direct) {
  switch (direct) {
    case 'top':
      return {
        type: coordinate.type,
        sheet: coordinate.sheet - 1,
      }
    case 'bottom':
      return coordinate;
  }
  return null;
}

function doNormalizePageCoordinate(coordinate, direct, sheetList) {
  if (coordinate.type < this.CoordinateTypes.PAGE) {
    switch (direct) {
      case 'bottom': {
        coordinate = {
          type: this.CoordinateTypes.PAGE,
          sheet: coordinate.sheet,
          page: -1,
        }
        break;
      }
      case 'top': {
        if (coordinate.sheet > 0) {
          let sheet = this.getSheetByCoordinate({
            type: this.CoordinateTypes.SHEET,
            sheet: coordinate.sheet - 1
          }, sheetList);
          coordinate = {
            type: this.CoordinateTypes.PAGE,
            sheet: coordinate.sheet - 1,
            page: sheet.pages.length - 1,
          }
        } else {
          coordinate = {
            type: this.CoordinateTypes.PAGE,
            sheet: coordinate.sheet,
            page: -1,
          }
        }
        direct = 'bottom';
        break;
      }
    }
  }

  switch (direct) {
    case 'top':
      return {
        type: this.CoordinateTypes.PAGE,
        sheet: coordinate.sheet,
        page: coordinate.page - 1,
      };
    case 'bottom':
      return coordinate;
  }
  return null;
}

function doNormalizeSheetItemCoordinate(coordinate, direct, sheetList) {
  if (coordinate.type < this.CoordinateTypes.SHEET_ITEM) {
    if (coordinate.type === this.CoordinateTypes.SHEET) {
      switch (direct) {
        case 'bottom': {
          let sheet = this.getSheetByCoordinate(coordinate, sheetList);
          coordinate = {
            type: this.CoordinateTypes.SHEET_ITEM,
            sheet: coordinate.sheet,
            page: sheet.pages.length > 0 ? 0 : -1,
            item: -1,
          };
          break;
        }
        case 'top': {
          if (coordinate.sheet > 0) {
            let sheet = this.getSheetByCoordinate({
              type: this.CoordinateTypes.SHEET,
              sheet: coordinate.sheet - 1
            }, sheetList);
            coordinate = {
              type: this.CoordinateTypes.SHEET_ITEM,
              sheet: coordinate.sheet - 1,
              page: sheet.pages.length - 1,
              item: sheet.pages.length > 0 ? sheet.pages[sheet.pages.length - 1].sheetItems.length - 1 : -1,
            }
          } else {
            let sheet = this.getSheetByCoordinate(coordinate, sheetList);
            coordinate = {
              type: this.Coordinatetypes.SHEET_ITEM,
              sheet: coordinate.sheet,
              page: sheet.pages.length > 0 ? 0 : -1,
              item: -1,
            }
          }
          direct = 'bottom';
          break;
        }
      }
    } else if (coordinate.type === this.CoordinateTypes.PAGE) {
      switch (direct) {
        case 'bottom':
          coordinate = {
            type: this.CoordinateTypes.SHEET_ITEM,
            sheet: coordinate.sheet,
            page: coordinate.page,
            item: -1,
          }
          break;
        case 'top': {
          if (coordinate.page === 0) {
            coordinate = {
              type: this.CoordinateTypes.SHEET_ITEM,
              sheet: coordinate.sheet,
              page: -1,
              item: -1,
            }
          } else {
            let previousPage = this.getPageByCoordinate({
              type: this.CoordinateTypes.PAGE,
              sheet: coordinate.sheet,
              page: coordinate.page - 1
            }, sheetList);
            coordinate = {
              type: this.CoordinateTypes.SHEET_ITEM,
              sheet: coordinate.sheet,
              page: coordinate.page - 1,
              item: previousPage.sheetItems.length - 1,
            }
          }
          direct = 'bottom';
          break;
        }
      }
    }
  }

  /**
   * 如果在分组后面，则移动到分组的第一项。
   */
  if (coordinate.type === this.CoordinateTypes.SHEET_ITEM) {
    let itemGroup = this.getSheetItemByCoordinate(coordinate, sheetList);
    if (itemGroup && itemGroup.type === SheetUtils.GROUP && itemGroup.items.length === 0) {
      if (direct === 'bottom') {
        coordinate = {
          type: this.CoordinateTypes.SUB_SHEET_ITEM,
          sheet: coordinate.sheet,
          page: coordinate.page,
          item: coordinate.item,
          s_item: -1,
        }
      }
    }
  }

  if (coordinate.type === this.CoordinateTypes.SUB_SHEET_ITEM) {
    switch (direct) {
      case 'top':
        return {
          type: this.CoordinateTypes.SUB_SHEET_ITEM,
          sheet: coordinate.sheet,
          page: coordinate.page,
          item: coordinate.item,
          s_item: coordinate.s_item - 1,
        }
        break;
      case 'bottom':
        return coordinate;
    }
  } else {
    switch (direct) {
      case 'top':
        return {
          type: this.CoordinateTypes.SHEET_ITEM,
          sheet: coordinate.sheet,
          page: coordinate.page,
          item: coordinate.item - 1,
        }
      case 'bottom':
        return coordinate;
    }
  }
}

export default {
  name: "SheetItemMoveHelper",
  mixins: [SheetItemCoordinateHelper],
  methods: {
    moveSheetItem(from, to, direct, sheetList) {
      let fromCoordinate = this.getCoordinateByKey(from, sheetList);
      if (!fromCoordinate) return null;
      let toCoordinate
      if (to === 'end#') {
        switch (fromCoordinate.type) {
          case this.CoordinateTypes.SHEET:
            direct = 'bottom';
            toCoordinate = this.getLastSheet(sheetList);
            if (!toCoordinate) {
              toCoordinate = {
                type: this.CoordinateTypes.SHEET,
                sheet: -1,
              }
            }
            break;
          case this.CoordinateTypes.PAGE:
            direct = 'bottom';
            toCoordinate = this.getLastSheetPage(sheetList);
            if (!toCoordinate) {
              toCoordinate = {
                type: this.CoordinateTypes.PAGE,
                sheet: -1,
                page: -1,
              }
            }
            break;
          case this.CoordinateTypes.SHEET_ITEM:
          case this.CoordinateTypes.SUB_SHEET_ITEM:
            direct = 'bottom';
            toCoordinate = this.getLastSheetItem(sheetList);
            if (!toCoordinate) {
              toCoordinate = {
                type: this.CoordinateTypes.SHEET_ITEM,
                sheet: -1,
                page: -1,
                item: -1,
              }
            }
            break;
        }
      } else {
        toCoordinate = this.getCoordinateByKey(to, sheetList);
      }

      if (!toCoordinate) return null;

      toCoordinate = doNormalizeCoordinate.bind(this)(toCoordinate, direct, fromCoordinate.type, sheetList);
      if (!toCoordinate) return null;

      let createdKey = null;
      switch (fromCoordinate.type) {
        case this.CoordinateTypes.SHEET: {
          let result = doMoveSheet.bind(this)(fromCoordinate, toCoordinate, sheetList);
          if(result) {
            createdKey = this.keyOfSheet(result);
          }
          break;
        }
        case this.CoordinateTypes.PAGE: {
          let result = doMoveSheetPage.bind(this)(fromCoordinate, toCoordinate, sheetList);
          if(result) {
            createdKey = this.keyOfSheetPage(result);
          }
          break;
        }
        case this.CoordinateTypes.SHEET_ITEM:
        case this.CoordinateTypes.SUB_SHEET_ITEM: {
          let result = doMoveSheetItem.bind(this)(fromCoordinate, toCoordinate, sheetList);
          if(result) {
            createdKey = this.keyOfSheetItem(result);
          }
          break;
        }
      }
      return createdKey;
    }
  }
}
</script>

<style scoped>

</style>