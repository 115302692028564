<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import Utils from "@/assets/javascript/utils";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";

function doCreateSheetModel(sheet) {
  let pageModelList = new Array();
  for (let page of sheet.pages) {
    pageModelList.push(doCreatePageModel(page, sheet));
  }

  return {
    id: sheet.id,
    name: sheet.name,
    message: sheet.message,
    globalId: sheet.globalId,
    links: sheet.links ? Utils.deepCopy(sheet.links) : [],
    key: SheetItemKeyGenerator.methods.keyOfSheet(sheet),
    pages: pageModelList,
  }
}

function doCreatePageModel(page, sheet) {
  let itemModelList = new Array();
  for (let sheetItem of page.sheetItems) {
    let itemModel;
    if (sheetItem.type === SheetUtils.GROUP) {
      itemModel = doCreateItemGroupModel(sheetItem, sheet);
    } else {
      itemModel = doCreateItemModel(sheetItem, sheet);
    }
    itemModelList.push(itemModel);
  }
  return {
    name: page.name,
    message: page.message,
    number: page.number,
    globalId: page.globalId,
    key: SheetItemKeyGenerator.methods.keyOfSheetPage(page),
    pages: [{
      key: SheetItemKeyGenerator.methods.keyOfSubSheetPage(page),
      items: itemModelList,
    }]
  }
}

function doCreateItemModel(sheetItem, sheet) {
  let boundUserDataList = sheet.dataDefinitionMap ? Utils.deepCopy(sheet.dataDefinitionMap[sheetItem.globalId]) : null;
  if (!boundUserDataList) boundUserDataList = [];
  return {
    number: sheetItem.number,
    globalId: sheetItem.globalId,
    type: sheetItem.type,
    key: SheetItemKeyGenerator.methods.keyOfSheetItem(sheetItem),
    template: Utils.deepCopy(sheetItem),
    boundUserDataList
  }
}

function doCreateItemGroupModel(itemGroup, sheet) {
  let items = new Array();
  let key = SheetItemKeyGenerator.methods.keyOfSheetItem(itemGroup);
  for (let sheetItem of itemGroup.items) {
    let itemModel = doCreateItemModel(sheetItem, sheet);
    itemModel.groupKey = key;
    items.push(itemModel);
  }
  let template = Utils.deepCopy(itemGroup);
  delete template.items;
  return {
    number: itemGroup.number,
    globalId: itemGroup.globalId,
    type: itemGroup.type,
    key,
    template,
    items,
  }
}

export default {
  methods: {
    doCreateSheetModel(sheet) {
      return doCreateSheetModel(sheet);
    }
  }
}
</script>

<style scoped>

</style>