<template>
  <div class="sheet-item sheet-item__group" v-if="group" :class="{'is-selected': selected}">
    <div
        class="font-size-small font-weight-bold padding-horizontal-large padding-vertical-small layout-horizontal layout-middle"
        v-if="title || mode === ViewMode.MODE_EDIT">
      <ys-click-to-edit
          v-if="mode === ViewMode.MODE_EDIT"
          class="layout-flexible"
          type="textarea"
          v-model="title"
          placeholder="双击此处添加标题"
          :maxlength="SheetUtils.TITLE_MAX_LENGTH"
          style="width: 100%"
          @blur="handleTitleBlur"
      ></ys-click-to-edit>
      <span v-else-if="title" class="layout-inflexible">
        <span>{{ title }}</span>
        <span v-if="group.count > 1">{{ group.index + 1 }}</span>
      </span>
      <span class="layout-flexible"></span>
      <span
          v-if="mode === ViewMode.MODE_VIEW && group.count > 1"
          class="btn-delete-group layout-inflexible padding-left-large font-color-danger"
          @click="handleDeleteGroupClick"
      >
        <i class="fas fa-minus-circle"></i> 删除
      </span>
    </div>
    <div v-for="(item, index) in items" :key="item.key">
      <div v-if="(index > 0 || title) && item.type !== SheetUtils.ILLUSTRATION" class="sheet-item-gap__small">
        <div class="divider"></div>
      </div>
      <sheet-item :item="item" :mode="mode" v-bind="$attrs" @input="$emit('input', item)" :read-only="readOnly"
                  :property-docker="propertyDocker"></sheet-item>
    </div>
    <div v-if="mode === ViewMode.MODE_VIEW && !readOnly && group.index === group.count - 1 && group.count < group.template.maxCount" class="font-align-center margin-top">
      <ys-button class="btn-add-group layout-self-center" type="secondary" size="small" @click="handleAddGroupClick">
        <span><i class="fas fa-plus-circle"></i> 添加分组</span>
        <span v-if="title">：{{ title }}</span>
      </ys-button>
    </div>
  </div>
</template>

<script>
import {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetItem from "@/components/sheet/SheetItem";
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemGroupPropertyPanel from "@/components/sheet/panel/SheetItemGroupPropertyPanel";
import YsButton from "@/components/wedigets/YsButton";

export default {
  name: "SheetItemGroup",
  mixins: [SheetItemBasic],
  components: {YsButton, YsClickToEdit, SheetItem},
  props: {
    group: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW
    },
    isBigGap: {
      type: Boolean,
      default: true,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  data() {
    return {
      itemGroup: null,
      title: null,
      items: [],
      ViewMode,
      SheetUtils,
      propertyPanel: null,
    }
  },
  watch: {
    group: {
      handler() {
        this.itemGroup = this.group.template;
        this.title = this.itemGroup.title;
        this.items = this.group.items;
        this.$emit('update:is-big-gap', true);
        if (this.propertyPanel) this.propertyPanel.itemGroup = this.itemGroup;
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.itemGroup.title = this.title;
      this.$emit('input', this.item);
    },
    handlePropertyInput() {
      this.title = this.itemGroup.title;
      this.$emit('input', this.group);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemGroupPropertyPanel);
        this.propertyPanel.sheetItemGroup = this.itemGroup;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    },
    handleAddGroupClick() {
      this.$emit('add:group', this.group);
    },
    handleDeleteGroupClick() {
      this.$emit('delete:group', this.group);
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.sheet-item-gap__small {
  background-color: white;
  padding: 0px 20px;
}

.sheet-item-gap__small > .divider {
  height: 1px;
  background-color: #f1f1f1;
}

.sheet-item .btn-add-group {
  visibility: hidden;
}

.sheet-item:hover .btn-add-group {
  visibility: visible;
}

.sheet-item .btn-delete-group {
  visibility: hidden;
  cursor: pointer;
}

.sheet-item:hover .btn-delete-group {
  visibility: visible;
}

</style>