import ExactNumber from "@/assets/javascript/exact-number";

export const FileTypes = {
    UNKNOWN: 0,
    PDF: 1,
    WORD: 2,
    EXCEL: 3,
    POWERPOINT: 4,
    IMAGE: 5,
    TEXT: 6,
}

function fileTypeOf(name) {
    if(!name && typeof name !== 'string') return FileTypes.UNKNOWN;
    name = name.toLowerCase();
    if(name.endsWith('.pdf')) {
        return FileTypes.PDF;
    } else if(name.endsWith('.doc') || name.endsWith('.docx')) {
        return FileTypes.WORD;
    } else if(name.endsWith('.xls') || name.endsWith('.xlsx')) {
        return FileTypes.EXCEL;
    } else if(name.endsWith('.ppt') || name.endsWith('.pptx')) {
        return FileTypes.POWERPOINT;
    } else if(name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.png') || name.endsWith('.bmp')) {
        return FileTypes.IMAGE;
    } else if(name.endsWith('.txt')) {
        return FileTypes.TEXT;
    } else {
        return FileTypes.UNKNOWN;
    }
}

function defaultLocalFile(file) {
    if(file && !file.url) {
        let URL = window.URL || window.webkitURL;
        file.url = URL.createObjectURL(file.raw);
    }
    let resource = defaultFileResource();
    resource.url = file ? file.url : null;
    resource.name = file ? file.name : null;
    resource.length = file ? file.size : null;
    resource.__localFile = file;
    return resource;
}

function defaultFileResource() {
    return {
        id: 0,
        url: null,
        name: null,
    }
}

function stringifyFileLength(file) {
    let fileLength = file?.length;
    if(!fileLength) {
        return null;
    } else if (fileLength < 1024) {
        return fileLength + 'b';
    } else if (fileLength < 1024 * 1024) {
        return ExactNumber.stringify(ExactNumber.of((fileLength / 1024 * 10).toFixed(0), 1)) + 'Kb';
    } else {
        return ExactNumber.stringify(ExactNumber.of((fileLength / 1024 / 1024 * 10).toFixed(0), 1)) + 'Mb';
    }
}

const FileResourceUtils = {
    FileTypes,
    fileTypeOf,
    defaultFileResource,
    defaultLocalFile,
    stringifyFileLength,
}

export default FileResourceUtils;