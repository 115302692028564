<script>
import GuidUtils from "@/assets/javascript/guid-utils";

export default {
  data() {
    return {
      parent: null,
      guid: null,
      selected: false,
    }
  },
  computed: {
    key() {
      if(this.page) {
        return this.page.key;
      } else if(this.item) {
        return this.item.key;
      } else if(this.group) {
        return this.group.key;
      } else {
        return null;
      }
    }
  },
  methods: {
    registerSelf() {
      if(!this.parent) {
        let parent = this.$parent;
        while (parent) {
          if (parent.isSheet) {
            break;
          }
          parent = parent.$parent;
        }
        this.parent = parent;
      }
      let key;
      if(this.item) {
        key = this.item.key;
      } else if(this.page) {
        key = this.page.key;
      } else if(this.group) {
        key = this.group.key;
      }
      if(this.parent && key) {
        this.parent.registerChildren(key, this);
      }
    },
    unregisterSelf() {
      let parent = this.parent;
      if(parent) {
        parent.unregisterChildren(this);
      }
      this.parent = null;
    },
    setSelected() {
      this.selected = true;
    },
    setUnselected() {
      this.selected = false;
    }
  },
  watch: {
    key: {
      handler() {
        if(this.key) {
          this.registerSelf();
        } else {
          this.unregisterSelf();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    this.guid = GuidUtils.guid();
    this.registerSelf();
  },
  destroyed() {
    this.unregisterSelf();
  },
  activated() {
    this.registerSelf();
  },
  deactivated() {
    this.unregisterSelf();
  }
}
</script>

<style>

.sheet-item.is-selected {
  animation-name: ys-selected;
  animation-duration: 1s;
}

</style>