<template>
  <div v-if="item">
    <sheet-item-choice ref="sheetItem" v-if="item.type === SheetUtils.CHOICE" :item="item"
                       v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-choice>
    <sheet-item-gender ref="sheetItem" v-else-if="item.type === SheetUtils.GENDER" :item="item"
                       v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-gender>
    <sheet-item-string ref="sheetItem" v-else-if="item.type === SheetUtils.STRING" :item="item"
                       v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-string>
    <sheet-item-number ref="sheetItem" v-else-if="item.type === SheetUtils.NUMBER" :item="item"
                       v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-number>
    <sheet-item-date ref="sheetItem" v-else-if="item.type === SheetUtils.DATE" :item="item"
                     v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-date>
    <sheet-item-date-time ref="sheetItem" v-else-if="item.type === SheetUtils.DATETIME" :item="item"
                          v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-date-time>
    <sheet-item-time ref="sheetItem" v-else-if="item.type === SheetUtils.TIME" :item="item"
                     v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-time>
    <sheet-item-phone ref="sheetItem" v-else-if="item.type === SheetUtils.PHONE" :item="item"
                      v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-phone>
    <sheet-item-certificate ref="sheetItem" v-else-if="item.type === SheetUtils.CERTIFICATE" :item="item"
                            v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-certificate>
    <sheet-item-ethnicity ref="sheetItem" v-else-if="item.type === SheetUtils.ETHNICITY" :item="item"
                          v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-ethnicity>
    <sheet-item-address ref="sheetItem" v-else-if="item.type === SheetUtils.ADDRESS" :item="item"
                        v-bind="$attrs"  @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-address>
    <sheet-item-illustration ref="sheetItem" v-else-if="item.type === SheetUtils.ILLUSTRATION" :item="item"
                             v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-illustration>
    <sheet-item-file ref="sheetItem" v-else-if="item.type === SheetUtils.FILE" :item="item"
                     v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-file>
    <sheet-item-image ref="sheetItem" v-else-if="item.type === SheetUtils.IMAGE" :item="item"
                      v-bind="$attrs" @update:is-big-gap="updateBigGap" @input="handleInput"></sheet-item-image>
    <div v-else>unsupported sheet item: {{item.type}}</div>
  </div>
</template>

<script>

import SheetItemChoice from "@/components/sheet/items/SheetItemChoice";
import SheetItemGender from "@/components/sheet/items/SheetItemGender";
import SheetUtils from "@/assets/javascript/sheet-utils";
import SheetItemString from "@/components/sheet/items/SheetItemString";
import SheetItemDate from "@/components/sheet/items/SheetItemDate";
import SheetItemPhone from "@/components/sheet/items/SheetItemPhone";
import SheetItemEthnicity from "@/components/sheet/items/SheetItemEthnicity";
import SheetItemAddress from "@/components/sheet/items/SheetItemAddress";
import SheetItemDateTime from "@/components/sheet/items/SheetItemDateTime";
import SheetItemIllustration from "@/components/sheet/items/SheetItemIllustration";
import SheetItemNumber from "@/components/sheet/items/SheetItemNumber";
import SheetItemTime from "@/components/sheet/items/SheetItemTime";
import SheetItemCertificate from "@/components/sheet/items/SheetItemCertificate";
import SheetItemImage from "@/components/sheet/items/SheetItemImage";
import SheetItemFile from "@/components/sheet/items/SheetItemFile";

export default {
  name: "SheetItem",
  components: {
    SheetItemFile,
    SheetItemImage,
    SheetItemCertificate,
    SheetItemTime,
    SheetItemNumber,
    SheetItemIllustration,
    SheetItemDateTime,
    SheetItemAddress,
    SheetItemEthnicity,
    SheetItemPhone,
    SheetItemDate,
    SheetItemString,
    SheetItemGender,
    SheetItemChoice
  },
  props: {
    item: Object,
    isBigGap: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      SheetUtils: SheetUtils,
    }
  },
  watch: {
    '$refs.sheetItem.isBigGap': {
      handler() {
        if(this.$refs.sheetItem) {
          console.log('isBigGap...' + this.$refs.sheetItem.isBigGap);
          this.isBigGap = this.$refs.sheetItem.isBigGap;
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateBigGap(isBigGap) {
      this.$emit('update:is-big-gap', isBigGap);
    },
    handleInput() {
      this.$emit('input', this.item);
    },
    setSelected() {
      this.$refs.sheetItem.setSelected();
    }
  }
}
</script>

<style scoped>

</style>