<template>
  <div class="sheet-item-property__number padding" v-if="sheetItem">
    <div class="font-size-small"><span class="font-color-primary font-weight-bold"><span class="fas fa-list-ul"></span> 数值输入</span>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible"><span class="font-color-danger">*</span>标题：</span>
      <ys-textarea v-model="sheetItem.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible">占位文字：</span>
      <ys-textarea v-model="sheetItem.placeholder" placeholder="请输入占位文字" :maxlength="SheetUtils.PLACEHOLDER_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItem.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top-large layout-horizontal layout-middle">
      <span>小数点后保留位数：</span>
      <ys-number class="number-range" :digits="0" v-model="digits" :max="ExactNumber.of(20, 0)"
                 @input="handleDigitsInput"></ys-number>
      <ys-button lighting @click="handleIntegerClick" class="margin-left-small layout-inflexible" size="small">取整
      </ys-button>
    </div>
    <div class="margin-top layout-horizontal layout-middle">
      <span>范围：</span>
      <ys-number class="number-range" v-model="sheetItem.minValue" :max="sheetItem.maxValue"
                 :digits="sheetItem.digits"></ys-number>
      <div class="number-range-bar margin-horizontal-small"></div>
      <ys-number class="number-range" v-model="sheetItem.maxValue" :min="sheetItem.minValue"
                 :digits="sheetItem.digits"></ys-number>
    </div>
    <div class="margin-top-large">
      <div>单位：</div>
      <ys-movable @move="handleUnitMove">
        <div class="layout-vertical">
          <ys-movable-item v-for="(unit, index) in sheetItem.units" :key="unit" :tag="index">
            <div class="unit-list-item padding-left">
              <ys-click-to-edit v-model="unitInputted" @focus="handleUnitFocus(index)" @blur="handleUnitBlur(index)">
                <div class="layout-horizontal layout-middle">
                  <span class="layout-flexible">{{ unit }}</span>
                  <span class="padding padding-small font-color-danger icon-delete"
                        @click.stop="handleUnitDeleteClick(index)"><i
                      class="fas fa-times-circle"></i></span>
                </div>
              </ys-click-to-edit>
            </div>
          </ys-movable-item>
          <ys-movable-item :tag="sheetItem.units.length" disabled>
            <div class="unit-list-item padding-left padding-vertical-small layout-horizontal layout-middle">
              <ys-click-to-edit v-model="unitInputted"
                                @focus="handleUnitFocus(sheetItem.units.length)"
                                @blur="handleUnitBlur(sheetItem.units.length)">
                <i class="fa fa-plus-circle"></i> 双击添加单位
              </ys-click-to-edit>
            </div>
          </ys-movable-item>
        </div>
      </ys-movable>
    </div>
    <sheet-item-user-data-binder
        :sheet-item="sheetItem"
        :bound-user-data-list="boundUserDataList"
        @input="handleInput"
    ></sheet-item-user-data-binder>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsNumber from "@/components/wedigets/YsNumber";
import ExactNumber from "@/assets/javascript/exact-number";
import YsButton from "@/components/wedigets/YsButton";
import YsMovable from "@/components/wedigets/YsMovable";
import YsMovableItem from "@/components/wedigets/YsMovableItem";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import SheetItemUserDataBinder from "@/components/sheet/panel/SheetItemUserDataBinder";

export default {
  name: "SheetItemNumberPropertyPanel",
  components: {
    SheetItemUserDataBinder,
    YsClickToEdit, YsMovableItem, YsMovable, YsButton, YsNumber, YsSwitch, YsTextarea
  },
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      SheetUtils,
      digits: null,
      ExactNumber,
      unitInputted: null,
    }
  },
  watch: {
    sheetItem: {
      handler() {
        this.digits = this.sheetItem && this.sheetItem.digits ? ExactNumber.of(this.sheetItem.digits) : ExactNumber.of(0);
      },
      immediate: true,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItem);
    },
    handleIntegerClick() {
      this.digits = ExactNumber.of(0, 0);
      this.handleDigitsInput();
    },
    handleDigitsInput() {
      this.sheetItem.digits = ExactNumber.toFloat(this.digits);
      this.handleInput();
    },
    handleUnitFocus(index) {
      if (index < this.sheetItem.units.length) {
        this.unitInputted = this.sheetItem.units[index];
      } else {
        this.unitInputted = "";
      }
    },
    handleUnitBlur(index) {
      if (this.unitInputted) {
        let duplicated = false;
        for (let n = 0; n < this.sheetItem.units.length; n++) {
          if (n === index) continue;
          if (this.sheetItem.units[n] === this.unitInputted) {
            duplicated = true;
            break;
          }
        }
        if (!duplicated) {
          this.sheetItem.units.splice(index, 1, this.unitInputted);
        } else {
          this.$message.warning('重复输入');
        }
      } else {
        this.sheetItem.units.splice(index, 1);
      }
      this.unitInputted = "";
      this.handleInput();
    },
    handleUnitDeleteClick(index) {
      this.sheetItem.units.splice(index, 1);
      this.handleInput();
    },
    handleUnitMove(evt) {
      let from = evt.from;
      let to = evt.to;
      if (from < to) {
        to--;
      }
      if (evt.direct === 'bottom') to++;
      let unit = this.sheetItem.units[from];
      this.sheetItem.units.splice(from, 1);
      this.sheetItem.units.splice(to, 0, unit);
      this.handleInput();
    }
  }
}
</script>

<style scoped>

.number-range {
  width: 128px;
}

.number-range-bar {
  width: 24px;
  height: 4px;
  background-color: #3a8ee6;
  border-radius: 2px;
}

.unit-list-item {
  border: 1px solid #dadada;
  border-radius: 4px;
  width: 128px;
  margin-top: 5px;
  cursor: pointer;
}

.unit-list-item:hover {
  background-color: #faf4f5;
}

.unit-list-item .icon-delete {
  opacity: 0;
}

.unit-list-item:hover .icon-delete {
  opacity: 1;
}
</style>