<template>
  <span ref="wrapper" class="ys-click-to-edit__wrapper" :class="{'ys-click-to-edit__mode-view': innerMode === 'view'}"
        @dblclick.stop="handleDoubleClick"
        @click="handleClick">
    <slot v-if="innerMode === 'view'">
    <div class="ys-click-to-edit__span">
      <span v-if="value">{{ value }}</span>
      <span v-else class="font-color-placeholder">{{ placeholder }}</span>
    </div>
    </slot>
    <el-input ref="input" :type="type" class="input" v-else :value="value" v-bind="$attrs" :placeholder="placeholder"
              @focus="handleFocus" @blur="handleBlur" @input="handleInput" @change="handleChange" :rows="1" autosize
              clearable
              @keyup.enter.native="handleBlur"></el-input>
  </span>
</template>

<script>
export default {
  name: "YsClickToEdit",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: '请输入',
    },
    mode: {
      type: String,
      default: 'view',
    },
    disable: Boolean,
    singleClickToEdit: Boolean,
    type: String,
  },
  data() {
    return {
      innerMode: 'view',
    }
  },
  watch: {
    mode: {
      handler() {
        this.innerMode = this.mode;
      },
      immediate: true,
    }
  },
  methods: {
    focus() {
      setTimeout(()=>this.handleDoubleClick(), 50);
    },
    handleDoubleClick() {
      if (this.disable) return;
      this.innerMode = 'edit';
      this.$nextTick(() => {
        this.$refs.input.focus();
      })
      document.addEventListener('click', this.handleDocumentClick);
      this.$emit('update:mode', this.innerMode);
    },
    handleClick(event) {
      if (this.singleClickToEdit) {
        this.handleDoubleClick();
        event.stopPropagation();
      }
    },
    handleDocumentClick(evt) {
      if (this.$refs.wrapper.contains(evt.target)) return;
      this.setModeView();
    },
    handleBlur(evt) {
      if (this.type !== 'textarea') {
        if (this.innerMode != 'view') {
          this.setModeView();
        }
      }
      this.$emit('blur', evt);
    },
    handleInput(evt) {
      this.$emit('input', evt);
    },
    handleChange(evt) {
      this.$emit('change', evt);
    },
    handleFocus(evt) {
      this.$emit('focus', evt);
    },
    setModeView() {
      this.innerMode = 'view';
      document.removeEventListener('click', this.handleDocumentClick);
      this.$emit('update:mode', this.innerMode);
    }
  },
  destroyed() {
    this.setModeView();
  }
}
</script>

<style scoped>

.ys-click-to-edit__wrapper {
  text-align: inherit;
  box-sizing: border-box;
  max-width: inherit;
  text-decoration: inherit;
  width: inherit;
  height: inherit;
}

.ys-click-to-edit__wrapper.ys-click-to-edit__mode-view:hover {
  opacity: 0.8;
}

.ys-click-to-edit__span {
  text-align: inherit;
  text-overflow: ellipsis;
  text-decoration: inherit;
  width: inherit;
  max-width: inherit;
  display: inline-block;
  text-align: inherit;
  vertical-align: top;
  overflow: hidden;
}

.input {
  width: inherit;
  height: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;
}

.input >>> .el-input__inner {
  border: 2px solid #3a8ee6;
  border-radius: 4px;
  text-align: inherit;
  box-shadow: 0 0 8px #bec0c0;
  text-decoration: inherit;
}

.input >>> .el-textarea__inner {
  border: 2px solid #3a8ee6;
  border-radius: 4px;
  font: inherit;
  font-size: inherit;
  text-align: inherit;
  box-shadow: 0 0 8px #bec0c0;
  padding: 16px;
  text-decoration: inherit;
}

.input >>> .el-textarea__inner::-webkit-scrollbar {
  width: 6px;
  padding-right: 10px !important;
}

.input >>> .el-textarea__inner::-webkit-scrollbar-button {
  visibility: hidden;
}

.input >>> .el-textarea__inner::-webkit-scrollbar-track {
  visibility: hidden;
}

.input >>> .el-textarea__inner::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #409EFF;
  visibility: hidden;
}

.input >>> .el-textarea__inner:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}


</style>