<template>
  <div v-if="page">
    <div class="sheet-page"
         :class="{
          'is-hide-border': hideBorder
         }"
         v-for="(subPage, pageIndex) in page.pages" :key="subPage.key">
      <div class="edit-divider" v-if="mode === ViewMode.MODE_EDIT">
        <div class="font-size-extra-small font-color-danger layout-vertical layout-center layout-middle"
             @click.stop="handleDividerClick(page.key)">插入表单项
        </div>
      </div>
      <div v-for="(item, itemIndex) in subPage.items" :key="item.key">
        <sheet-item-wrapper ref="sheetItems" :item="item"
                            v-show="!item.hide"
                            :mode="mode"
                            :read-only="readOnly"
                            :propertyDocker="propertyDocker"
                            @input="handleInput"
                            @add:group="handleAddGroupClick($event, pageIndex, itemIndex)"
                            @delete:group="handleDeleteGroupClick($event, pageIndex, itemIndex)"
        ></sheet-item-wrapper>
        <div class="edit-divider" v-if="mode === ViewMode.MODE_EDIT">
          <div class="font-size-extra-small font-color-danger layout-vertical layout-center layout-middle"
               @click.stop="handleDividerClick(item.key)">插入表单项
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import SheetItemWrapper from "@/components/sheet/SheetItemWrapper";
import SheetPagePropertyPanel from "@/components/sheet/panel/SheetPagePropertyPanel";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetRecordViewModelCreator from "@/components/sheet/basic/SheetRecordViewModelCreator";
import SheetRecordUtils from "@/assets/javascript/sheet-record-utils";
import SheetViewModelParser from "@/components/sheet/basic/SheetViewModelParser";
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";

export default {
  name: "SheetPage",
  mixins: [SheetItemBasic, SheetRecordViewModelCreator, SheetViewModelParser, SheetItemKeyGenerator],
  components: {SheetItemWrapper},
  props: {
    page: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    propertyDocker: Element,
    readOnly: Boolean,
    hideBorder: Boolean,
  },
  data() {
    return {
      SheetUtils,
      propertyPanel: null,
      ViewMode,
    }
  },
  watch: {
    page: {
      handler() {
        let propertyPanel = this.propertyPanel;
        if (propertyPanel) propertyPanel.page = this.page;
      },
      immediate: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.page);
    },
    handleDividerClick(key) {
      if(this.mode === 'edit') {
        this.$emit('insert', key);
      }
    },
    handleAddGroupClick(evt, pageIndex, itemIndex) {
      let subPage = this.page.pages[pageIndex];
      let groupIndex = itemIndex;

      let groupModels = [];
      for(let n = 0; n < subPage.items.length; n++) {
        if(subPage.items[n].number === evt.number) {
          groupModels.push(subPage.items[n]);
        }
      }

      let group = this.doParseSheetItemGroup(evt);
      let copiedGroupModel = this.createSheetItemRecordGroupModel(group, SheetRecordUtils.defaultSheetItemRecord(SheetUtils.GROUP), groupModels.length, groupModels.length + 1);

      groupModels.push(copiedGroupModel);

      let index = 0;
      for(let groupModel of groupModels) {
        groupModel.count = groupModels.length;
        groupModel.index = index ++;
        groupModel.key = this.keyOfSheetItem(groupModel, groupModel.index);
      }

      subPage.items.splice(groupIndex + 1, 0, copiedGroupModel);

      this.$emit('input', this.page);
    },
    handleDeleteGroupClick(evt, pageIndex, itemIndex) {
      let subPage = this.page.pages[pageIndex];
      subPage.items.splice(itemIndex, 1);

      let groupModels = [];
      for(let n = 0; n < subPage.items.length; n++) {
        if(subPage.items[n].number === evt.number) {
          groupModels.push(subPage.items[n]);
        }
      }

      let index = 0;
      for(let groupModel of groupModels) {
        groupModel.count = groupModels.length;
        groupModel.index = index ++;
        groupModel.key = this.keyOfSheetItem(groupModel, groupModel.index);
      }

      this.$emit('input', this.page);

    },
    setSelected() {
      if(this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetPagePropertyPanel);
        this.propertyPanel.page = this.page;
        this.propertyPanel.$on('input', this.handleInput);
      }
    },
    setSelectedSheetItem(sheetItem) {
      for (let sheetItemEl of this.$refs.sheetItems) {
        if (sheetItemEl.item.globalId === sheetItem.globalId) {
          sheetItemEl.setSelected();
          break;
        }
      }
    },
  }
}
</script>

<style scoped>

.sheet-page {
  border-radius: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  box-shadow: 0 0 16px #bec0c0;
  margin-top: 10px;
  min-height: 90vh;
  background-color: #f1f1f1;
}

.sheet-page.is-hide-border {
  box-shadow: unset;
  background-color: transparent;
}

.sheet-page:first-child {
  margin-top: 0px;
}

.edit-divider {
  height: 0px;
}

.edit-divider >>> div {
  height: 10px;
  position: relative;
  top: -5px;
  cursor: pointer;
  opacity: 0;
}

.edit-divider >>> div:hover {
  opacity: 1;
  background-color: rgba(240, 145, 166, .5);
}

</style>