const defaultPhone = (phone) => {
    if(typeof phone == 'string') {
        return {
            phone: phone
        }
    } else {
        return phone;
    }
}

function checkPhone(phone) {
    if(!phone) return true;
    if(typeof phone === 'object') phone = phone.phone;
    return /^1(3\d{2}|4[14-9]\d|5([0-35689]\d|7[1-79])|66\d|7[2-35-8]\d|8\d{2}|9[13589]\d)\d{7}$/.test(phone);

}

const PhoneUtils = {
    defaultPhone: defaultPhone,
    checkPhone: checkPhone,
}

export default PhoneUtils;