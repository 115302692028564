<script>
import SheetItemKeyGenerator from "@/components/sheet/basic/SheetItemKeyGenerator";
import SheetUtils from "@/assets/javascript/sheet-utils";

const CoordinateTypes = {
  SHEET: 0,
  PAGE: 1,
  SHEET_ITEM: 2,
  SUB_SHEET_ITEM: 3,
};

function doSearchSubSheetItem(key, items) {
  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    if (key == this.keyOfSheetItem(items[itemIndex])) {
      return {
        type: CoordinateTypes.SUB_SHEET_ITEM,
        s_item: itemIndex,
      }
    }
  }
}

function doSearchSheetItem(key, items) {
  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    if (key == this.keyOfSheetItem(items[itemIndex])) {
      return {
        type: CoordinateTypes.SHEET_ITEM,
        item: itemIndex,
      }
    }
  }
  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    let sheetItem = items[itemIndex];
    if (sheetItem.type === SheetUtils.GROUP) {
      let result = doSearchSubSheetItem.bind(this)(key, sheetItem.items);
      if (result) {
        result.item = itemIndex;
        return result;
      }
    }
  }
}

function doSearchSheetPage(key, pages) {
  for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
    if (key == this.keyOfSheetPage(pages[pageIndex])) {
      return {
        type: CoordinateTypes.PAGE,
        page: pageIndex,
      }
    }
  }
  for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
    let result = doSearchSheetItem.bind(this)(key, pages[pageIndex].sheetItems);
    if (result) {
      result.page = pageIndex;
      return result;
    }
  }
}

export default {
  name: "SheetItemCoordinateHelper",
  mixins: [SheetItemKeyGenerator],
  data() {
    return {
      CoordinateTypes,
    }
  },
  methods: {
    getCoordinatesByKeys(keys, sheetList) {
      let candidates = new Array();
      for (let key of keys) {
        let coordinate = this.getCoordinateByKey(key, sheetList);
        if (coordinate) {
          let duplicated = false;
          for (let n = 0; n < candidates.length; n++) {
            let candidate = candidates[n];
            if (this.isCoordinateContains(candidate, coordinate)) {
              duplicated = true;
              break;
            } else {
              if (this.isCoordinateContains(coordinate, candidate)) {
                candidates.splice(n, 1, coordinate);
              }
            }
          }
          if (!duplicated) {
            candidates.push(coordinate);
          }
        }
      }
      return candidates;
    },
    getCoordinateByKey(key, sheets) {
      for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
        if (key == this.keyOfSheet(sheets[sheetIndex])) {
          return {
            type: CoordinateTypes.SHEET,
            sheet: sheetIndex,
          }
        }
      }

      for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
        let sheet = sheets[sheetIndex];
        let result = doSearchSheetPage.bind(this)(key, sheet.pages);
        if (result) {
          result.sheet = sheetIndex;
          return result;
        }
      }
    },
    getLastSheet(sheets) {
      if(sheets.length == 0) return null;
      return {
        type: CoordinateTypes.SHEET,
        sheet: sheets.length - 1,
      };
    },
    getLastSheetPage(sheets) {
      let lastSheetCoordinate = this.getLastSheet(sheets);
      if(!lastSheetCoordinate) return null;

      let lastSheet = sheets[lastSheetCoordinate.sheet];
      if(lastSheet.pages.length == 0) return lastSheetCoordinate;

      return {
        type: CoordinateTypes.PAGE,
        sheet: lastSheetCoordinate.sheet,
        page: lastSheet.pages.length - 1,
      }
    },
    getLastSheetItem(sheets) {
      let lastPageCoordinate = this.getLastSheetPage(sheets);
      if(!lastPageCoordinate) return null;

      if(lastPageCoordinate.type != CoordinateTypes.PAGE) return lastPageCoordinate;

      let lastPage = sheets[lastPageCoordinate.sheet].pages[lastPageCoordinate.page];
      if(lastPage.sheetItems.length === 0) return lastPageCoordinate;

      return {
        type: CoordinateTypes.SHEET_ITEM,
        sheet: lastPageCoordinate.sheet,
        page: lastPageCoordinate.page,
        item: lastPage.sheetItems.length - 1,
      }
    },
    getSheetByCoordinate(coordinate, sheetList) {
      if(coordinate.sheet < 0 || coordinate.sheet >= sheetList.length) return null;
      return sheetList[coordinate.sheet];
    },
    getPageByCoordinate(coordinate, sheetList) {
      if(coordinate.type < CoordinateTypes.PAGE) return null;
      let sheet = this.getSheetByCoordinate(coordinate, sheetList);
      if(!sheet) return null;
      if(coordinate.page < 0 || coordinate.page >= sheet.pages.length) return null;
      return sheet.pages[coordinate.page];
    },
    getSheetItemByCoordinate(coordinate, sheetList) {
      if(coordinate.type < CoordinateTypes.SHEET_ITEM) return null;
      let page = this.getPageByCoordinate(coordinate, sheetList);
      if(!page) return null;
      if(coordinate.item < 0 || coordinate.item >= page.sheetItems) return null;
      let sheetItem = page.sheetItems[coordinate.item];
      if(coordinate.type === CoordinateTypes.SUB_SHEET_ITEM && sheetItem.type === SheetUtils.GROUP) {
        if(coordinate.s_item < 0 || coordinate.s_item >= sheetItem.items.length) return null;
        return sheetItem.items[coordinate.s_item];
      } else {
        return sheetItem;
      }
    },
    isCoordinateContains(parent, children) {
      if (parent.type >= children.type) return false;
      switch (parent.type) {
        case CoordinateTypes.SHEET:
          return parent.sheet === children.sheet;
        case CoordinateTypes.PAGE:
          return parent.sheet === children.sheet && parent.page === children.page;
        case CoordinateTypes.SHEET_ITEM:
          return parent.sheet === children.sheet && parent.page === children.page && parent.item === children.item;
        default:
          return false;
      }
    }
  }
}
</script>

<style scoped>

</style>