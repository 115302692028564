<template>
  <div class="sheet-item-property__group padding" v-if="sheetItemGroup">
    <div><span class="font-color-primary font-weight-bold"><i class="fa fa-list-ul"></i> 组</span></div>
    <div class="margin-top layout-horizontal">
      <span class="layout-inflexible">标题：</span>
      <ys-textarea v-model="sheetItemGroup.title" placeholder="请输入标题" :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                   @input="handleInput"></ys-textarea>
    </div>
    <div class="margin-top layout-horizontal">
      <span>选填：</span>
      <ys-switch v-model="sheetItemGroup.optional" @input="handleInput"></ys-switch>
    </div>
    <div class="margin-top layout-horizontal layout-middle">
      <span class="layout-inflexible">最多添加几组：</span>
      <ys-number v-model="maxCount" @input="handleMaxCountInput" placeholder="用户最多添加几组数据" :min="ExactNumber.of(1, 0)" :max="ExactNumber.of(20, 0)"></ys-number>
    </div>
  </div>
</template>

<script>
import SheetUtils from "@/assets/javascript/sheet-utils";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsSwitch from "@/components/wedigets/YsSwitch";
import YsNumber from "@/components/wedigets/YsNumber";
import ExactNumber from "@/assets/javascript/exact-number";

export default {
  name: "SheetItemGroupPropertyPanel",
  components: {YsNumber, YsSwitch, YsTextarea},
  props: {
    sheetItemGroup: Object,
  },
  data() {
    return {
      SheetUtils,
      maxCount: null,
      ExactNumber,
    }
  },
  watch: {
    sheetItemGroup: {
      handler() {
        this.maxCount = this.sheetItemGroup && this.sheetItemGroup.maxCount ? ExactNumber.of(this.sheetItemGroup.maxCount, 0) : ExactNumber.of(0, 0);
      },
      immediate: true,
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.sheetItemGroup);
    },
    handleMaxCountInput(value) {
      this.sheetItemGroup.maxCount = value ? ExactNumber.toFloat(value) : 1;
      this.handleInput();
    }
  }
}
</script>

<style scoped>

</style>