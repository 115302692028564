<template>
  <div>
    <div class="divider margin-vertical-large"></div>
    <ys-button type="secondary" @click="clickButton">绑定用户数据</ys-button>
    <ys-dialog
        title="绑定用户数据"
        :visible.sync="binderDialogVisible"
    >
      <ys-infinite-scroll-view style="height: 50vh;">
        <div class="layout-horizontal layout-wrap">
          <div
              v-for="userDataDef in userDataDefList"
              :key="userDataDef.key"
              :class="[
                 'user-data-def',
                 {
                   'is-active': userDataDef.__isBound,
                 }
            ]"
              @click="clickUserDataDef(userDataDef)"
          >
            {{ userDataDef.name }}
          </div>
        </div>
      </ys-infinite-scroll-view>
      <div slot="footer">
        <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
        <ys-button class="margin-left" lighting @click="clickConfirm">确定</ys-button>
      </div>
    </ys-dialog>
  </div>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsButton from "@/components/wedigets/YsButton";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";

export default {
  name: "SheetItemUserDataBinder",
  components: {YsInfiniteScrollView, YsButton, YsDialog},
  mixins: [httpapi],
  props: {
    sheetItem: Object,
    boundUserDataList: Array,
  },
  data() {
    return {
      /**
       * 显示弹窗
       */
      binderDialogVisible: false,

      /**
       * 用户数据类型
       */
      userDataDefList: [],

    }
  },
  watch: {
    sheetItem: {
      handler() {
        this.loadingCode++;
      },
      immediate: true,
    }
  },
  methods: {
    clickButton() {
      this.binderDialogVisible = true;
      this.loadUserDataDefinitions();
    },
    clickUserDataDef(userDataDef) {
      userDataDef.__isBound = !userDataDef.__isBound;
      if (userDataDef.__isBound) {
        this.userDataDefList.forEach(x => {
          if (x !== userDataDef) x.__isBound = false;
        });
      }
      this.$forceUpdate();
    },
    clickCancel() {
      this.binderDialogVisible = false;
    },
    clickConfirm() {
      this.binderDialogVisible = false;
      let boundUserDataList = this.boundUserDataList;
      boundUserDataList.splice(0, this.boundUserDataList.length);
      for (let userDataDef of this.userDataDefList) {
        if (userDataDef.__isBound) {
          boundUserDataList.push({
            key: userDataDef.key,
            type: userDataDef.supportedType,
          });
        }
      }

      this.$emit('input', boundUserDataList);
    },
    loadUserDataDefinitions() {
      if (!this.sheetItem) return;
      this.$reqGet({
        path: `/sheet/user_data_def/list/sheet_item_type/${this.sheetItem.type}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let userDataDefList = res.data;
            let boundUserDataList = this.boundUserDataList;
            if (boundUserDataList) {
              for (let userDataDef of userDataDefList) {
                let isBound = false;
                for (let boundUserData of boundUserDataList) {
                  if (boundUserData.key === userDataDef.key &&
                      boundUserData.type === userDataDef.supportedType
                  ) {
                    isBound = true;
                    break;
                  }
                }
                userDataDef.__isBound = isBound;
              }
            }
            this.userDataDefList = userDataDefList;
          })
          .catch(() => this.$message.error('加载失败'));
    }

  }
}
</script>

<style scoped>

.user-data-def {
  padding: 8px 16px;
  border-radius: 100px;
  background-color: #f1f1f1;
  margin: 5px;
  cursor: pointer;
}

.user-data-def:hover {
  opacity: .7;
}

.user-data-def.is-active {
  background-color: #f091a6;
  color: white;
}
</style>