<template>
  <div class="sheet-item sheet-item__ethnicity" v-if="sheetItem" :class="{'is-selected': selected}">
    <div class="text-measurer" v-resize="handleTextMeasurerResize">{{ sheetItem.title }} {{ simulatedData }}</div>
    <div class="title layout-horizontal layout-middle padding-horizontal-large">
      <span v-if="!sheetItem.optional" class="font-color-danger">*</span>
      <div :class="mode === ViewMode.MODE_EDIT ? 'layout-flexible': 'layout-inflexible'">
        <ys-click-to-edit v-if="mode === ViewMode.MODE_EDIT"
                          type="textarea"
                          v-model="title"
                          :maxlength="SheetUtils.TITLE_MAX_LENGTH"
                          style="width: 100%"
                          @blur="handleTitleBlur"></ys-click-to-edit>
        <span v-else>{{ title }}</span>
      </div>
      <div class="layout-flexible layout-horizontal layout-middle layout-right" v-if="!isBigGap">
        <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
          <ys-ethnicity-picker
              :disabled="mode === ViewMode.MODE_EDIT"
              @pick="handleEthnicityPicked"
          >
            <div slot="reference" class="ethnicity layout-horizontal layout-middle"
                 :class="{'is-disabled' : mode === ViewMode.MODE_EDIT}">
              <span v-if="sheetItemRecord && sheetItemRecord.ethnicity">{{ sheetItemRecord.ethnicity }}</span>
              <span class="font-color-placeholder" slot="reference" v-else>请选择民族 <i
                  class="fa fa-angle-right"></i></span>
            </div>
          </ys-ethnicity-picker>
        </template>
        <template v-else>
          <div v-if="sheetItemRecord.ethnicity">{{ sheetItemRecord.ethnicity }}</div>
          <div v-else class="font-color-placeholder">未填写</div>
        </template>
      </div>
    </div>
    <div class="padding-horizontal-large padding-bottom layout-vertical layout-right" v-if="isBigGap">
      <template v-if="mode === ViewMode.MODE_EDIT || !readOnly">
        <ys-ethnicity-picker
            :disabled="mode === ViewMode.MODE_EDIT"
            @pick="handleEthnicityPicked"
        >
          <div slot="reference" class="ethnicity layout-horizontal layout-middle"
               :class="{'is-disabled' : mode === ViewMode.MODE_EDIT}">
            <span v-if="sheetItemRecord && sheetItemRecord.ethnicity">{{ sheetItemRecord.ethnicity }}</span>
            <span class="font-color-placeholder" slot="reference" v-else>请选择民族 <i class="fa fa-angle-right"></i></span>
          </div>
        </ys-ethnicity-picker>
      </template>
      <template v-else>
        <div v-if="sheetItemRecord.ethnicity">{{ sheetItemRecord.ethnicity }}</div>
        <div v-else class="font-color-placeholder">未填写</div>
      </template>
    </div>

  </div>
</template>

<script>
import SheetUtils, {ViewMode} from "@/assets/javascript/sheet-utils";
import YsClickToEdit from "@/components/wedigets/YsClickToEdit";
import YsEthnicityPicker from "@/components/wedigets/YsEthnicityPicker";
import SheetItemBasic from "@/components/sheet/basic/SheetItemBasic";
import SheetItemEthnicityPropertyPanel from "@/components/sheet/panel/SheetItemEthnicityPropertyPanel";

export default {
  name: "SheetItemEthnicity",
  mixins: [SheetItemBasic],
  components: {YsEthnicityPicker, YsClickToEdit},
  props: {
    item: Object,
    mode: {
      type: String,
      default: ViewMode.MODE_VIEW,
    },
    readOnly: Boolean,
    propertyDocker: Element,
  },
  computed: {
    simulatedData() {
      return '乌孜别克族'
    }
  },
  data() {
    return {
      sheetItem: null,
      sheetItemRecord: null,
      title: null,
      ViewMode,
      SheetUtils,
      isBigGap: false,
      propertyPanel: null,
    }
  },
  watch: {
    item: {
      handler() {
        this.sheetItem = this.item.template;
        this.sheetItemRecord = this.item.record;
        this.title = this.sheetItem.title;
        if (this.propertyPanel) this.propertyPanel.sheetItem = this.sheetItem;
      },
      immediate: true,
    }
  },
  methods: {
    handleTitleBlur() {
      this.sheetItem.title = this.title;
      this.$emit('input', this.item);
    },
    handleTextMeasurerResize(evt) {
      let itemWidth = this.$el.getBoundingClientRect().width;
      this.isBigGap = itemWidth < evt.width + 40;
      this.$emit('update:is-big-gap', this.isBigGap);
    },
    handlePropertyInput() {
      this.title = this.sheetItem.title;
      this.$emit('input', this.item);
    },
    handlePropertyUnmount() {
      this.propertyPanel = null;
      this.selected = false;
    },
    handleEthnicityPicked(evt) {
      if (this.sheetItemRecord) {
        this.sheetItemRecord.ethnicity = evt;
        this.$emit('input', this.item);
      }
    },
    setSelected() {
      if (this.mode === ViewMode.MODE_VIEW) return;
      let propertyDocker = this.propertyDocker;
      if (propertyDocker) {
        this.propertyPanel = propertyDocker.mountPanel(SheetItemEthnicityPropertyPanel);
        this.propertyPanel.sheetItem = this.sheetItem;
        this.propertyPanel.boundUserDataList = this.item.boundUserDataList;
        this.propertyPanel.$on('input', this.handlePropertyInput);
        this.propertyPanel.$on('unmounted', this.handlePropertyUnmount);
        this.selected = true;
      }
    }
  }
}
</script>

<style scoped>

.sheet-item {
  background-color: white;
}

.title {
  min-height: 40px;
}

.ethnicity {
  cursor: pointer;
  height: 40px;
}

.ethnicity:hover {
  background-color: #f1f1f1;
}

.ethnicity.is-disabled {
  cursor: not-allowed;
}

.text-measurer {
  height: 0px;
  overflow: hidden;
  position: absolute;
}
</style>